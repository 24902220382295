import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons'
import { observer } from 'mobx-react'
import React, { useState } from 'react'

import { faqData } from '../../assets/faq'
import { primaryColor } from '../../variables'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionFaq: {
      paddingTop: '3rem',
      '& .faq-heading': {
        '& h1': {
          marginTop: '1rem',
          fontSize: '1.5rem',
          color: 'black',
          letterSpacing: '.2px',
          fontWeight: 800,
        },
        '& span': {
          color: 'black',
          fontSize: '1.2rem',
        },
      },
      '& .faq-list': {
        '& .toggle-collapse': {
          textAlign: 'right',
          marginBottom: 5,
          '& button': {
            padding: '0 5px',
            color: '#ADADAD',
            fontSize: '.8rem',
            textTransform: 'lowercase',
            fontWeight: 400,
            '&:hover': {
              color: '#2563eb',
              background: 'transparent',
            },
          },
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      sectionFaq: {
        gridTemplateColumns: '1fr',
      },
    },
    [theme.breakpoints.down('sm')]: {
      sectionFaq: {
        '& .faq-list': {
          width: 'calc(100vw - 4.1rem)',
        },
      },
    },
  }),
)

export const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'inset 0px -1px 0px rgba(219, 219, 219, 0.5)',
    marginBottom: '1.5rem',
    borderRadius: '0.5rem',
    fontFamily: 'Avenir, Roboto, Arial, serif',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginTop: 0,
      marginBottom: '1.5rem',
      boxShadow: '0px 30px 80px rgba(0, 0, 0, 0.04)',
    },
  },
  expanded: {},
})(MuiAccordion)

export const AccordionSummary = withStyles({
  root: {
    padding: '0.5rem 1.5rem',
    backgroundColor: '#fff',
    borderBottom: 'none',
    marginBottom: -1,
    minHeight: 62,
    color: primaryColor,
    borderRadius: '0.5rem',
    '&$expanded': {
      minHeight: 62,
      color: primaryColor,
      '& svg': {
        color: primaryColor,
      },
    },
  },
  content: {
    margin: '0',
    '& p': {
      fontSize: '1rem',
      fontWeight: 800,
    },
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

export const AccordionDetails = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: '5px',
  },
}))(MuiAccordionDetails)

const initialExpandedMap = faqData.reduce((m, q, i) => {
  m[i] = true
  return m
}, {} as { [k: number]: boolean })

export const SectionFaq = observer(() => {
  const css = useStyles()

  const [expandedMap, setExpandedMap] = useState(initialExpandedMap)
  const toggleMap = (i: number) =>
    setExpandedMap({
      ...expandedMap,
      [i]: !expandedMap[i],
    })

  const anyExpanded = Object.values(expandedMap).some(v => v)
  const toggleExpandedAll = () => {
    setExpandedMap(anyExpanded ? {} : initialExpandedMap)
  }

  return (
    <div id='faq' className={css.sectionFaq}>
      <div className='faq-heading'>
        <h1>Welcome to the Wrapped Pastel Conversion Service!</h1>
        Before continuing, please make sure you read our quick introduction{' '}
        <a href='#faq'>here</a>.
      </div>
      <div className='faq-list'>
        <div className='toggle-collapse'>
          <Button onClick={toggleExpandedAll}>
            {anyExpanded ? 'Collapse' : 'Expand'} All
          </Button>
        </div>
        {faqData.map((q, i) => (
          <Accordion expanded={expandedMap[i]} key={q.id}>
            <AccordionSummary
              expandIcon={expandedMap[i] ? <RemoveIcon /> : <AddIcon />}
              onClick={() => toggleMap(i)}
            >
              <Typography className='text-gray-700 font-avenir'>
                {q.q}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='font-avenir'>{q.a}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  )
})
