import classNames from 'classnames'
import React, { forwardRef } from 'react'

interface IInputProps {
  value?: string
  type?: string
  error?: string
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void
  placeholder?: string
  name: string
  defaultValue?: string
  pattern?: string
  className?: string
  helperText?: string
  disabled?: boolean
  readOnly?: boolean
}

export const Input: React.FC<IInputProps> = forwardRef<
  HTMLInputElement,
  IInputProps
>(
  (
    {
      name,
      error = '',
      className = '',
      type = 'text',
      helperText = '',
      disabled = false,
      readOnly = false,
      ...rest
    },
    ref,
  ) => {
    return (
      <React.Fragment>
        <input
          name={name}
          type={type}
          className={classNames(
            className,
            'text-gray-800 placeholder-gray-500 focus:outline-none  mt-5',
            'focus:ring-gray-500 focus:border-transparent px-4 py-3 bg-white rounded w-full shadow',
            {
              'border-red-500 border': !!error,
              'focus:ring-2': !readOnly,
              'shadow-inner disabled:opacity-50 focus:ring-0 ring-transparent bg-gray-100': readOnly,
            },
          )}
          disabled={disabled}
          readOnly={readOnly}
          ref={ref}
          {...rest}
        />
        {error ? (
          <p className='text-sm text-red-500 mt-2'>{error}</p>
        ) : (
          <p className='text-sm text-gray-500 opacity-50 mt-2'>{helperText}</p>
        )}
      </React.Fragment>
    )
  },
)

export default Input
