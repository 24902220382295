import { Link } from 'react-router-dom'

export const faqData = [
  {
    id: 'faq-1',
    q: <>What is Wrapped Pastel anyway?</>,
    a: (
      <>
        Wrapped Pastel (also referred to as "wPSL") is an Ethereum token based
        on the ERC-20 standard that is used as an equivalent to regular Pastel
        (PSL)-- buit it works in the existing Ethereum ecosystem. This allows it
        to be stored in wallets such as MetaMask, and also allows wPSL to be
        traded on Ethereum DEXs such as Uniswap.
      </>
    ),
  },
  {
    id: 'faq-2',
    q: <>But if wPSL isn't PSL, what exactly makes it "equivalent"?</>,
    a: (
      <>
        wPSL doesn't exist in a vacuum-- each and every unit of wPSL in
        existence (currently, 250 million wPSL exist, which can always be
        verified by looking at the Wrapped Pastel contract on EtherScan.io:{' '}
        <a
          target='_blank'
          href='https://etherscan.io/token/0xc775c0c30840cb9f51e21061b054ebf1a00acc29'
          rel='noopener noreferrer'
        >
          https://etherscan.io/token/0xc775c0c30840cb9f51e21061b054ebf1a00acc29
        </a>
        ) correponds on a one-to-one basis with a unit of PSL that has been set
        aside in the WrappedPastel PSL address (which can always be verified
        using the Pastel Network explorer page for the address:{' '}
        <a
          target='_blank'
          href='https://explorer.pastel.network/address/ptJEVxQJ9spxa2Hp9vWxiEf3EhAWvQ1hCaS'
          rel='noopener noreferrer'
        >
          https://explorer.pastel.network/address/ptJEVxQJ9spxa2Hp9vWxiEf3EhAWvQ1hCaS
        </a>
        ). What this means in practice is that we can establish a clear link
        between wPSL and PSL, and in fact, that is the whole purpose of this
        website and service: to allow users to convert from the wPSL that they
        have purchased on Uniswap or other exchanges into "native" PSL coins
        that can be used to register or purchase NFTs on the Pastel Network.
      </>
    ),
  },
  {
    id: 'faq-3',
    q: <>But how does this work?</>,
    a: (
      <>
        To use this website, you first must acquire wPSL, which you can do by
        purchasing it on Uniswap here:{' '}
        <a
          target='_blank'
          href='https://info.uniswap.org/token/0xc775c0c30840cb9f51e21061b054ebf1a00acc29'
          rel='noopener noreferrer'
        >
          https://info.uniswap.org/token/0xc775c0c30840cb9f51e21061b054ebf1a00acc29
        </a>
        . Once you have wPSL tokens in your MetaMask wallet, you can start the
        conversion or "unwrapping" process. In order to convert your wPSL to
        PSL, you must sign into this site using your Google account (this is
        used to prevent DDOS attacks and also for user security). Then, you
        simply specify the PSL address where you would like your converted PSL
        coins sent (note that only transparent PSL addresses are supported!) and
        the number of wPSL that you want to convert and click the "Transfer"
        button. This will cause your web browser to prompt you to sign into
        MetaMask, which will then ask you to confirm the transaction. Finally,
        you will need to pass an identity check using a reputable third party
        service, IDAnalyzer Identity. This is to confirm that you are not a
        United States resident and is required for regulatory reasons (so-called
        "KYC/AML" rules: "know-your-customer"/"anti-money-laundering").
      </>
    ),
  },
  {
    id: 'faq-4',
    q: <>Is the conversion process free?</>,
    a: (
      <>
        No, in order to cover the adminstative and legal costs associated with
        operating the service (which includes the IDAnalyzer Identity service,
        which charges us for each user verification), wrappedpastel.com charges
        users a service fee equal to 1% of the converted amount. For example, if
        you convert 100,000 wPSL into PSL, you will receive 99,000 PSL at your
        PSL address (i.e., the converted amount will be net of the 1% service
        fee).
      </>
    ),
  },
  {
    id: 'faq-5',
    q: <>Who runs the wrappedpastel.com website?</>,
    a: (
      <>
        The wrappedpastel.com website and service is operating by Wrapped Pastel
        Custody, LLC, a Delaware limited liability corporation. Pastel Custody
        can be contacted at{' '}
        <a href='mailto:support@pastel.network'>support@pastel.network</a>
      </>
    ),
  },
  {
    id: 'faq-6',
    q: <>What if something goes wrong? What protections do I have as a user?</>,
    a: (
      <>
        For our complete terms and conditions, please see our{' '}
        <Link to='/terms-of-service'>Terms of Service</Link>
      </>
    ),
  },
]
