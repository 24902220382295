import { observer } from 'mobx-react'
import React from 'react'
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login'
import { toast } from 'react-toastify'

import Button from '../../common/components/Button/Button'
import { googleClientId } from '../../config'
import authStore from '../../mobx/authStore'
import OnboardingModal from '../onboarding'
import style from './LandingPage.module.scss'

const onGoogleLoginSuccess = async (
  res: GoogleLoginResponseOffline | GoogleLoginResponse,
) => {
  if ('tokenId' in res) {
    await authStore.login(res.tokenId, res.profileObj)
  }
}

const Landing = observer(() => {
  const { showOnboarding, openOnboardingModal } = authStore

  return (
    <div className={`lg:p-32 p-8 pt-20 text-white ${style.hero_background}`}>
      <h1 className='text-6xl  w-1/2 md:mt-40'>Welcome to Wrapped Pastel</h1>
      <div className='mt-7 text-xl'>
        Convert PSL into Wrapped Pastel (wPSL), and wPSL into PSL
      </div>
      <p className='italic'>
        A Secure and Convenient Wrapping and Unwrapping Service
      </p>
      <Button
        width='250px'
        className='mt-10'
        onClick={() => openOnboardingModal()}
      >
        Begin Sign Up Process
      </Button>
      {showOnboarding && <OnboardingModal />}
      <br />
      <GoogleLogin
        render={p => (
          <Button
            width='250px'
            className='mt-6'
            variant='transparent'
            onClick={p.onClick}
          >
            Login with Google
          </Button>
        )}
        clientId={googleClientId}
        onSuccess={onGoogleLoginSuccess}
        onFailure={(err: any) => {
          toast(err.message)
        }}
        cookiePolicy='single_host_origin'
      />
    </div>
  )
})

export default Landing
