import { action, makeAutoObservable, runInAction } from 'mobx'
import { toast } from 'react-toastify'

import { UserStatus } from '../constants/constants'
import { AjaxStore } from '../utils/AjaxStore'
import { ErrorWithDetail } from '../utils/ErrorWithDetail'
import { fetcher, FetcherOptions } from '../utils/fetcher'
import { TUser } from './api'

export interface IDAnalyzerIframe {
  key: string
  Iframe: string
}
export class AuthStore {
  constructor() {
    makeAutoObservable(this)
  }

  timerId?: NodeJS.Timeout
  isProcessing = false
  submitting = false
  showOnboarding = false
  idVerifySuccess = false
  googleToken = localStorage.getItem('googleToken') || undefined
  idAnalyzerIframe: string = ''
  googleUser?: TGoogleUser
  googleUserFetching = false
  googleUserAlreadyFetchedOnce = false
  isAdmin: boolean = false
  isLoggedIn: boolean = Boolean(localStorage.getItem('isLoggedIn')) || false
  isAdminPage: boolean = false
  showIframeIDanalyzerModal = false
  iframeIDanalyzerUrl = ''
  isLoading = false

  openOnboardingModal = () => {
    this.showOnboarding = true
  }

  closeOnboardingModal = () => {
    this.showOnboarding = false
  }

  checkIDProcessing = () => {
    this.isProcessing = true

    this.timerId = setInterval(async () => {
      await this.refetchUser()

      if (this.user?.status === UserStatus.Verified) {
        this.isProcessing = false
        this.idVerifySuccess = true
        clearInterval(this.timerId!)
      } else if (
        this.user?.status === UserStatus.RequiresInput ||
        this.user?.status === UserStatus.Canceled
      ) {
        this.isProcessing = false
        clearInterval(this.timerId!)
      }
    }, 1000)
  }

  isTosOpen = false
  toggleTosOpen = (isTosOpen: boolean) => {
    this.isTosOpen = isTosOpen
  }
  isTosAgreeChecked = false
  tosAgreeCheck = (isTosAgreeChecked: boolean) => {
    this.isTosAgreeChecked = isTosAgreeChecked
  }
  tosAgreeSubmit = () => {
    this.ajaxPost('/api/user/submit-agree-tos')
      .then(
        action(j => {
          if (j.error) {
            throw new ErrorWithDetail(j.error, j.errorDetail)
          }
          this.user = j.data
          this.isTosOpen = false
        }),
      )
      .catch(err => {
        toast.error(err.detail)
      })
  }

  checkAccountExist = async () =>
    this.ajaxGet('/api/user/check-user-status')
      .then(
        action(data => {
          if (data.error) {
            toast.error(data.errorDetail)
            localStorage.removeItem('googleToken')
            throw new ErrorWithDetail(data.errorDetail, data.error)
          }
          this.toggleTosOpen(true)
        }),
      )
      .catch(err => {})

  showIframeIDanalyzer = async () => {
    if (!this.googleToken) {
      return
    }

    this.isLoading = true
    try {
      const data = await this.ajaxPost('/api/create-idanalyzer-iframe')
      if (data.error) {
        toast(data.error.message, { type: 'error' })
      } else {
        if (data.data.error) {
          toast(data.data.error.message, { type: 'error' })
        } else {
          this.showIframeIDanalyzerModal = true
          this.iframeIDanalyzerUrl = data.data.url
        }
      }
    } catch (error) {
      toast(error.message, { type: 'error' })
    }
    this.isLoading = false
  }

  hideIframeIDanalyzer = (): void => {
    this.showIframeIDanalyzerModal = false
  }

  login = async (googleToken: string, googleUser: TGoogleUser) => {
    this.setGoogleToken(googleToken, googleUser)
    try {
      const data = await this.ajaxGet('/api/user/login')
      if (data.error) {
        toast.error(data.errorDetail)
        throw new ErrorWithDetail(data.errorDetail, data.error)
      }
      this.user = data.data
      this.isLoggedIn = true
      localStorage.setItem('isLoggedIn', 'true')
    } catch (error) {
      this.userFetchFailed = true
    }
  }

  adminLogin = async (googleToken: string, googleUser: TGoogleUser) => {
    this.setGoogleToken(googleToken, googleUser)
    try {
      const data = await this.ajaxGet('/api/admin/login')
      if (data.error) {
        toast.error(data.errorDetail)
        throw new ErrorWithDetail(data.errorDetail, data.error)
      }
      runInAction(() => {
        this.user = data.data
        this.isAdmin = true
      })
    } catch (error) {
      runInAction(() => {
        this.userFetchFailed = true
      })
    }
  }

  setAdminPage = (status = true): void => {
    this.isAdminPage = status
  }

  logout = () => {
    localStorage.removeItem('googleToken')
    localStorage.removeItem('isLoggedIn')
    localStorage.removeItem('currentTab')
    this.googleUser = undefined
    this.googleToken = ''
    this.isLoggedIn = false
    this.isAdmin = false
    this.user = undefined
  }

  setGoogleToken = (googleToken?: string, googleUser?: TGoogleUser): void => {
    this.googleToken = googleToken
    this.googleUser = googleUser
    this.googleUserFetching = false
    localStorage.setItem('googleToken', googleToken || '')
    this.resetManagedAjaxStore()
  }

  fetchGoogleUser = () => {
    if (this.googleUserAlreadyFetchedOnce) {
      return
    }
    this.refetchGoogleUser()
    this.googleUserAlreadyFetchedOnce = true
  }
  refetchGoogleUser = () => {
    if (!this.googleToken || this.googleUserFetching) {
      return
    }
    this.googleUserFetching = true
    // https://developers.google.com/identity/sign-in/web/backend-auth#calling-the-tokeninfo-endpoint
    fetch(
      `https://oauth2.googleapis.com/tokeninfo?id_token=${this.googleToken}`,
    )
      .then(r => r.json())
      .then(u => {
        if (!u.sub || !u.email) {
          throw new Error('Invalid google token, email notfound in response')
        }
        this.setGoogleToken(this.googleToken, {
          googleId: u.sub,
          email: u.email,
          name: u.name,
          imageUrl: u.picture,
          givenName: u.given_name,
          familyName: u.family_name,
          fromAutoFetch: true,
        })
      })
      .catch(() => {
        this.setGoogleToken()
      })
  }

  user?: TUser
  userFetching = false
  userFetchFailed = false
  userAlreadyFetchedOnce = false

  fetchUser = async () => {
    if (this.userAlreadyFetchedOnce) {
      return
    }
    await this.refetchUser()
    this.userAlreadyFetchedOnce = true
  }
  refetchUser = async () => {
    if (!this.googleToken || this.userFetching) {
      return
    }
    this.userFetching = true
    this.userFetchFailed = false
    try {
      const data = await this.ajaxGet('/api/user/current-detail')

      if (data.error) {
        throw new ErrorWithDetail(data.error, data.errorDetail)
      }
      this.user = data.data
    } catch (error) {
      this.userFetchFailed = true
    }

    this.userFetching = false
  }

  ajaxGet = (url: string) =>
    this._fetch(url, undefined, {
      method: 'GET',
      headers: {
        'x-access-token': this.googleToken || '',
      },
    })
  ajaxPost = (url: string, body?: object) =>
    this._fetch(url, body, {
      method: 'POST',
      headers: {
        'x-access-token': this.googleToken || '',
        'Content-Type': 'application/json',
      },
    })
  ajaxPut = (url: string, body?: object) =>
    this._fetch(url, body, {
      method: 'PUT',
      headers: {
        'x-access-token': this.googleToken || '',
        'Content-Type': 'application/json',
      },
    })
  ajaxDelete = (url: string) =>
    this._fetch(url, undefined, {
      method: 'DELETE',
      headers: {
        'x-access-token': this.googleToken || '',
        'Content-Type': 'application/json',
      },
    })
  /**
   * Calls fetch on a URL that is prefixed with "/api", and returns the result
   * as JSON or catches an error and returns that.
   *
   * @private
   */
  _fetch = async (url: string, body?: object, fetchOverrides = {}) => {
    const fetchOptions: FetcherOptions = {
      method: 'POST',
      data: body,
      ...fetchOverrides,
    }

    try {
      const res = await fetcher(url, fetchOptions)
      if (res.error === 'Unauthenticated') {
        this.logout()
      }
      return res
    } catch (error) {
      return { error }
    }
  }
  private managedAjaxStores: AjaxStore[] = []
  private resetManagedAjaxStore = () => {
    this.managedAjaxStores.forEach(s => {
      const h = s.options.headers as { [k: string]: unknown }
      h['x-access-token'] = this.googleToken
      s.reset()
    })
  }
}

const authStore = new AuthStore()
export default authStore as Readonly<AuthStore>

export type TGoogleUser = {
  googleId: string
  email: string
  name: string
  imageUrl: string
  givenName: string
  familyName: string
  fromAutoFetch?: boolean
}
