/**
 * A custom JavaScript Error which takes a `fetch` response, uses its
 * `statusText` as the error message, and exposes a `json` method for getting
 * the content of the HTTP call (for displaying error messages from the
 * backend).
 */
export default class APIError extends Error {
  _httpResponse: Response
  status: number
  constructor(httpResponse: Response) {
    super(httpResponse.statusText)
    this._httpResponse = httpResponse
    this.status = httpResponse.status
  }

  async json() {
    try {
      return await this._httpResponse.json()
    } catch {
      // If the error wasn't JSON or we couldn’t parse it, return a blank object
      return {}
    }
  }
}
