import { useCallback, useEffect, useState } from 'react'

// Tried such hook from `react-use` and from other libraries - all have problems
// so here is a custom implementation

export const setLocalStorageItem = (key: string, value: string | null) => {
  if (value === null) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, value)
  }

  const event = new CustomEvent('localStorage', { detail: { key, value } })
  window.dispatchEvent(event)
}

export const useLocalStorage = (
  key: string,
  defaultValue?: string | null,
): [string | null, (value: string | null) => void] => {
  const [value, setValueFromEvent] = useState<string | null>(
    () => localStorage.getItem(key) || defaultValue || null,
  )

  useEffect(() => {
    if (key) {
      const listener = ({
        detail,
      }: {
        detail: { key: string; value: string | null }
      }) => {
        if (detail.key === key && detail.value !== value) {
          setValueFromEvent(detail.value)
        }
      }

      window.addEventListener('localStorage', listener as () => void)
      return () =>
        window.removeEventListener('localStorage', listener as () => void)
    }

    return
  }, [key, value, setValueFromEvent])

  const setValue = useCallback(
    (value: string | null) => setLocalStorageItem(key, value),
    [key],
  )

  return [value, setValue]
}
