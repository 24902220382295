import { observer } from 'mobx-react'
import React from 'react'
import Modal from 'react-modal'

import {
  MINIMUM_PSL_AMOUNT,
  MINIMUM_TEST_PSL_AMOUNT,
} from '../../../../common/constants'
import transactionStore from '../../mobx/transactionStore'
import { formatNumber } from '../../utils/lib'
import style from './FirstConversionModal.module.scss'

const FirstConversionModal: React.FC = observer(() => {
  const {
    firstConversionModal: open,
    closeFirstConversionNotifyModal,
  } = transactionStore

  const firstConversionContent = () => (
    <p className='text-neutrals-400 mt-2'>
      To allow users to try out the conversion service in order to verify that
      it{"'"}s working properly, we allow each user to convert as little as{' '}
      {formatNumber(MINIMUM_TEST_PSL_AMOUNT)} PSL into wPSL (and{' '}
      {formatNumber(MINIMUM_TEST_PSL_AMOUNT)} wPSL into PSL) for their very
      first conversion in each direction (i.e., PSL to wPSL and wPSL to PSL) on{' '}
      <a href='https://wrappedpastel.com/'>wrappedpastel.com</a>. After this,
      the minimum conversion amount will be set to{' '}
      {formatNumber(MINIMUM_PSL_AMOUNT)} PSL/wPSL in order for us to cover the
      costs of ID verification and running the conversion service.
    </p>
  )

  return (
    <Modal
      isOpen={open}
      contentLabel='First Conversion'
      className={style.modal_content}
      overlayClassName={style.modal_overlay}
    >
      <div className='py-10 px-15 text-center'>
        {firstConversionContent()}
        <button
          onClick={closeFirstConversionNotifyModal}
          className='rounded-2xl content-center py-3 w-20 text-white mt-4 bg-blue-500'
        >
          OK
        </button>
      </div>
    </Modal>
  )
})

export default FirstConversionModal
