import { observer } from 'mobx-react'
import React from 'react'
import { useForm } from 'react-hook-form'
import Modal from 'react-modal'

import { EmailRegex } from '../../../../common/constants'
import { ReactComponent as CloseIcon } from '../../assets/icons/ico-union.svg'
import Input from '../../common/components/Input/Input'
import Select from '../../common/components/Select/Select'
import {
  UserVerifyOptions,
  VALIDATION_MESSAGES,
} from '../../constants/constants'
import adminStore, { TVerifyUserForm } from '../../mobx/adminStore'
import style from './VerifyUserForm.module.scss'

const VerifyUserForm: React.FC = observer(() => {
  const {
    isOpen,
    user,
    handleCloseUserForm,
    updateVerifyUser,
    isLoading,
  } = adminStore
  const form = useForm<TVerifyUserForm>({
    defaultValues: {
      email: user?.email || '',
      status:
        UserVerifyOptions.find(option => option.value === user?.status) ||
        UserVerifyOptions[0],
    },
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form

  const onSubmit = async ({ email, status }: TVerifyUserForm) => {
    await updateVerifyUser({ email, status: status.value })
  }

  const renderModalContent = () => (
    <div className='py-10 px-15'>
      <button
        onClick={handleCloseUserForm}
        className='w-7 h-7 rounded-lg border border-icon-default opacity-30 px-2.5 absolute right-6 top-6'
        type='button'
      >
        <CloseIcon />
      </button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...register('email', {
            required: VALIDATION_MESSAGES.required,
            pattern: {
              value: EmailRegex,
              message: 'Invalid Email address',
            },
          })}
          name='email'
          placeholder='Enter email address'
          helperText='Email Address you are going to verify.'
          error={errors.email?.message}
          readOnly
        />

        <Select
          form={form}
          label='Status'
          name='status'
          options={UserVerifyOptions}
          placeholder='Status'
          className='mt-5'
        />
        <button
          className='rounded-2xl py-3 w-full text-white mt-4 bg-blue-500 flex justify-center'
          disabled={isLoading}
          type='submit'
        >
          {isLoading ? 'Confirming' : 'Confirm'}
        </button>
      </form>
    </div>
  )

  return (
    <Modal
      isOpen={isOpen}
      contentLabel='Verify UserForm'
      className={style.modal_content}
      overlayClassName={style.modal_overlay}
    >
      {renderModalContent()}
    </Modal>
  )
})

export default VerifyUserForm
