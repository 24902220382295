import { ErrorMessage } from '@hookform/error-message'
import { yupResolver } from '@hookform/resolvers/yup'
import cn from 'classnames'
import React from 'react'
import { useForm } from 'react-hook-form'

import { TempAuthSchema, TTempAuthSchema } from '../../../../common/validations'
import Input from '../../common/components/Input'
import SmallSpinner from '../../common/components/SmallSpinner'
import { useAuthorize } from './tempAuth.service'

export default function TempAuthPage() {
  const form = useForm<TTempAuthSchema>({
    resolver: yupResolver(TempAuthSchema),
  })

  const { authorize, error, isLoading } = useAuthorize()

  const onSubmit = () => {
    authorize(form.getValues())
  }

  return (
    <div className='w-full h-full flex justify-center'>
      <form className='max-w-sm mt-10' onSubmit={form.handleSubmit(onSubmit)}>
        <div className='text-gray-800 font-extrabold'>
          Authenticate to use Conversion Service
        </div>
        {error && (
          <p className='text-sm text-red-500 mt-2 text-center'>
            {error.message}
          </p>
        )}
        <Input {...form.register('username')} placeholder='Enter Login' />
        <ErrorMessage
          errors={form.formState.errors}
          name='username'
          render={error => (
            <p className='text-sm text-red-500 mt-2'>{error.message}</p>
          )}
        />
        <Input
          {...form.register('password')}
          placeholder='Enter Password'
          type='password'
        />
        <ErrorMessage
          errors={form.formState.errors}
          name='password'
          render={error => (
            <p className='text-sm text-red-500 mt-2'>{error.message}</p>
          )}
        />
        <button
          className={cn(
            'flex-center rounded-2xl h-12 w-full text-white mt-4 disabled:bg-gray-300 bg-blue-500',
          )}
        >
          {isLoading && <SmallSpinner addClass='mr-2' />}
          Authenticate
        </button>
      </form>
    </div>
  )
}
