import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { ChangeEvent, useState } from 'react'
import { toast } from 'react-toastify'

import { fetcher, FetcherOptions } from '../../utils/fetcher'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionContact: {
      paddingTop: '3rem',
      '& .contact-heading': {
        '& h1': {
          marginTop: '1rem',
          fontSize: '1.25rem',
          color: 'black',
          fontWeight: 800,
          lineHeight: '1.5rem',
          letterSpacing: '.2px',
          marginBottom: '1rem',
        },
        '& span': {
          color: 'black',
          fontSize: '1.2rem',
        },
      },
      '& .contact-form': {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '1rem',
        '& .customInput': {
          backgroundColor: '#fff',
          padding: '1rem',
          borderRadius: '0.25rem',
          boxShadow:
            'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
          '& .MuiFormControl-root.MuiTextField-root': {
            width: '100%',
          },
        },
        '& .input-question': {
          gridColumn: '1 / -1',
        },
        '& .submit-button': {
          gridColumn: '1 / -1',
          textAlign: 'right',
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      contact: {
        marginTop: '2rem',
      },
    },
  }),
)

export const SectionContact = observer(() => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [content, setContent] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState({
    name: {
      message: '',
    },
    email: {
      message: '',
    },
    content: {
      message: '',
    },
  })
  const css = useStyles()

  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const handleSendMail = async () => {
    setLoading(true)
    setMessage('')
    setError({
      name: {
        message: '',
      },
      email: {
        message: '',
      },
      content: {
        message: '',
      },
    })
    let isError = false
    let errorMessage = error
    if (!name) {
      errorMessage = {
        ...errorMessage,
        name: {
          message: 'Name is required.',
        },
      }
      isError = true
    }

    if (!email) {
      errorMessage = {
        ...errorMessage,
        email: {
          message: 'Email is required.',
        },
      }
      isError = true
    }

    if (!content) {
      errorMessage = {
        ...errorMessage,
        content: {
          message: 'Content is required.',
        },
      }
      isError = true
    }

    if (email && !validateEmail(email)) {
      errorMessage = {
        ...errorMessage,
        email: {
          message: 'Email is not valid.',
        },
      }
      isError = true
    }

    setError(errorMessage)
    if (!isError) {
      const fetchOptions: FetcherOptions = {
        method: 'POST',
        data: {
          email,
          name,
          content,
        },
      }

      try {
        const data = await fetcher('/api/mail', fetchOptions)
        if (!data.error) {
          setMessage('Thank you for your contact.')
        } else {
          toast(data.errorDetail, { type: 'error' })
        }
      } catch (error) {
        toast(error.message, { type: 'error' })
      }
    }
    setLoading(false)
  }

  return (
    <div id='contact' className={css.sectionContact}>
      <div className='contact-heading'>
        <h1>
          Still have question? <br /> Feel free to contact us:
        </h1>
      </div>
      <div className='contact-form'>
        <div className='customInput'>
          <TextField
            className='themedInput'
            label='Your Name'
            InputLabelProps={{ shrink: true }}
            value={name}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setName(event.target.value)
            }
            error={!!error.name.message}
            helperText={error.name.message}
          />
        </div>
        <div className='customInput'>
          <TextField
            className='themedInput'
            label='Your Email Address'
            InputLabelProps={{ shrink: true }}
            value={email}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setEmail(event.target.value)
            }
            error={!!error.email.message}
            helperText={error.email.message}
          />
        </div>
        <div className='customInput input-question'>
          <TextField
            className='themedInput'
            label='Your Question'
            InputLabelProps={{ shrink: true }}
            multiline
            rows={4}
            value={content}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setContent(event.target.value)
            }
            error={!!error.content.message}
            helperText={error.content.message}
          />
        </div>
        {message ? <div className='text-lg'>{message}</div> : null}
        <div className='submit-button mb-3'>
          <button
            className='rounded-2xl py-3 w-full text-white mt-4 bg-blue-500 text-base font-medium'
            onClick={handleSendMail}
            disabled={isLoading}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )
})
