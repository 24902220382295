import React from 'react'
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login'

import { ReactComponent as GoogleColorIcon } from '../../../assets/google-color-icon.svg'
import { googleClientId } from '../../../config'
import authStore from '../../../mobx/authStore'

interface IGoogleButton {
  onClick: () => void
  disabled?: boolean | undefined
}

const GoogleButton = (p: IGoogleButton) => {
  return (
    <button
      className='flex justify-between items-center rounded-sm w-48 h-10 mt-6.5 bg-button-default hover:bg-button-hover focus:bg-button-pressed text-white text-sm py-2 pr-3'
      onClick={p.onClick}
    >
      <span className='bg-white w-10 h-9.5 p-2.5'>
        <GoogleColorIcon />
      </span>
      <span>Sign in with Google</span>
    </button>
  )
}

export default function GoogleSignIn(): JSX.Element {
  const { setGoogleToken, checkAccountExist } = authStore

  const handleOnSuccess = async (
    res: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) => {
    if ('tokenId' in res) {
      setGoogleToken(res.tokenId, res.profileObj)
      await checkAccountExist()
    }
  }

  return (
    <GoogleLogin
      clientId={googleClientId}
      render={GoogleButton}
      onSuccess={(res: GoogleLoginResponse | GoogleLoginResponseOffline) =>
        handleOnSuccess(res)
      }
      cookiePolicy='single_host_origin'
    />
  )
}
