import { tempAuthHeaderName } from '../../../common/constants'
import { ErrorCode } from '../../../common/errorCode'
import {
  getAuthToken,
  resetToken,
} from '../components/TempAuth/tempAuth.service'
import { baseURL, tempAuthLock } from '../config'
import APIError from './api_error'
import { ErrorWithDetail } from './ErrorWithDetail'

export type FetcherOptions = RequestInit & {
  data?: object
  throwError?: boolean
  extractData?: boolean
}

export async function fetcher<T = any>(
  url: string,
  options?: FetcherOptions,
): Promise<T> {
  const headers = {
    'Content-Type': 'application/json',
    ...options?.headers,
  }

  if (tempAuthLock) {
    const token = getAuthToken()
    if (token) {
      ;(headers as Record<string, string>)[tempAuthHeaderName] = token
    }
  }

  const res = await fetch(baseURL + url, {
    body: options?.data ? JSON.stringify(options?.data) : undefined,
    ...options,
    headers,
  })

  let data
  if (res.headers.get('Content-Type')?.startsWith('application/json')) {
    data = await res.json()
  }

  if (!res.ok) {
    if (data?.errorCode === ErrorCode.tempAuthUnauthorized) {
      resetToken()
    }

    throw new APIError(res)
  }

  if (options?.throwError && data?.error) {
    throw new ErrorWithDetail(data.error, data.errorDetail)
  }

  return (options?.extractData ? data?.data : data) as T
}

export const get = <T>(url: string, options: FetcherOptions = {}) => {
  options.method = 'GET'
  options.throwError = true
  options.extractData = true
  return fetcher<T>(url, options)
}

export const post = <T>(url: string, options: FetcherOptions = {}) => {
  options.method = 'POST'
  options.throwError = true
  options.extractData = true
  return fetcher<T>(url, options)
}
