import { observer } from 'mobx-react'
import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as HelpIcon } from '../../assets/icons/ico-help.svg'
import { ReactComponent as LogoIcon } from '../../assets/Logo.svg'
import {
  resetToken,
  useIsAuthorized,
} from '../../components/TempAuth/tempAuth.service'
import { tempAuthLock } from '../../config'
import authStore from '../../mobx/authStore'

const AppHeader: React.FC = observer(() => {
  const {
    isAdmin: isLoggedInAdmin,
    logout,
    isLoggedIn,
    isAdminPage,
  } = authStore

  const isLoggedInTemp = useIsAuthorized()

  return (
    <nav
      className='fixed top-0 z-50 flex flex-col text-center content-center sm:flex-row sm:text-left sm:justify-between py-4 px-15 bg-white bg-opacity-20 shadow sm:items-baseline w-full'
      id='header'
    >
      <div className='mb-2 sm:mb-0 inner flex items-center'>
        <Link to='/'>
          <LogoIcon width={36} />
        </Link>
        <span className='ml-4 text-base text-neutrals-400'>
          Official Wrapped Pastel (wPSL) Conversion Service
        </span>
      </div>

      {isLoggedInAdmin && (
        <div className='mb-2 sm:mb-0 inner flex items-center'>
          <Link to='/admin/users' className='px-2'>
            Users
          </Link>
          <Link to='/admin/whitelist' className='px-2'>
            {'Whitelist & BlockList'}
          </Link>
        </div>
      )}

      <div className='sm:mb-0 self-center flex items-center space-x-2'>
        <Link to='/faq' className=' ml-2 px-1 flex items-center'>
          <HelpIcon />
        </Link>

        {isLoggedInAdmin ? (
          <button
            type='button'
            className='border border-gray-600 text-gray-700 px-2 rounded text-sm hover:text-gray-800'
            onClick={logout}
          >
            Log Out Admin
          </button>
        ) : null}

        {isLoggedIn && !isAdminPage ? (
          <button
            type='button'
            className='border border-gray-600 text-gray-700 px-2 rounded text-sm hover:text-gray-800'
            onClick={logout}
          >
            Log Out
          </button>
        ) : null}

        {tempAuthLock && isLoggedInTemp && (
          <button
            type='button'
            className='border border-gray-600 text-gray-700 px-2 rounded text-sm hover:text-gray-800'
            onClick={resetToken}
          >
            Log Out Temp
          </button>
        )}
      </div>
    </nav>
  )
})

export default AppHeader
