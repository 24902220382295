import React, { ReactNode } from 'react'
import { Redirect, Route } from 'react-router-dom'

import authStore from '../../mobx/authStore'

interface IAdminRoute {
  path: string
  children: ReactNode
}

export default function AdminRoute({ children, ...rest }: IAdminRoute) {
  const { googleToken, isAdmin } = authStore
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAdmin && googleToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/admin',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
