import moment from 'moment'
import React from 'react'

import Spinner from '../../common/components/Spinner'
import { CONVERSION_STATUS, ConversionType } from '../../constants/constants'
import { TConversion } from '../../mobx/api'
import transactionStore from '../../mobx/transactionStore'
import { formatNumber } from '../../utils/lib'
import styles from './TransactionTable.module.scss'

interface ITransactionTable {
  transactions: TConversion[]
  transactionsFetching?: boolean
}

async function onViewLogsCLick(transaction: TConversion) {
  if (transaction.type === ConversionType.Unwrapping) {
    await transactionStore.fetchPslConversionLogs(transaction.id)
  } else {
    await transactionStore.fetchWpslConversionLogs(transaction.id)
  }
}

async function onRetryClick(transaction: TConversion) {
  if (transaction.type === ConversionType.Unwrapping) {
    await transactionStore.retryWpslToPslModal(transaction.id)
  } else {
    await transactionStore.retryPslToWpslModal(transaction.id)
  }
}

export default function TransactionTable({
  transactions,
  transactionsFetching = true,
}: ITransactionTable): JSX.Element {
  const remainingText = (blockNumber: number) => {
    if (blockNumber) {
      return `1-${blockNumber * 2.5} minutes (${blockNumber} block${
        blockNumber >= 2 ? 's' : ''
      })`
    } else {
      return '-'
    }
  }

  const transactionHeader = () => (
    <tr>
      <th
        scope='col'
        className='px-6 py-3 text-left text-base font-extrabold text-gray-700 sticky top-0 bg-white z-30'
      >
        Date
      </th>
      <th
        scope='col'
        className='px-6 py-3 text-left text-base font-extrabold text-gray-700 sticky top-0 bg-white z-30'
      >
        Amount
      </th>
      <th
        scope='col'
        className='px-6 py-3 text-left text-base font-extrabold text-gray-700 sticky top-0 bg-white z-30'
      >
        Status
      </th>
      <th
        scope='col'
        className='px-6 py-3 text-left text-base font-extrabold text-gray-700 sticky top-0 bg-white z-30'
      >
        Conversion Log
      </th>
      <th
        scope='col'
        className='px-6 py-3 text-left text-base font-extrabold text-gray-700 sticky top-0 bg-white z-30'
      >
        Remaining Until Confirmed
      </th>
    </tr>
  )

  const transactionBody = () =>
    transactions?.map((transaction, idx) => (
      <tr key={transaction.id}>
        <td className='px-6 py-4 whitespace-nowrap'>
          <div className='flex items-center text-base text-gray-600'>
            {transaction.createdAt
              ? moment(transaction.createdAt).format('MM/DD/YYYY hh:mm')
              : null}
          </div>
        </td>
        <td className='px-6 py-4 whitespace-nowrap'>
          {transaction.amount ? (
            transaction.type === ConversionType.Unwrapping ? (
              transaction.transferredTransactionHash ? (
                <a
                  className='text-base text-link'
                  href={`https://explorer.pastel.network/tx/${transaction.transferredTransactionHash.toString()}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {formatNumber(transaction.amount)} wPSL
                </a>
              ) : (
                <span>{formatNumber(transaction.amount)} wPSL</span>
              )
            ) : transaction.transferredTransactionHash ? (
              <a
                className='text-base text-link'
                href={`https://etherscan.io/tx/${transaction.transferredTransactionHash.toString()}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {formatNumber(transaction.amount)} PSL
              </a>
            ) : (
              <span>{formatNumber(transaction.amount)} PSL</span>
            )
          ) : null}
        </td>
        <td className='px-6 py-4 whitespace-nowrap'>
          {transaction.status === CONVERSION_STATUS.initial ? (
            <button
              className='capitalize inline-flex text-base leading-5 rounded-full text-link cursor-pointer focus:outline-none'
              onClick={() => onRetryClick(transaction)}
              type='button'
            >
              {transaction.status}
            </button>
          ) : (
            <span className='capitalize inline-flex text-base leading-5 rounded-full text-gray-500'>
              {transaction.status}
            </span>
          )}
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-base text-gray-600'>
          <button
            className='text-link cursor-pointer focus:outline-none'
            onClick={() => onViewLogsCLick(transaction)}
            type='button'
          >
            View logs
          </button>
        </td>
        <td className='px-6 py-4 whitespace-nowrap text-base text-gray-600'>
          {remainingText(transaction.remainingBlocks)}
        </td>
      </tr>
    ))

  const mainContent = () => (
    <div className={styles.wrapper}>
      {!transactionsFetching && transactions.length ? (
        <table className='min-w-full relative table-auto'>
          <thead>{transactionHeader()}</thead>
          <tbody className='bg-white'>{transactionBody()}</tbody>
        </table>
      ) : null}
      {transactionsFetching ? (
        <div className='p-8 text-center'>
          <Spinner />
          <p className='text-base font-extrabold'>Loading...</p>
        </div>
      ) : null}
      {!transactionsFetching && !transactions.length && (
        <p className='p-4 text-center text-base'>
          No wrapping or unwrapping transactions have been submitted yet! Click
          the button in the upper right to begin one.
        </p>
      )}
    </div>
  )

  return (
    <div className='flex flex-col bg-white pt-5 px-9 shadow'>
      <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-3'>
        <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
          {mainContent()}
        </div>
      </div>
    </div>
  )
}
