export class ErrorWithDetail extends Error {
  detail?: string
  constructor(message: string, err?: Error | string) {
    super(message)
    this.detail = typeof err === 'string' ? err : `${err?.message || err || ''}`
  }

  static format = (err: ErrorWithDetail) => {
    return err?.detail
      ? `${err.message}: ${err.detail}`
      : err?.message || `${err}`
  }
}
