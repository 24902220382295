import { action, makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'

import { TConversion } from './api'
import authStore, { AuthStore } from './authStore'
import web3Store from './web3Store'

export class TransactionStore {
  authStore: Readonly<AuthStore>
  transactions: TConversion[] = []
  pslToWpslModal: boolean = false
  wpslToPslModal: boolean = false
  transactionsFetching: boolean = false
  firstConversionModal: boolean = false
  retryPslToWpsl: boolean = false
  retryWpslToPsl: boolean = false
  openConversionLogModal: boolean = false
  conversionLogs: TConversion[] = []
  conversionLogsFetching: boolean = false

  constructor(authStore: Readonly<AuthStore>) {
    this.authStore = authStore
    makeAutoObservable(this)
  }

  openWpslToPslModal = () => {
    this.wpslToPslModal = true
    this.retryWpslToPsl = false
    if (!this.authStore.user?.firstPslConversionPassed) {
      this.firstConversionModal = true
    }
  }

  closeWpslToPslModal = () => {
    web3Store.resetPslConversionByIdForRetry()
    this.wpslToPslModal = false
    this.retryWpslToPsl = false
  }

  retryWpslToPslModal = async (conversionById: string) => {
    await web3Store.getPslConversionByIdForRetry(conversionById)
    this.wpslToPslModal = true
    this.retryWpslToPsl = true
  }

  closeFirstConversionNotifyModal = () => {
    this.firstConversionModal = false
  }

  openPslToWpslModal = () => {
    this.pslToWpslModal = true
    this.retryPslToWpsl = false
    if (!this.authStore.user?.firstWpslConversionPassed) {
      this.firstConversionModal = true
    }
  }

  closePslToWpslModal = () => {
    this.pslToWpslModal = false
    this.retryPslToWpsl = false
  }

  retryPslToWpslModal = async (conversionById: string) => {
    await web3Store.getWpslConversionByIdForRetry(conversionById)
    this.pslToWpslModal = true
    this.retryPslToWpsl = true
  }

  fetchTransactions = () => {
    if (this.transactionsFetching) {
      return
    }
    this.transactionsFetching = true
    this.authStore
      .ajaxGet('/api/user/get-conversions')
      .then(
        action(async ({ data, error }) => {
          if (error) {
            toast.error(error)
          }
          this.transactions = data.data
          await this.authStore.refetchUser()
        }),
      )
      .catch(
        action(err => {
          toast.error(err)
        }),
      )
      .finally(
        action(() => {
          this.transactionsFetching = false
        }),
      )
  }

  fetchWpslConversionLogs = async (conversionId: string) => {
    if (this.conversionLogsFetching) {
      return
    }
    this.openConversionLogModal = true
    this.conversionLogsFetching = true
    this.authStore
      .ajaxGet(`/api/user/get-psl-wpsl-conversions-message/${conversionId}`)
      .then(
        action(({ data, error }) => {
          if (error) {
            toast.error(error)
          }
          this.conversionLogs = data.data
        }),
      )
      .catch(
        action(err => {
          toast.error(err)
        }),
      )
      .finally(
        action(() => {
          this.conversionLogsFetching = false
        }),
      )
  }

  fetchPslConversionLogs = async (conversionId: string) => {
    if (this.conversionLogsFetching) {
      return
    }
    this.openConversionLogModal = true
    this.conversionLogsFetching = true
    this.authStore
      .ajaxGet(`/api/user/get-wpsl-psl-conversions-message/${conversionId}`)
      .then(
        action(({ data, error }) => {
          if (error) {
            toast.error(error)
          }
          this.conversionLogs = data.data
        }),
      )
      .catch(
        action(err => {
          toast.error(err)
        }),
      )
      .finally(
        action(() => {
          this.conversionLogsFetching = false
        }),
      )
  }

  closeConversionLogModal = () => {
    this.openConversionLogModal = false
  }
}

const transactionStore = new TransactionStore(authStore)
export default transactionStore as Readonly<TransactionStore>
