import React, { ReactNode } from 'react'
import { Redirect, Route } from 'react-router-dom'

import authStore from '../../mobx/authStore'

interface IPrivateRoute {
  path: string
  children: ReactNode
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export default function PrivateRoute({ children, ...rest }: IPrivateRoute) {
  const { googleToken } = authStore
  return (
    <Route
      {...rest}
      render={({ location }) =>
        googleToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
