import { observer } from 'mobx-react'
import React from 'react'
import { useForm } from 'react-hook-form'
import Modal from 'react-modal'

import { EmailRegex } from '../../../../common/constants'
import { ReactComponent as CloseIcon } from '../../assets/icons/ico-union.svg'
import Input from '../../common/components/Input/Input'
import Select from '../../common/components/Select/Select'
import {
  UserStatusOptions,
  VALIDATION_MESSAGES,
} from '../../constants/constants'
import whitelistStore, { TWhitelistUserForm } from '../../mobx/whitelistStore'
import style from './UserForm.module.scss'

const UserForm: React.FC = observer(() => {
  const {
    addUserToWhitelist,
    isOpen: open,
    handleCloseUserForm,
    user,
    updateWhitelistUser,
  } = whitelistStore
  const form = useForm<TWhitelistUserForm>({
    defaultValues: {
      email: user?.email || '',
      status:
        UserStatusOptions.find(option => option.value === user?.status) ||
        UserStatusOptions[0],
    },
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form

  const onSubmit = async ({ email, status }: TWhitelistUserForm) => {
    if (!user) {
      await addUserToWhitelist({ email, status })
    } else {
      await updateWhitelistUser(user.id, status.value, email)
    }
  }

  return (
    <Modal
      isOpen={open}
      contentLabel='Whitelist UserForm'
      className={style.modal_content}
      overlayClassName={style.modal_overlay}
    >
      <div className='py-10 px-15'>
        <button
          onClick={handleCloseUserForm}
          className='w-7 h-7 rounded-lg border border-icon-default opacity-30 px-2.5 absolute right-6 top-6'
        >
          <CloseIcon />
        </button>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register('email', {
              required: VALIDATION_MESSAGES.required,
              pattern: {
                value: EmailRegex,
                message: 'Invalid Email address',
              },
            })}
            name='email'
            placeholder='Enter email address'
            helperText='Email Address you are going to allow or block.'
            error={errors.email?.message}
          />

          <Select
            form={form}
            label='Status'
            name='status'
            options={UserStatusOptions}
            placeholder='Status'
          />
          <button className='rounded-2xl py-3 w-full text-white mt-4 bg-blue-500 flex justify-center'>
            Confirm
          </button>
        </form>
      </div>
    </Modal>
  )
})

export default UserForm
