import 'react-toastify/dist/ReactToastify.css'

import React, { useEffect } from 'react'
import ReactModal from 'react-modal'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from 'styled-components/macro'

import { theme } from './common/theme'
import Routes from './components/Routes/Routes'
import AppHeader from './container/AppHeader'
import { queryClient } from './utils/queryClient'

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('#root')
const NAV_HEIGHT = 68

const App: React.FC = () => {
  useEffect(() => {
    const handleScroll = () => {
      var nav = document.getElementById('header')
      const currentScrollY = window.scrollY
      if (currentScrollY > NAV_HEIGHT) {
        nav?.classList?.add('bg-main', 'bg-opacity-100')
      } else {
        nav?.classList?.remove('bg-main', 'bg-opacity-100')
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => window.removeEventListener('scroll', handleScroll)
  })

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <AppHeader />
          <div className='bg-main content'>
            <Routes />
            <ToastContainer />
          </div>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App
