export {
  MAXIMUM_PSL_AMOUNT,
  MINIMUM_PSL_AMOUNT,
} from '../../../common/constants'

export const VALIDATION_MESSAGES = {
  required: 'This field is required.',
}

export const CONVERSION_STATUS = {
  checkingFormStatus: 'Checking...',
  initial: 'Initiated',
  detected: 'Detected',
  pending: 'Pending',
  error: 'FatalError',
  firstBlock:
    'First block confirmation complete! Now waiting for second block confirmation...',
  pslConfirmed:
    'PSL transaction confirmed by network! Now sending wPSL transaction...',
  wpslConfirmed:
    'wPSL transaction confirmed by network! Now sending PSL transaction...',
  complete: 'Successful',
  failed: 'Failed',
}

export const conversionIntervalMs = 1000
export const eventTransferToPSLTimeoutMs = 120000

export enum ConversionStep {
  Register = 0,
  PSL = 1,
  WPSL = 2,
  Initiated = 'Initiated',
  FatalError = 'FatalError',
  Pending = 'pending',
}

export const DATA_FETCH_LIMIT = 20
export const DATA_OFFSET = 0
export const DATA_DEFAULT_SORT = 'ASC'

export enum ConversionType {
  Wrapping = 'PslToWpsl',
  Unwrapping = 'WpslToPsl',
}
export const UserStatusOptions = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Blocked',
    value: 'blocked',
  },
]

export const UserVerifyOptions = [
  {
    label: 'Verified',
    value: 'verified',
  },
]

export enum UserStatus {
  RequiresInput = 'requires_input',
  Processing = 'processing',
  Verified = 'verified',
  Canceled = 'canceled',
}
