import { observer } from 'mobx-react'
import React, { useEffect } from 'react'

import Button from '../../../common/components/Button/Button'
import InfinityTable from '../../../components/InfinityTable/InfinityTable'
import UserForm from '../../../components/UserForm'
import { DATA_FETCH_LIMIT, DATA_OFFSET } from '../../../constants/constants'
import { TUser } from '../../../mobx/api'
import whitelistStore from '../../../mobx/whitelistStore'

const columns = [
  {
    width: 30,
    minWidth: 30,
    flexGrow: 1,
    label: 'ID',
    dataKey: 'id',
    disableSort: false,
  },
  {
    width: 60,
    minWidth: 60,
    flexGrow: 1,
    label: 'Email',
    dataKey: 'email',
    disableSort: false,
  },
  {
    width: 60,
    minWidth: 60,
    flexGrow: 1,
    label: 'Status',
    dataKey: 'status',
    disableSort: false,
  },
  {
    width: 150,
    minWidth: 150,
    flexGrow: 1,
    label: 'Timestamp',
    dataKey: 'createdAt',
    disableSort: false,
  },
  {
    width: 150,
    minWidth: 150,
    flexGrow: 1,
    label: 'Actions',
    dataKey: 'actions',
    disableSort: false,
  },
]

const WhiteList = observer(() => {
  const {
    fetchParams,
    users,
    isOpen,
    handleOpenUserForm,
    handleFetchWhiteList,
    removeWhitelistUser,
    handleShowUpdateForm,
  } = whitelistStore

  const transformUsersData = (users: TUser[]) =>
    users.map(user => {
      const { id, email, status, createdAt } = user
      return {
        id,
        email,
        status,
        createdAt: new Date(createdAt).toISOString(),
        actions: (
          <div>
            <button onClick={() => handleShowUpdateForm(user)}>Edit</button>{' '}
            <button onClick={() => removeWhitelistUser(id)}>Delete</button>
          </div>
        ),
      }
    })

  const handleFetchMoreUsers = (reachedTableBottom: boolean) => {
    if (!reachedTableBottom) {
      return null
    }

    fetchParams.offset += DATA_FETCH_LIMIT

    return handleFetchWhiteList(
      fetchParams.offset,
      fetchParams.order,
      fetchParams.sortDirection,
    )
  }

  useEffect(() => {
    handleFetchWhiteList(
      DATA_OFFSET,
      fetchParams.order,
      fetchParams.sortDirection,
      true,
    )
      .then(() => {
        // noop
      })
      .catch(() => {
        // noop
      })
      .finally(() => {
        // noop
      })
  }, [])

  const handleAddUser = () => {
    handleOpenUserForm()
  }

  return (
    <div className='container py-5'>
      {isOpen && <UserForm />}
      <Button onClick={handleAddUser}>Add a new User</Button>
      <div className='overflow-hidden rounded-tl-none rounded-lg'>
        <InfinityTable
          sortDirection={fetchParams.sortDirection}
          rows={transformUsersData(users)}
          columns={columns}
          tableHeight={950}
          onBottomReach={handleFetchMoreUsers}
        />
      </div>
    </div>
  )
})

export default WhiteList
