import { makeAutoObservable } from 'mobx'
import moment from 'moment'
import { toast } from 'react-toastify'

import { TOption } from '../common/components/Select/Select'
import {
  DATA_DEFAULT_SORT,
  DATA_FETCH_LIMIT,
  DATA_OFFSET,
} from '../constants/constants'
import { ErrorWithDetail } from '../utils/ErrorWithDetail'
import { TUser } from './api'
import authStore, { AuthStore } from './authStore'

export type TVerifyUserForm = {
  email: string
  status: TOption
}

export class AdminStore {
  authStore: Readonly<AuthStore>
  isOpen = false
  isLoading = false
  user: TUser | null = null
  users: TUser[] = []

  constructor(authStore: Readonly<AuthStore>) {
    this.authStore = authStore
    makeAutoObservable(this)
  }

  handleShowUpdateForm = (user: TUser): void => {
    this.user = user
    this.isOpen = true
  }

  handleCloseUserForm = (): void => {
    this.user = null
    this.isOpen = false
  }

  fetchAllUsers = async (params: Record<string, string>) => {
    const query = new URLSearchParams(params).toString()
    try {
      const { data, error, errorDetail } = await this.authStore.ajaxGet(
        `/api/admin/users?${query}`,
      )
      if (error) {
        throw new ErrorWithDetail(error, errorDetail)
      }

      return data.data
    } catch (error) {
      toast.error(error)
      throw error
    }
  }

  updateVerifyUser = async ({
    email,
    status,
  }: {
    email: string
    status: string
  }) => {
    try {
      this.isLoading = true
      const date: string = moment().format('YYYYMMDD-HH:mm').toString() || ''
      const { error, errorDetail } = await this.authStore.ajaxPost(
        '/api/admin/user/verified',
        {
          email,
          stripeId: `verified_by_admin_${date}`,
          status,
        },
      )
      if (error) {
        this.isLoading = false
        throw new ErrorWithDetail(error, errorDetail)
      }

      this.users = await this.fetchAllUsers({
        offset: DATA_OFFSET.toString(),
        limit: DATA_FETCH_LIMIT.toString(),
        order: 'createdAt',
        sortDirection: DATA_DEFAULT_SORT,
      })
      this.isOpen = false
      this.isLoading = false
    } catch (error) {
      toast.error(error)
      this.isLoading = false
      throw error
    }
  }
}

const adminStore = new AdminStore(authStore)
export default adminStore as Readonly<AdminStore>
