import 'react-circular-progressbar/dist/styles.css'

import cn from 'classnames'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login'
import Modal from 'react-modal'
import { toast } from 'react-toastify'

import { ReactComponent as GoogleIcon } from '../../assets/google-icon.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/ico-union.svg'
import { ReactComponent as IdCardIcon } from '../../assets/id-card-icon.svg'
import { ReactComponent as LogoIcon } from '../../assets/Logo.svg'
import Spinner from '../../common/components/Spinner'
import IDVerifyModal from '../../components/IDVerifyModal'
import { TosModal } from '../../components/SectionTos'
import { googleClientId } from '../../config'
import { UserStatus } from '../../constants/constants'
import authStore from '../../mobx/authStore'
import GoogleSignIn from './googleSignIn'
import style from './Onboarding.module.scss'
import OnboardingStep from './onboardingStep'

const Onboarding: React.FC = observer(() => {
  const {
    googleToken,
    isTosOpen,
    submitting,
    idAnalyzerIframe,
    closeOnboardingModal,
    user,
  } = authStore
  const step = googleToken && user?.tosAgreedAt ? 2 : 1

  return (
    <Modal
      isOpen={true}
      onRequestClose={closeOnboardingModal}
      overlayClassName={`bg-modal ${style.modal_overlay}`}
      className={style.modal_content}
    >
      {isTosOpen ? <TosModal /> : null}
      <IDVerifyModal open={!!idAnalyzerIframe && !submitting} />
      <div
        className={`bg-white mx-auto grid grid-cols-2 gap-x-2 rounded-xl overflow-hidden ${style.onboarding_page}`}
      >
        <div className={`bg-onboarding ${style.steps}`}>
          <div className='flex items-start'>
            <LogoIcon className='w-9' />
            <div className='flex-grow ml-5'>
              <h1 className='text-3xl font-extrabold'>Onboarding</h1>
              <span className='text-nav-selected bg-white opacity-50 font-medium text-sm'>
                Set up your account to use WrappedPastel.com
              </span>
            </div>
            <div className='w-16 h-16'>
              <CircularProgressbar
                strokeWidth={12}
                value={(step / 2) * 100}
                text={`${step}/2`}
                styles={{
                  path: {
                    stroke: '#6DBD72',
                  },
                  text: {
                    fill: '#11142D',
                    fontSize: '18px',
                    fontWeight: 800,
                  },
                }}
              />
            </div>
          </div>
          <div className='mt-7'>
            <OnboardingStep
              icon={<GoogleIcon />}
              status={step === 2 ? 'checked' : 'active'}
              text='Google Authorization'
            />
            <div className={`${style.divider} h-9 ml-12`}></div>
            <OnboardingStep
              icon={<IdCardIcon />}
              status={step === 2 ? 'active' : 'default'}
              text='Identity Verification'
            />
          </div>
        </div>
        <div className='flex flex-col items-center justify-center py-3.5 pr-18 pl-14'>
          {step === 1 ? <GoogleAuth /> : <IdentityVerification />}
        </div>
      </div>
    </Modal>
  )
})

const GoogleAuth = () => (
  <React.Fragment>
    <p className='text-neutrals-400 text-lg text-center'>
      To continue the process of using{' '}
      <a className='text-blue-500' href='https://wrappedpastel.com'>
        wrappedpastel.com
      </a>
      , you must first log in with a valid Google account (this is to avoid
      spam/DDOS attacks).
    </p>
    <GoogleSignIn />
  </React.Fragment>
)

function RenderVerifyModal({
  setSubmitted,
}: {
  setSubmitted: (val: boolean) => void
}): JSX.Element | null {
  const {
    showIframeIDanalyzerModal,
    iframeIDanalyzerUrl,
    checkIDProcessing,
    hideIframeIDanalyzer,
  } = authStore

  const handleVerifyModalClose = () => {
    checkIDProcessing()
    setSubmitted(true)
    hideIframeIDanalyzer()
  }

  const onClick = useCallback(() => {
    handleVerifyModalClose()
  }, [handleVerifyModalClose])

  if (!showIframeIDanalyzerModal || !iframeIDanalyzerUrl) {
    return null
  }

  return (
    <div className='rounded-xl overflow-hidden absolute top-0 right-0 bottom-0 left-0 bg-white z-50'>
      <button
        onClick={onClick}
        className='w-7 h-7 rounded-lg border border-icon-default opacity-30 px-2.5 absolute right-6 top-10'
        type='button'
      >
        <CloseIcon />
      </button>
      <iframe
        src={iframeIDanalyzerUrl}
        frameBorder='0'
        allow='camera;microphone'
        className='w-full h-full'
        title='Verify Country of Origin using IDAnalyzer Identity'
      ></iframe>
    </div>
  )
}

const IdentityVerification: React.FC = observer(() => {
  const {
    isProcessing,
    user,
    idVerifySuccess,
    login,
    refetchUser,
    googleToken,
    showIframeIDanalyzer,
    isLoading,
  } = authStore
  const [submitted, setSubmitted] = useState<boolean>(false)

  useEffect(() => {
    refetchUser()
      .then(() => {
        // noop
      })
      .catch(() => {
        // noop
      })
      .finally(() => {
        // noop
      })
  }, [])

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    // Block native event handling.
    event.preventDefault()
    if (!user) {
      return
    }

    showIframeIDanalyzer()
      .then(() => {
        // noop
      })
      .catch(() => {
        // noop
      })
      .finally(() => {
        // noop
      })
  }

  if (submitted && isProcessing) {
    return (
      <>
        <Spinner />
        <h3 className='text-center text-gray-800 my-4'>
          Thanks for submitting your identity document.
        </h3>
        <p className='text-neutrals-400'>
          We are processing your verification.
        </p>
      </>
    )
  } else if (submitted && !isProcessing && !idVerifySuccess) {
    return (
      <>
        <h1>Failed Verifying Identity.</h1>
        <p>{user?.errorMessage}</p>
        <button
          className='w-full bg-blue-500 rounded-2xl text-sm text-white  py-3 px-8 mt-7.5'
          onClick={handleClick}
          type='button'
        >
          Try Again
        </button>
      </>
    )
  } else if (
    (submitted && idVerifySuccess) ||
    (user?.status === UserStatus.Verified && googleToken)
  ) {
    return (
      <>
        <h1>Success Verifying Identity.</h1>
        <GoogleLogin
          render={p => (
            <button
              className='w-full bg-blue-500 rounded-2xl text-sm text-white  py-3 px-8 mt-7.5'
              onClick={p.onClick}
              type='button'
            >
              Login with Google
            </button>
          )}
          clientId={googleClientId}
          onSuccess={async (
            res: GoogleLoginResponseOffline | GoogleLoginResponse,
          ) => {
            if ('tokenId' in res) {
              await login(res.tokenId, res.profileObj)
            }
          }}
          onFailure={(err: any) => {
            toast(err.message)
          }}
          cookiePolicy='single_host_origin'
        />
      </>
    )
  }

  return (
    <>
      <p className='text-neutrals-400 text-lg'>
        For regulatory reasons, the Wrapped Pastel conversion service is not
        available to citizens or residents of the United States (as well as
        certain other countries, such as Cuba and Iran).
      </p>
      <br />
      <p className='text-neutrals-400 text-lg'>
        We use IDAnalyzer{"'"}s Identity service to verify that your country of
        origin is compliant with all applicable laws and regulations.
      </p>
      <button
        className={cn(
          'w-full bg-blue-500 rounded-2xl text-sm text-white py-3 px-4 mt-7.5',
          isLoading && 'opacity-60',
        )}
        onClick={handleClick}
        disabled={isLoading}
        type='button'
      >
        Verify Country of Origin using IDAnalyzer Identity
      </button>
      <RenderVerifyModal setSubmitted={setSubmitted} />
    </>
  )
})

export default Onboarding
