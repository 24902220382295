import React from 'react'

import { tosData } from '../../assets/tos'
import * as Styles from './Tos.styles'

const TOS = () => {
  return (
    <Styles.TOSWrapper className='px-15 pb-10'>
      <div className='container'>
        <h1>Wrapped Pastel Terms of Service</h1>
        <div className='last-updated'>
          <em>Last Updated: July 2, 2021</em>
        </div>
        <div className='content'>{tosData}</div>
      </div>
    </Styles.TOSWrapper>
  )
}

export default TOS
