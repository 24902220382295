import styled from 'styled-components/macro'

export const TOSWrapper = styled.div`
  .container {
    padding-top: 3rem;
    font-weight: 400;
  }

  h1 {
    color: black;
    font-size: 1.5rem;
    margin-top: 1rem;
    font-weight: 800;
    letter-spacing: 0.2px;
  }

  .last-updated {
    margin-bottom: 25px;

    em {
      font-size: 84%;
    }
  }

  b {
    font-weight: 800;
  }

  .anchor {
    &,
    &:hover {
      text-decoration: none;
      color: #000;
    }
  }

  p,
  li,
  u {
    margin-bottom: 10px;
    font-size: 1rem;
    font-family: Avenir, Roboto, Arial, serif;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }

  ul,
  ol {
    padding-left: 20px;
    margin-bottom: 10px;

    li {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .sub-ul {
    padding-left: 28px;
  }

  .content {
    padding: 1.5rem;
    background: #fff;
    border-radius: 0.5rem;
  }
`
