import { makeAutoObservable, runInAction } from 'mobx'
import { toast } from 'react-toastify'

import { TOption } from '../common/components/Select/Select'
import { SortDirectionsType } from '../components/InfinityTable/InfinityTable'
import {
  DATA_DEFAULT_SORT,
  DATA_FETCH_LIMIT,
  DATA_OFFSET,
} from '../constants/constants'
import { ErrorWithDetail } from '../utils/ErrorWithDetail'
import { TUser } from './api'
import authStore, { AuthStore } from './authStore'

export type TWhitelistUserForm = {
  email: string
  status: TOption
}

interface IDataRef {
  offset: number
  order: string
  sortDirection: SortDirectionsType
}

export class WhitelistStore {
  authStore: Readonly<AuthStore>
  isOpen: boolean = false
  fetchParams: IDataRef = {
    offset: DATA_OFFSET,
    order: 'createdAt',
    sortDirection: DATA_DEFAULT_SORT,
  }
  users: TUser[] = []
  user: TUser | null = null

  constructor(authStore: Readonly<AuthStore>) {
    this.authStore = authStore
    makeAutoObservable(this)
  }

  handleOpenUserForm = () => {
    runInAction(() => {
      this.user = null
      this.isOpen = true
    })
  }

  handleCloseUserForm = () => {
    runInAction(() => {
      this.isOpen = false
    })
  }

  fetchAllWhitelistedUsers = async (params: Record<string, string>) => {
    const query = new URLSearchParams(params).toString()
    try {
      const { data, error, errorDetail } = await this.authStore.ajaxGet(
        `/api/admin/whitelist?${query}`,
      )
      if (error) {
        throw new ErrorWithDetail(error, errorDetail)
      }

      return data.data
    } catch (error) {
      toast.error(error)
      throw error
    }
  }

  handleShowUpdateForm = async (user: TUser) => {
    this.user = user
    this.isOpen = true
  }
  updateWhitelistUser = async (id: number, status: string, email: string) => {
    await this.authStore.ajaxPut(`/api/admin/whitelist/${id}`, {
      status,
      email,
    })
    await this.handleFetchWhiteList(
      DATA_OFFSET,
      this.fetchParams.order,
      this.fetchParams.sortDirection,
      true,
    )
  }

  removeWhitelistUser = async (id: number) => {
    await this.authStore.ajaxDelete(`/api/admin/whitelist/${id}`)
    await this.handleFetchWhiteList(
      DATA_OFFSET,
      this.fetchParams.order,
      this.fetchParams.sortDirection,
      true,
    )
  }

  addUserToWhitelist = async ({ email, status }: TWhitelistUserForm) => {
    const { data } = await this.authStore.ajaxPost('/api/admin/whitelist', {
      email,
      status: status.value,
    })

    if (data.error) {
      toast.error(data.error.detail)
    } else {
      runInAction(() => {
        this.isOpen = false
      })
      await this.handleFetchWhiteList(
        0,
        this.fetchParams.order,
        this.fetchParams.sortDirection,
        true,
      )
    }
  }

  handleFetchWhiteList = async (
    offset: number,
    order: string,
    sortDirection: SortDirectionsType,
    replaceData = false,
  ) => {
    this.fetchParams.order = order
    const limit = DATA_FETCH_LIMIT

    // Allow to sort by block height
    // In this situation if user will click on block column we need to sort by timestamp
    const fetchSortBy = this.fetchParams.order
    const users = await this.fetchAllWhitelistedUsers({
      offset: offset.toString(),
      limit: limit.toString(),
      order: fetchSortBy,
      sortDirection,
    })
    runInAction(() => {
      replaceData
        ? (this.users = users)
        : (this.users = [...this.users, ...users])
    })
  }
}

const whitelistStore = new WhitelistStore(authStore)
export default whitelistStore as Readonly<WhitelistStore>
