import { mdiCheck } from '@mdi/js'
import Icon from '@mdi/react'
import React from 'react'

import { ReactComponent as ArrowRightIcon } from '../../../assets/arrow-right.svg'

interface IOnboardingStepProps {
  icon?: React.SVGProps<SVGSVGElement>
  text: string
  status?: 'active' | 'checked' | 'default'
}

export const CheckIcon: React.FC = () => (
  <div className='bg-green-light rounded-full flex justify-center items-center w-10 h-10'>
    <Icon path={mdiCheck} className='text-green-default w-4 h-4' />
  </div>
)

const OnboardingStep: React.FC<IOnboardingStepProps> = ({
  icon,
  text,
  status = 'default',
}) => {
  return (
    <div
      className={`rounded-md py-3.5 px-7.5 flex items-center ${
        status === 'active' ? 'bg-gray-400' : ''
      } ${status === 'default' ? 'opacity-60' : ''}`}
    >
      {status !== 'checked' ? icon : <CheckIcon />}
      <p
        className={`font-semibold ml-8 flex-grow ${
          status === 'active' ? 'text-neutrals-200' : 'text-gray-700'
        }`}
      >
        {text}
      </p>
      {status === 'active' && <ArrowRightIcon />}
    </div>
  )
}

export default OnboardingStep
