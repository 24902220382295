import React, { forwardRef } from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format'

import Input from './Input'

const InputNumberFormat = forwardRef<HTMLInputElement, NumberFormatProps>(
  ({ displayType = 'input', ...props }, ref) => {
    return (
      <NumberFormat
        displayType={displayType}
        customInput={Input}
        thousandSeparator={true}
        className='w-full disabled:opacity-50'
        {...props}
      />
    )
  },
)

export default InputNumberFormat
