import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'

import InfinityTable, {
  ISortData,
  SortDirectionsType,
} from '../../../components/InfinityTable/InfinityTable'
import VerifyUserForm from '../../../components/VerifyUserForm'
import {
  DATA_DEFAULT_SORT,
  DATA_FETCH_LIMIT,
  DATA_OFFSET,
} from '../../../constants/constants'
import adminStore from '../../../mobx/adminStore'
import { TUser } from '../../../mobx/api'

interface IDataRef {
  offset: number
  order: string
  sortDirection: SortDirectionsType
}
const columns = [
  {
    width: 30,
    minWidth: 30,
    flexGrow: 1,
    label: 'ID',
    dataKey: 'id',
    disableSort: false,
  },
  {
    width: 60,
    minWidth: 60,
    flexGrow: 1,
    label: 'Email',
    dataKey: 'email',
    disableSort: false,
  },
  {
    width: 20,
    minWidth: 20,
    flexGrow: 1,
    label: 'Country',
    dataKey: 'country_code',
    disableSort: false,
  },
  {
    width: 60,
    minWidth: 60,
    flexGrow: 1,
    label: 'Status',
    dataKey: 'status',
    disableSort: false,
  },
  {
    width: 150,
    minWidth: 150,
    flexGrow: 1,
    label: 'Timestamp',
    dataKey: 'createdAt',
    disableSort: false,
  },
  {
    width: 60,
    minWidth: 60,
    flexGrow: 1,
    label: 'Actions',
    dataKey: 'actions',
    disableSort: false,
  },
]

function EditButton({ user }: { user: TUser }) {
  const { handleShowUpdateForm } = adminStore

  const onClick = useCallback(() => {
    handleShowUpdateForm(user)
  }, [handleShowUpdateForm])

  return (
    <button type='button' onClick={onClick}>
      Edit
    </button>
  )
}

const UserList = observer(() => {
  const { fetchAllUsers, isOpen, users: usersList } = adminStore
  const [users, setUsers] = useState<TUser[]>(usersList)

  const transformUsersData = (users: TUser[]) =>
    users.map(user => {
      const { id, email, status, country_code, createdAt } = user
      return {
        id,
        email,
        country_code,
        status,
        createdAt: new Date(createdAt).toISOString(),
        actions: (
          <div>
            <EditButton user={user} />
          </div>
        ),
      }
    })

  const fetchParams = React.useRef<IDataRef>({
    offset: DATA_OFFSET,
    order: 'createdAt',
    sortDirection: DATA_DEFAULT_SORT,
  })

  const handleFetchUsers = async (
    offset: number,
    order: string,
    sortDirection: SortDirectionsType,
    replaceData = false,
  ) => {
    fetchParams.current.order = order
    const limit = DATA_FETCH_LIMIT

    // Allow to sort by block height
    // In this situation if user will click on block column we need to sort by timestamp
    const fetchSortBy = fetchParams.current.order
    const users = await fetchAllUsers({
      offset: offset.toString(),
      limit: limit.toString(),
      order: fetchSortBy,
      sortDirection,
    })
    replaceData
      ? setUsers(users)
      : setUsers((prev: TUser[]) => [...prev, ...users])
  }

  const handleSort = ({ sortBy, sortDirection }: ISortData) => {
    fetchParams.current.offset = DATA_OFFSET
    fetchParams.current.sortDirection = sortDirection

    return handleFetchUsers(
      DATA_OFFSET,
      sortBy,
      fetchParams.current.sortDirection,
      true,
    )
  }

  const handleFetchMoreUsers = (reachedTableBottom: boolean) => {
    if (!reachedTableBottom) {
      return null
    }

    fetchParams.current.offset += DATA_FETCH_LIMIT

    return handleFetchUsers(
      fetchParams.current.offset,
      fetchParams.current.order,
      fetchParams.current.sortDirection,
    )
  }

  useEffect(() => {
    if (usersList.length) {
      setUsers(usersList)
    }
  }, [usersList])

  useEffect(() => {
    handleFetchUsers(
      DATA_OFFSET,
      fetchParams.current.order,
      fetchParams.current.sortDirection,
    )
      .then(() => {
        // noop
      })
      .catch(() => {
        // noop
      })
      .finally(() => {
        // noop
      })
  }, [])

  return (
    <div className='container py-5'>
      {isOpen && <VerifyUserForm />}
      <div className='overflow-hidden rounded-tl-none rounded-lg'>
        <InfinityTable
          sortDirection={fetchParams.current.sortDirection}
          rows={transformUsersData(users)}
          columns={columns}
          tableHeight={950}
          onBottomReach={handleFetchMoreUsers}
          onHeaderClick={handleSort}
        />
      </div>
    </div>
  )
})

export default UserList
