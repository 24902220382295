import { action, makeAutoObservable, runInAction } from 'mobx'
import Web3 from 'web3'

import { WPSL_DECIMALS, WPSL_IMAGE } from '../../../common/constants'
import { Wpsl } from '../../types/web3-v1-contracts/wpsl'
import abi from '../assets/wpsl.json'
import { web3Networks, web3WpslAddresses } from '../config'
import {
  CONVERSION_STATUS,
  conversionIntervalMs,
  ConversionStep,
} from '../constants/constants'
import { ErrorWithDetail } from '../utils/ErrorWithDetail'
import { WPSL_SYMBOL } from './../../../common/constants'
import { TConversion } from './api'
import authStore from './authStore'
import transactionStore from './transactionStore'

export interface Erc20Transaction {
  hash: string
  amount: number
  sender: string
  receiver: string
  block: number
  createdAt: string
}

const isEthereumAvailable = 'ethereum' in window

export class Web3Store {
  constructor() {
    makeAutoObservable(this)
  }

  isEthereumAvailable = isEthereumAvailable
  alreadyInitWeb3 = false
  web3!: Web3
  web3Network = ''
  singlePslAddress = ''
  singleEthAddress = ''
  wpsl = 0
  isValidConversionData = false
  isValidPSLConversionData = false
  conversionTimerId?: NodeJS.Timeout
  pslConversionTimerId?: NodeJS.Timeout
  transaction: TConversion | null = null
  conversionStatus: string = CONVERSION_STATUS.initial
  conversionStep = ConversionStep.Register

  // Vriables for PSL to WPSL conversion
  transferToWpslLoading = false
  transferToWpslSuccess = false
  showToWpslProcess = false
  conversionStatusMessage = ''
  // Variables for WPSL to PSL conversion
  transferToPslLoading = false
  transferToPslSuccess = false

  transactions = [] as TConversion[]
  transactionsFetching = false
  transactionsFetchFailed = false
  transactionsAlreadyFetchedOnce = false

  web3Wpsl!: Wpsl
  get web3WpslAddress() {
    return web3WpslAddresses[web3Networks.indexOf(this.web3Network)]
  }

  getWpslConversionById = async (conversionId: string) => {
    const { data } = await authStore.ajaxGet(
      `/api/user/get-psl-wpsl-conversion/${conversionId}`,
    )
    return data
  }
  getPslConversionById = async (conversionId: string) => {
    const { data } = await authStore.ajaxGet(
      `/api/user/get-wpsl-psl-conversion/${conversionId}`,
    )
    return data
  }

  getPslConversionByIdForRetry = async (conversionId: string) => {
    const { data } = await authStore.ajaxGet(
      `/api/user/get-wpsl-psl-conversion-for-retry/${conversionId}`,
    )
    this.pslTransaction = data
    this.isValidPSLConversionData = true
    this.singleEthAddress = data?.ethAddress
  }

  resetPslConversionByIdForRetry = () => {
    this.transaction = null
    this.isValidPSLConversionData = false
    this.transferToPslLoading = false
    this.singleEthAddress = ''
  }

  getWpslConversionByIdForRetry = async (conversionId: string) => {
    const { data } = await authStore.ajaxGet(
      `/api/user/get-psl-wpsl-conversion-for-retry/${conversionId}`,
    )
    this.transaction = data
    this.isValidConversionData = true
    this.singlePslAddress = data?.pslAddress
    this.wpsl = data?.wpsl
    this.conversionStep = ConversionStep.Initiated
  }

  getBlockNumber = async () => await this.web3.eth.getBlockNumber()

  watchEthereumAvailable = async () => {
    this.isEthereumAvailable = 'ethereum' in window
    if (!this.isEthereumAvailable || this.alreadyInitWeb3) {
      return
    }

    this.alreadyInitWeb3 = true

    this.web3 = new Web3(window.ethereum as any)
    await this.web3.eth.net
      .getNetworkType()
      .then(
        action((networkType: string) => {
          this.web3Network = networkType
        }),
      )
      .catch(
        action(() => {
          this.web3Network = 'error'
        }),
      )

    if (!this.web3WpslAddress) {
      return
    }

    this.web3Wpsl = (new this.web3.eth.Contract(
      abi as any,
      this.web3WpslAddress,
    ) as any) as Wpsl

    this.loadStats()
  }

  stats?: TWpslData
  statsFetching = false
  statsFetchFailed = false

  loadStats = () => {
    this.statsFetching = true
    this.statsFetchFailed = false
    Promise.all([
      this.web3Wpsl.methods.name().call(),
      this.web3Wpsl.methods.totalSupply().call(),
      this.web3Wpsl.methods.sentToPSL().call(),
    ])
      .then(
        action(([name, totalSupply, sentToPSL]) => {
          this.stats = {
            name,
            totalSupply: Number(totalSupply),
            sentToPSL: Number(sentToPSL),
          }
        }),
      )
      .catch(
        action(() => {
          this.statsFetchFailed = true
        }),
      )
      .finally(
        action(() => {
          this.statsFetching = false
        }),
      )
  }

  showToPslProcess = false
  pslTransaction: TConversion | null = null

  checkOutgoingPSLTransaction = async (conversionId: string) => {
    this.pslConversionTimerId = setInterval(async () => {
      this.pslTransaction = await this.getPslConversionById(conversionId)
      await this.trackPslConverionStatus()
    }, conversionIntervalMs)
    await this.completePslTransfer(conversionId)
  }

  initiatePslConversion = async (
    targetAddress: string,
    amount: number,
    ethAddress: string,
  ) => {
    if (this.transferToPslLoading) {
      return
    }
    this.showToPslProcess = true
    this.transferToPslLoading = true
    this.conversionStatus = CONVERSION_STATUS.checkingFormStatus
    this.conversionStatusMessage = ''
    try {
      await authStore
        .ajaxPost('/api/user/init-psl-transfer', {
          ethAddress,
          targetAddress,
          amount,
        })
        .then(res => {
          if (!res.error) {
            this.pslTransaction = res.data
            if (!this.isValidPSLConversionData) {
              this.conversionStep = ConversionStep.Initiated
              if (this.pslTransaction?.status === CONVERSION_STATUS.error) {
                this.conversionStatus = CONVERSION_STATUS.error
                this.conversionStatusMessage =
                  this.pslTransaction.statusErrorDetail ||
                  this.pslTransaction.statusErrorDetail
              } else if (
                this.pslTransaction?.status === CONVERSION_STATUS.initial
              ) {
                this.conversionStatus = CONVERSION_STATUS.initial
                this.showToPslProcess = false
                this.transferToPslLoading = false
                this.singleEthAddress = this.pslTransaction?.ethAddress
                this.wpsl = this.pslTransaction?.wpsl
              }
              this.isValidPSLConversionData =
                this.pslTransaction?.status === CONVERSION_STATUS.initial
            }
          } else if (
            res.error !== 'No Transactions' &&
            res.error !== 'Multiple transactions'
          ) {
            this.showToPslProcess = false
            throw new ErrorWithDetail(res.error, res.errorDetail)
          }
          this.transferToPslLoading = false
        })
    } catch (err) {
      runInAction(() => {
        this.conversionStatus = CONVERSION_STATUS.failed
        this.transferToPslLoading = false
      })
      window.alert(
        `Failed to convert into PSL. Error: ${ErrorWithDetail.format(err)}`,
      )
    }
  }

  transferToPsl = async () => {
    if (this.pslTransaction?.id) {
      this.showToPslProcess = true
      this.transferToPslLoading = true
      this.conversionStatus = CONVERSION_STATUS.checkingFormStatus
      this.conversionStatusMessage = ''
      await this.checkOutgoingPSLTransaction(this.pslTransaction.id)
    }
  }

  addTokenToMetamask = () => {
    window.ethereum
      .request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: this.web3WpslAddress,
            symbol: WPSL_SYMBOL,
            decimals: WPSL_DECIMALS,
            image: WPSL_IMAGE,
          },
        },
      })
      .then(success => {
        if (!success) {
          throw new Error('Something went wrong.')
        }
      })
      .catch(error => {
        throw error
      })
  }

  hideToWpslProcess = () => {
    transactionStore.closePslToWpslModal()
    this.initWpslStatus()
  }

  hideErrorModal = () => {
    this.showToWpslProcess = false
  }

  hideToPslProcess = () => {
    this.showToPslProcess = false
    transactionStore.closeWpslToPslModal()
    transactionStore.fetchTransactions()
  }

  trackPslConverionStatus = async () => {
    if (this.pslTransaction?.status == CONVERSION_STATUS.initial) {
      this.conversionStatus = CONVERSION_STATUS.initial
      this.conversionStatusMessage =
        this.pslTransaction?.updatedMessage ||
        this.pslTransaction?.statusErrorDetail
    }
    if (this.pslTransaction?.status == CONVERSION_STATUS.error) {
      this.conversionStatus = CONVERSION_STATUS.error
      this.conversionStatusMessage = this.pslTransaction.statusErrorDetail
      clearInterval(this.pslConversionTimerId!)
      this.pslConversionTimerId = undefined
      if (this.pslTransaction?.id) {
        this.transactions.unshift(this.pslTransaction)
      }
    }
    if (this.pslTransaction?.status == CONVERSION_STATUS.failed) {
      this.conversionStatus = CONVERSION_STATUS.failed
      this.conversionStatusMessage = this.pslTransaction.statusErrorDetail
      clearInterval(this.pslConversionTimerId!)
      this.pslConversionTimerId = undefined
      if (this.pslTransaction?.id) {
        this.transactions.unshift(this.pslTransaction)
      }
    }
    if (this.pslTransaction?.status == CONVERSION_STATUS.pending) {
      this.conversionStatus = CONVERSION_STATUS.pending
      this.conversionStatusMessage =
        this.pslTransaction?.updatedMessage ||
        this.pslTransaction?.statusErrorDetail ||
        ''
    } else if (this.pslTransaction?.status === CONVERSION_STATUS.complete) {
      this.conversionStatus = CONVERSION_STATUS.complete
      this.conversionStatusMessage =
        this.pslTransaction?.updatedMessage ||
        this.pslTransaction?.updatedMessage ||
        ''
      clearInterval(this.pslConversionTimerId!)
      this.pslConversionTimerId = undefined
      if (this.pslTransaction?.id) {
        this.transactions.unshift(this.pslTransaction)
      }
      transactionStore.fetchTransactions()

      if (!authStore.user?.firstPslConversionPassed) {
        await authStore.refetchUser()
      }
    }
  }
  trackWpslConversionStatus = async () => {
    if (this.transaction?.status == CONVERSION_STATUS.initial) {
      this.conversionStatus = CONVERSION_STATUS.initial
      this.conversionStatusMessage =
        this.transaction?.updatedMessage || this.transaction?.statusErrorDetail
    }
    if (this.transaction?.status == CONVERSION_STATUS.error) {
      this.conversionStatus = CONVERSION_STATUS.error
      this.conversionStatusMessage = this.transaction.statusErrorDetail
      clearInterval(this.conversionTimerId!)
      this.conversionTimerId = undefined
      if (this.transaction?.id) {
        this.transactions.unshift(this.transaction)
      }
    }
    if (this.transaction?.status == CONVERSION_STATUS.failed) {
      this.conversionStatus = CONVERSION_STATUS.failed
      this.conversionStatusMessage = this.transaction.statusErrorDetail
      clearInterval(this.conversionTimerId!)
      this.conversionTimerId = undefined
      if (this.transaction?.id) {
        this.transactions.unshift(this.transaction)
      }
    }
    if (this.transaction?.status == CONVERSION_STATUS.pending) {
      this.conversionStatus = CONVERSION_STATUS.pending
      this.conversionStatusMessage =
        this.transaction?.updatedMessage ||
        this.transaction?.updatedMessage ||
        ''
    } else if (this.transaction?.status === CONVERSION_STATUS.complete) {
      this.conversionStatus = CONVERSION_STATUS.complete
      this.conversionStatusMessage =
        this.transaction?.updatedMessage ||
        this.transaction?.updatedMessage ||
        ''
      clearInterval(this.conversionTimerId!)
      this.conversionTimerId = undefined
      if (this.transaction?.id) {
        this.transactions.unshift(this.transaction)
      }
      transactionStore.fetchTransactions()

      if (!authStore.user?.firstWpslConversionPassed) {
        await authStore.refetchUser()
      }
    }
  }

  transferWpsl = async () => {
    this.showToWpslProcess = true
    this.conversionTimerId = setInterval(async () => {
      if (this.conversionStep === ConversionStep.Initiated) {
        await this.completeWpslTransfer()
        this.conversionStep = ConversionStep.PSL
      } else if (
        this.conversionStep === ConversionStep.PSL &&
        this.transaction
      ) {
        this.transaction = await this.getWpslConversionById(this.transaction.id)
        await this.trackWpslConversionStatus()
      }
    }, conversionIntervalMs)
  }
  initiateWpslConversion = async (
    amount: number,
    targetAddress: string,
    rPslAddress: string,
  ) => {
    if (this.transferToWpslLoading) {
      return
    }
    this.showToWpslProcess = true
    this.transferToWpslLoading = true
    this.conversionStatus = CONVERSION_STATUS.checkingFormStatus
    this.conversionStep = ConversionStep.Register
    this.conversionStatusMessage = ''
    try {
      await authStore
        .ajaxPost('/api/user/initiate-to-wpsl', {
          network: this.web3Network,
          amount,
          targetAddress,
          rPslAddress,
        })
        .then(res => {
          if (!res.error) {
            this.transaction = res.data
            if (!this.isValidConversionData) {
              this.conversionStep = ConversionStep.Initiated
              if (this.transaction?.status === CONVERSION_STATUS.error) {
                this.conversionStatus = CONVERSION_STATUS.error
                this.conversionStatusMessage =
                  this.transaction.statusErrorDetail ||
                  this.transaction.statusErrorDetail
              } else if (
                this.transaction?.status === CONVERSION_STATUS.initial
              ) {
                this.conversionStatus = CONVERSION_STATUS.initial
                this.showToWpslProcess = false
                this.transferToWpslLoading = false
                this.singlePslAddress = this.transaction?.pslAddress
                this.wpsl = this.transaction?.wpsl
              }
              this.isValidConversionData =
                this.transaction?.status === CONVERSION_STATUS.initial
            }
          } else if (
            res.error !== 'No Transactions' &&
            res.error !== 'Multiple transactions'
          ) {
            this.showToWpslProcess = false
            throw new ErrorWithDetail(res.error, res.errorDetail)
          }
          this.transferToWpslLoading = false
        })
    } catch (err) {
      runInAction(() => {
        this.transferToWpslLoading = false
        this.conversionStatus = CONVERSION_STATUS.failed
      })
      window.alert(
        `Failed to convert into wPSL. Error: ${ErrorWithDetail.format(err)}`,
      )
    }
  }
  initWpslStatus = () => {
    this.isValidConversionData = false
    this.singlePslAddress = ''
    this.showToWpslProcess = false
    this.transferToWpslLoading = false
    this.conversionStatus = ''
    this.conversionStep = ConversionStep.Register
  }
  completeWpslTransfer = async () => {
    if (this.transferToWpslLoading) {
      return
    }
    this.transferToWpslLoading = true
    try {
      await authStore.ajaxGet(
        `/api/user/transfer-to-wpsl/${this.transaction?.id}`,
      )
    } catch (err) {
      runInAction(() => {
        this.transferToWpslLoading = false
        this.showToWpslProcess = false
        this.conversionStatus = CONVERSION_STATUS.failed
      })
      window.alert(
        `Failed to convert into wPSL. Error: ${ErrorWithDetail.format(err)}`,
      )
    }
  }

  completePslTransfer = async (conversionId: string) => {
    this.transferToPslLoading = true
    try {
      await authStore.ajaxGet(`/api/user/transfer-to-psl/${conversionId}`)
    } catch (err) {
      runInAction(() => {
        this.transferToPslLoading = false
        this.showToPslProcess = false
        this.conversionStatus = CONVERSION_STATUS.failed
      })
      window.alert(
        `Failed to convert into wPSL. Error: ${ErrorWithDetail.format(err)}`,
      )
    }
  }

  transferToWpslReset = () => {
    this.transferToWpslSuccess = false
    this.isValidConversionData = false
    this.transaction = null
    this.initWpslStatus()
  }

  fetchTransactions = () => {
    if (this.transactionsAlreadyFetchedOnce) {
      return
    }
    this.refetchTransactions()
    this.transactionsAlreadyFetchedOnce = true
  }
  refetchTransactions = () => {
    if (this.transactionsFetching) {
      return
    }
    this.transactionsFetching = true
    this.transactionsFetchFailed = false

    authStore
      .ajaxGet('/api/user/get-conversions')
      .then(
        action(async j => {
          if (j.error) {
            throw new ErrorWithDetail(j.error, j.errorDetail)
          }
          const map = this.transactions.reduce((m, t) => {
            // @ts-ignore
            m[t.id] = 1
            return m
          }, {} as { [k: number]: boolean })
          const arr = j.data.data as TConversion[]
          // @ts-ignore
          arr.filter(t => !map[t.id]).forEach(t => this.transactions.push(t))
          await authStore.refetchUser()
        }),
      )
      .catch(
        action(err => {
          this.transactionsFetchFailed = true
        }),
      )
      .finally(
        action(() => {
          this.transactionsFetching = false
        }),
      )
  }
}

const web3Store = new Web3Store()
export default web3Store as Readonly<Web3Store>

// Watch and wait for window.ethereum to be available
web3Store
  .watchEthereumAvailable()
  .then(() => {
    // noop
  })
  .catch(() => {
    // noop
  })
  .finally(() => {
    // noop
  })
window.setInterval(web3Store.watchEthereumAvailable, 3000)

export type TWpslData = {
  name: string
  totalSupply: number
  sentToPSL: number
}
export type TTransferToPsl = {
  targetAddress: string
  amount: string
  ethAddress: string
  zEthAddress: string
}

export type TtransferToWpsl = {
  targetAddress: string
  zPslAddress: string
  rPslAddress: string
  amount: string
}

declare global {
  interface Window {
    // https://docs.metamask.io/guide/ethereum-provider.html#methods
    ethereum: {
      isMetaMask: boolean
      request: <T extends { method: string }>(o: T) => Promise<unknown>
      selectedAddress?: string
    }
  }
}
