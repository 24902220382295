import HtmlReactParser from 'html-react-parser'
import { observer } from 'mobx-react'
import React from 'react'
import Modal from 'react-modal'

import authStore from '../../mobx/authStore'
import style from './IDVerifyModal.module.scss'

interface IIDverifyModalProps {
  open: boolean
}

const IDVerifyModal: React.FC<IIDverifyModalProps> = observer(({ open }) => {
  const { idAnalyzerIframe } = authStore

  return (
    <Modal
      isOpen={open}
      contentLabel='ID Verification'
      className={style.modal_content}
      overlayClassName={style.modal_overlay}
    >
      <div>{HtmlReactParser(idAnalyzerIframe)}</div>
    </Modal>
  )
})

export default IDVerifyModal
