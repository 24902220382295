import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Modal from 'react-modal'
import { toast } from 'react-toastify'

import {
  CONVERSION_FEE,
  MAXIMUM_PSL_AMOUNT,
  MINIMUM_PSL_AMOUNT,
  MINIMUM_TEST_PSL_AMOUNT,
} from '../../../../common/constants'
import { ReactComponent as HelpIcon } from '../../assets/icons/ico-help.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/ico-union.svg'
import Input from '../../common/components/Input/Input'
import InputNumberFormat from '../../common/components/Input/InputNumberFormat'
import { VALIDATION_MESSAGES } from '../../constants/constants'
import authStore from '../../mobx/authStore'
import transactionStore from '../../mobx/transactionStore'
import web3Store, { TtransferToWpsl } from '../../mobx/web3Store'
import { formatNumber, parseFormattedNumber } from '../../utils/lib'
import style from './PslToWpslModal.module.scss'

const PslToWpslModal: React.FC = observer(() => {
  const {
    initiateWpslConversion,
    conversionTimerId,
    singlePslAddress,
    isValidConversionData,
    transferWpsl,
    transferToWpslReset,
    wpsl,
    transaction,
  } = web3Store
  const { user } = authStore
  const [checked, setChecked] = useState(false)
  const {
    pslToWpslModal: open,
    closePslToWpslModal,
    retryPslToWpsl,
  } = transactionStore
  const {
    register,
    setValue,
    control,
    handleSubmit,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm<TtransferToWpsl>()
  const amount = parseFormattedNumber(watch('amount', '0'))
  const [defaultAmount, setDefaultAmount] = useState('')
  const min = user?.firstWpslConversionPassed
    ? MINIMUM_PSL_AMOUNT
    : MINIMUM_TEST_PSL_AMOUNT

  const handleConfirmCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.currentTarget.checked)
  }

  const setShieldAddress = (address: string) => {
    setValue('zPslAddress', address)
  }
  const closeWpslModal = () => {
    transferToWpslReset()
    closePslToWpslModal()
    reset()
  }

  useEffect(() => {
    if (!open) {
      reset()
    }
  }, [open])

  useEffect(() => {
    setShieldAddress(singlePslAddress)
  }, [singlePslAddress.length, isValidConversionData])

  const openPastelWallet = async () => {
    if (wpsl <= amount) {
      toast.warn(
        'Sorry, the WrappedPastel Conversion Service is currently unable to process your requested conversion transaction at this time. Please try again later.',
      )
      return
    }

    window.open(`pastel://send?to=${getValues('zPslAddress')}&amount=${amount}`)
  }

  const onSubmit = async ({ targetAddress, rPslAddress }: TtransferToWpsl) => {
    if (!isValidConversionData) {
      await initiateWpslConversion(amount, targetAddress, rPslAddress)
    } else {
      await transferWpsl()
    }
  }

  useEffect(() => {
    if (retryPslToWpsl) {
      if (transaction?.amount) {
        setDefaultAmount(formatNumber(transaction.amount))
        setValue('amount', `${formatNumber(transaction.amount)}`)
      }
      if (transaction?.ethAddress) {
        setValue('targetAddress', `${transaction.ethAddress}`)
      }
      if (transaction?.targetAddress) {
        setValue('zPslAddress', `${transaction.targetAddress}`)
      }
      if (transaction?.pslAddress) {
        setValue('rPslAddress', `${transaction.pslAddress}`)
      }
      setChecked(true)
    } else {
      setDefaultAmount('')
      setValue('targetAddress', '')
      setValue('zPslAddress', '')
      setValue('rPslAddress', '')
      setChecked(false)
    }
  }, [retryPslToWpsl])

  return (
    <Modal
      isOpen={open}
      contentLabel='Psl To Wpsl Modal'
      className={style.modal_content}
      overlayClassName={style.modal_overlay}
    >
      <div className='py-10 px-15 relative'>
        <button
          onClick={closeWpslModal}
          className='w-7 h-7 rounded-lg border border-icon-default opacity-30 px-2.5 absolute right-6 top-8'
        >
          <CloseIcon />
        </button>
        <h2 className='text-gray-800 font-extrabold	'>
          Convert PSL to Wrapped PSL
        </h2>
        <p className='text-neutrals-400 mt-2'>
          Minimum {formatNumber(min)} PSL, maximum{' '}
          {formatNumber(MAXIMUM_PSL_AMOUNT)} PSL
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputNumberFormat
            {...register('amount', {
              required: VALIDATION_MESSAGES.required,
              validate: {
                min: val =>
                  parseInt(val.replaceAll(',', '')) >= min ||
                  `Amount must be more than ${formatNumber(
                    min,
                  )} and less than ${formatNumber(MAXIMUM_PSL_AMOUNT)}`,
                max: val =>
                  parseInt(val.replaceAll(',', '')) <= MAXIMUM_PSL_AMOUNT ||
                  `Amount must be more than ${formatNumber(
                    min,
                  )} and less than ${formatNumber(MAXIMUM_PSL_AMOUNT)}`,
              },
            })}
            name='amount'
            value={defaultAmount || amount}
            type='text'
            readOnly={isValidConversionData || retryPslToWpsl}
            placeholder='Enter amount of PSL to change'
            helperText={
              amount
                ? `Conversion fee (${CONVERSION_FEE * 100}%) of ${formatNumber(
                    amount,
                  )} PSL. You will receive ${formatNumber(
                    amount - amount * CONVERSION_FEE,
                  )} wPSL after fees`
                : `Conversion fee (${
                    CONVERSION_FEE * 100
                  }%) - no data to calculate yet`
            }
            error={errors.amount?.message}
          />
          <Controller
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <Input
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                  onChange(event.currentTarget.value)
                }}
                readOnly={isValidConversionData || retryPslToWpsl}
                value={value || ''}
                placeholder='Paste your ETH address '
                helperText='ETH address where you want to receive wPSL'
                {...fieldProps}
                error={errors.targetAddress?.message}
              />
            )}
            rules={{
              required: true,
            }}
            control={control}
            name='targetAddress'
          />
          {isValidConversionData && (
            <Controller
              render={({ field: { value, ...fieldProps } }) => (
                <Input
                  value={value || ''}
                  placeholder='PSL Shielded Address'
                  readOnly
                  helperText='Your unique temporary z-address for Pastel wallet recipient field'
                  {...fieldProps}
                  error={errors.zPslAddress?.message}
                />
              )}
              control={control}
              name='zPslAddress'
              rules={{
                required: true,
              }}
            />
          )}

          <Controller
            render={({ field: { value, ...fieldProps } }) => (
              <Input
                value={value || ''}
                placeholder='PSL Transparent Address to send a refund if required'
                helperText='Your PSL transparent address where a refund should be sent if required.'
                {...fieldProps}
                readOnly={isValidConversionData || retryPslToWpsl}
                error={errors.rPslAddress?.message}
              />
            )}
            control={control}
            name='rPslAddress'
            rules={{
              required: true,
            }}
          />
          <div className='mt-8'>
            {isValidConversionData ? (
              <>
                <p className='text-gray-800 font-semibold'>Next Steps:</p>
                <ol className='list-decimal list-inside text-neutrals-400 mb-4'>
                  <li>Go to Pastel Wallet</li>
                  <li>
                    Input copied z-address in recipient field
                    <span className='has-tooltip'>
                      <span className='tooltip rounded shadow-lg p-4 font-normal bg-gray-100 text-gray-600 mt-8 w-80 transform -translate-x-1/2'>
                        Note: If your PSL transaction is for an amount less than{' '}
                        {formatNumber(amount || 0)} PSL, your transaction will
                        be refunded to the refund address you specified (minus a
                        1 PSL transaction fee). If you send more than required,
                        we will refund the overage to you.
                      </span>
                      <HelpIcon className={style.custom_help_icon} />
                    </span>
                  </li>
                </ol>
                <p className='text-neutrals-400'>
                  Or,{' '}
                  <a className='cursor-pointer' onClick={openPastelWallet}>
                    click here
                  </a>{' '}
                  to launch your Pastel wallet software and pre-populate a
                  transaction with the required information that you can submit;
                  your web browser will then ask you to confirm that you want to
                  open the Pastel Fullnode Wallet.
                </p>
                {conversionTimerId ? (
                  <div className='callout callout-medium text-base'>
                    Transaction in progress: 2 to 5 minutes remaining to confirm
                    on the Pastel Blockchain...
                  </div>
                ) : (
                  <div className='callout callout-medium text-base'>
                    Please send the required PSL to the address specified to
                    begin the wrapping process...
                  </div>
                )}
              </>
            ) : null}
            <input
              type='checkbox'
              checked={checked}
              onChange={handleConfirmCheck}
              id='account-confirm'
              className='w-4 h-4 checked:bg-blue-600 checked:border-transparent'
            />
            <label htmlFor='account-confirm' className='text-neutrals-400'>
              {' '}
              I agree that the{' '}
              <a href='https://wrappedpastel.com'>wrappedpastel.com</a> account
              I am using now is my own account, and that the identity documents
              used to verify my residency on IDAnalyzer Identity belong to me.
            </label>
            <button
              className={classNames('rounded-2xl py-3 w-full text-white mt-4', {
                'text-gray-500': !checked,
                'bg-gray-300': !checked,
                'bg-blue-500': checked,
              })}
              disabled={!checked}
              type='submit'
            >
              {!isValidConversionData
                ? 'Validate'
                : retryPslToWpsl
                ? 'Convert Again'
                : 'Convert'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
})
export default PslToWpslModal
