import HtmlReactParser from 'html-react-parser'
import { observer } from 'mobx-react'
import React from 'react'
import Modal from 'react-modal'

import Button from '../../common/components/Button/Button'
import Spinner from '../../common/components/Spinner'
import { CONVERSION_STATUS } from '../../constants/constants'
import web3Store from '../../mobx/web3Store'
import style from './PslToWpslProgressModal.module.scss'

const PslToWpslProgresslModal: React.FC = observer(() => {
  const {
    conversionStatus,
    showToWpslProcess,
    conversionStatusMessage,
    hideToWpslProcess,
    conversionTimerId,
    addTokenToMetamask,
    hideErrorModal,
  } = web3Store

  return (
    <Modal
      isOpen={!!showToWpslProcess}
      contentLabel='Conversion Status'
      className={style.modal_content}
      overlayClassName={style.modal_overlay}
    >
      <p className='text-2xl'>{conversionStatus}</p>
      {conversionStatus !== CONVERSION_STATUS.complete ? (
        <p className='break-words pt-2' style={{ fontSize: 14 }}>
          {conversionStatusMessage && conversionStatusMessage.length
            ? HtmlReactParser(
                conversionStatusMessage
                  .replace('(', '<br>(')
                  .replace(')', ')</br>'),
              )
            : ''}
        </p>
      ) : null}
      {conversionTimerId ||
      conversionStatus === CONVERSION_STATUS.checkingFormStatus ? (
        <div className='p-8'>
          <Spinner />
        </div>
      ) : (
        <>
          {conversionStatus === CONVERSION_STATUS.complete ? (
            <>
              <p>
                Before you finish, make sure that you add wPSL to MetaMask by
                clicking{' '}
                <a
                  className='text-link cursor-pointer'
                  onClick={addTokenToMetamask}
                >
                  here
                </a>
                .
              </p>
              <Button
                width='250px'
                className='mt-10'
                onClick={() => hideToWpslProcess()}
              >
                OK
              </Button>
            </>
          ) : (
            <Button
              width='250px'
              className='mt-10'
              onClick={() => hideErrorModal()}
            >
              OK
            </Button>
          )}
        </>
      )}
    </Modal>
  )
})

export default PslToWpslProgresslModal
