import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'

import { ReactComponent as IconUnwrapping } from '../../assets/icons/ico-unwrapping.svg'
import { ReactComponent as IconWrapping } from '../../assets/icons/ico-wrapping.svg'
import Button from '../../common/components/Button/Button'
import ConversionLogModal from '../../components/ConversionLogModal'
import FirstConversionModal from '../../components/FirstConversionModal'
import PslToWpslModal from '../../components/PslToWpslModal'
import PslToWpslProgresslModal from '../../components/PslToWpslProgressModal'
import TransactionTable from '../../components/TransactionTable/TransactionTable'
import WpslToPslModal from '../../components/WpslToPslModal'
import WpslToPslProgressModal from '../../components/WpslToPslProgressModal'
import { ConversionType } from '../../constants/constants'
import transactionStore from '../../mobx/transactionStore'

const Dashboard: React.FC = observer(() => {
  const {
    openWpslToPslModal,
    fetchTransactions,
    transactions,
    openPslToWpslModal,
    transactionsFetching,
    openConversionLogModal,
    closeConversionLogModal,
    conversionLogs,
    conversionLogsFetching,
  } = transactionStore

  const [currentTab, setCurrentTab] = useState(
    localStorage.getItem('currentTab') || ConversionType.Wrapping,
  )

  useEffect(() => {
    fetchTransactions()
  }, [])

  const handleChangeTab = (type: string) => {
    setCurrentTab(type)
    localStorage.setItem('currentTab', type)
  }

  return (
    <div className='h-full bg-dashboard py-5 px-15'>
      <div className='container'>
        <WpslToPslModal />
        <PslToWpslModal />
        <PslToWpslProgresslModal />
        <WpslToPslProgressModal />
        <FirstConversionModal />
        <div className='rounded w-full mx-auto mt-4'>
          <ul id='tabs' className='inline-flex pt-2 w-full'>
            <li
              onClick={() => handleChangeTab(ConversionType.Wrapping)}
              className={
                currentTab === ConversionType.Wrapping
                  ? 'px-7 h-14 text-gray-800 font-semibold py-2 rounded-t-lg cursor-pointer bg-white border-t border-r -mb-px text-center'
                  : 'px-7 text-gray-800 h-14 font-semibold py-2 rounded-t-lg cursor-pointer'
              }
            >
              <a className='text-xl text-neutrals-200 font-extrabold no-underline flex items-center justify-between'>
                <IconWrapping />
                <span className='pl-4'>Wrapping</span>
              </a>
              {currentTab === ConversionType.Wrapping && (
                <span className='text-sm text-gray-500'>PSL ➞ wPSL</span>
              )}
            </li>
            <li
              onClick={() => handleChangeTab(ConversionType.Unwrapping)}
              className={
                currentTab === ConversionType.Unwrapping
                  ? 'px-7 h-14 text-gray-800 font-semibold py-2 rounded-t-lg cursor-pointer bg-white border-t border-r -mb-px text-center'
                  : 'px-7 h-14 text-gray-800 font-semibold py-2 rounded-t-lg cursor-pointer'
              }
            >
              <a className='text-xl text-neutrals-200 font-extrabold no-underline flex items-center justify-between'>
                <IconUnwrapping />
                <span className='pl-4'>Unwrapping</span>
              </a>
              {currentTab === ConversionType.Unwrapping && (
                <span className='text-sm text-gray-500'>wPSL ➞ PSL</span>
              )}
            </li>
            <div className='flex-grow text-right'>
              {currentTab === ConversionType.Wrapping ? (
                <Button width='260px' onClick={openPslToWpslModal}>
                  Convert PSL to Wrapped PSL
                </Button>
              ) : (
                <Button width='260px' onClick={openWpslToPslModal}>
                  Convert Wrapped PSL to PSL
                </Button>
              )}
            </div>
          </ul>

          <div className='overflow-hidden rounded-tl-none rounded-lg'>
            <TransactionTable
              transactions={transactions
                .filter(transaction => transaction.type === currentTab)
                .sort((a, b) => {
                  if (a.createdAt === null) {
                    return -1
                  }
                  if (b.createdAt === null) {
                    return -1
                  }
                  if (a.createdAt < b.createdAt) {
                    return 1
                  }
                  if (a.createdAt > b.createdAt) {
                    return -1
                  }
                  return 0
                })}
              transactionsFetching={transactionsFetching}
            />
          </div>
        </div>
      </div>
      <ConversionLogModal
        isOpen={openConversionLogModal}
        onClose={closeConversionLogModal}
        data={conversionLogs}
        isLoading={conversionLogsFetching}
      />
    </div>
  )
})

export default Dashboard
