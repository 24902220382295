import React from 'react'

import style from './Spinner.module.scss'

const Spinner = () => {
  return (
    <div className={style.lds_roller}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Spinner
