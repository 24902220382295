import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import { tempAuthLock } from '../../config'
import authStore from '../../mobx/authStore'
import UserList from '../../pages/admin/UserList'
import WhiteList from '../../pages/admin/WhiteList'
import Dashboard from '../../pages/dashboard'
import Faq from '../../pages/faq'
import Landing from '../../pages/landing'
import Tos from '../../pages/tos'
import AdminLogin from '../AdminLogin'
import { useIsAuthorized } from '../TempAuth/tempAuth.service'
import TempAuthPage from '../TempAuth/TempAuthPage'
import AdminRoute from './AdminRoute'
import PrivateRoute from './PrivateRoute'

export default observer(function Routes() {
  const { isLoggedIn, isAdmin, fetchUser, setAdminPage } = authStore

  const isAuthorized = useIsAuthorized()
  const location = useLocation()

  useEffect(() => {
    fetchUser()
      .then(() => {
        // noop
      })
      .catch(() => {
        // noop
      })
      .finally(() => {
        // noop
      })
  }, [isAuthorized])

  useEffect(() => {
    if (location.pathname.indexOf('admin') !== -1) {
      setAdminPage()
    } else {
      setAdminPage(false)
    }
  }, [])

  if (tempAuthLock && !isAuthorized) {
    return <TempAuthPage />
  }
  return (
    <Switch>
      <Route
        exact
        path='/'
        render={() => (isLoggedIn ? <Redirect to='dashboard' /> : <Landing />)}
      />
      <Route path='/faq'>
        <Faq />
      </Route>
      <Route path='/terms-of-service'>
        <Tos />
      </Route>
      <PrivateRoute path='/dashboard'>
        <Dashboard />
      </PrivateRoute>
      <Route
        path='/admin'
        exact
        render={() =>
          isAdmin ? <Redirect to='/admin/users' /> : <AdminLogin />
        }
      />
      <AdminRoute path='/admin/users'>
        <UserList />
      </AdminRoute>
      <AdminRoute path='/admin/whitelist'>
        <WhiteList />
      </AdminRoute>
    </Switch>
  )
})
