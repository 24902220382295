import { Checkbox, FormControlLabel, Modal } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { useState } from 'react'

import { tosData } from '../../assets/tos'
import authStore from '../../mobx/authStore'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1000,
      height: 'calc(100vh - 100px)',
      margin: '80px auto 0',
      backgroundColor: 'white',
      borderRadius: 3,
      boxShadow: theme.shadows[5],
    },
    body: {
      flex: 1,
      overflow: 'auto',
      padding: theme.spacing(4),
      boxShadow: '0 -3px 3px rgba(0,0,0,0.1) inset',
    },
    footer: {
      padding: theme.spacing(2),
      cursor: 'pointer',
    },
  }),
)

export const TosModal = observer(() => {
  const css = useStyles()
  const { toggleTosOpen, tosAgreeSubmit } = authStore
  const [tos, setTosCheck] = useState<boolean>(false)
  const [region, setRegionCheck] = useState<boolean>(false)
  const checked = tos && region

  return (
    <Modal open onClose={() => toggleTosOpen(false)}>
      <div className={css.paper}>
        <div className={css.body}>
          {tosData}
          <p className='text-center'>
            <strong>ELECTRONIC CONSENT</strong>
          </p>
          <p>Please view, print or save the documents linked below.</p>
          <p>
            By clicking "<b>I Agree</b>" and "<b>Continue</b>", I hereby:
          </p>
          <FormControlLabel
            control={
              <Checkbox
                checked={tos}
                onChange={e => setTosCheck(e.target.checked)}
              />
            }
            label={
              <span className='highlight'>
                I agree to Wrapped Pastel Terms of Service
              </span>
            }
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={region}
                onChange={e => setRegionCheck(e.target.checked)}
              />
            }
            label={
              <span className='highlight'>
                I confirm that I am not a resident or citizen of any of the
                following countries: US, Burma, Cuba, Democratic Republic of
                Congo, Iran, Iraq, Liberia, North Korea, Sudan, Syria, and
                Zimbabwe
              </span>
            }
          />
        </div>
        <div className={css.footer}>
          <button
            className={classNames('rounded-2xl   py-3 w-full text-white mt-4', {
              'text-gray-500': !checked,
              'bg-gray-300': !checked,
              'bg-blue-500': checked,
            })}
            disabled={!checked}
            onClick={tosAgreeSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  )
})
