import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Modal from 'react-modal'

import {
  CONVERSION_FEE,
  ETHAddressRegex,
  LSPAddressRegex,
  MAXIMUM_PSL_AMOUNT,
  MINIMUM_PSL_AMOUNT,
  MINIMUM_TEST_PSL_AMOUNT,
  PSLAddressRegex,
} from '../../../../common/constants'
import { ReactComponent as CloseIcon } from '../../assets/icons/ico-union.svg'
import Input from '../../common/components/Input/Input'
import InputNumberFormat from '../../common/components/Input/InputNumberFormat'
import { VALIDATION_MESSAGES } from '../../constants/constants'
import authStore from '../../mobx/authStore'
import transactionStore from '../../mobx/transactionStore'
import web3Store, { TTransferToPsl } from '../../mobx/web3Store'
import { formatNumber, parseFormattedNumber } from '../../utils/lib'
import style from './WpslToPslModal.module.scss'

const PslToWpslModal: React.FC = observer(() => {
  const {
    transferToPsl,
    isValidPSLConversionData,
    initiatePslConversion,
    singleEthAddress,
    pslTransaction,
    pslConversionTimerId,
  } = web3Store
  const {
    wpslToPslModal: open,
    closeWpslToPslModal,
    retryWpslToPsl,
  } = transactionStore
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<TTransferToPsl>()
  const { user } = authStore
  let amount = parseFormattedNumber(watch('amount', '0'))
  const [defaultAmount, setDefaultAmount] = useState('')
  const [checked, setChecked] = useState(false)
  const min = user?.firstPslConversionPassed
    ? MINIMUM_PSL_AMOUNT
    : MINIMUM_TEST_PSL_AMOUNT

  const onSubmit: SubmitHandler<TTransferToPsl> = async ({
    targetAddress,
    ethAddress,
  }: TTransferToPsl) => {
    if (!isValidPSLConversionData) {
      await initiatePslConversion(targetAddress, amount, ethAddress)
    } else {
      await transferToPsl()
    }
  }

  const handleConfirmCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.currentTarget.checked)
  }

  const setEthAddress = (address: string) => {
    setValue('zEthAddress', address)
  }

  useEffect(() => {
    setEthAddress(singleEthAddress)
  }, [singleEthAddress.length, isValidPSLConversionData])

  useEffect(() => {
    if (!open) {
      reset()
    }
  }, [open])

  useEffect(() => {
    if (retryWpslToPsl) {
      if (pslTransaction?.amount) {
        setDefaultAmount(formatNumber(pslTransaction.amount))
        setValue('amount', `${formatNumber(pslTransaction.amount)}`)
      }
      if (pslTransaction?.pslAddress) {
        setValue('targetAddress', `${pslTransaction.pslAddress}`)
      }
      if (pslTransaction?.targetAddress) {
        setValue('ethAddress', `${pslTransaction.targetAddress}`)
      }
      setChecked(true)
    } else if (!open && pslTransaction) {
      setDefaultAmount('')
      setValue('targetAddress', '')
      setValue('ethAddress', '')
      setChecked(false)
    }
  }, [retryWpslToPsl])

  return (
    <Modal
      isOpen={open}
      contentLabel='Wpsl To Psl Modal'
      className={style.modal_content}
      overlayClassName={style.modal_overlay}
    >
      <div className='py-10 px-15'>
        <button
          onClick={closeWpslToPslModal}
          className='w-7 h-7 rounded-lg border border-icon-default opacity-30 px-2.5 absolute right-6 top-10'
        >
          <CloseIcon />
        </button>
        <h2 className='text-gray-800 font-extrabold'>
          Convert Wrapped PSL to PSL
        </h2>
        <p className='text-neutrals-400 mt-2'>
          <b>Note:</b>
          <br />
          Users are responsible for supplying the required Ethereum gas for any
          wPSL to PSL conversion transactions.
          <br />
          For PSL to wPSL conversions, wrappedpastel.com will supply the
          required gas.
          <br />
        </p>
        <p className='text-neutrals-400 mt-2'>
          Minimum {formatNumber(min)} WPSL, maximum{' '}
          {formatNumber(MAXIMUM_PSL_AMOUNT)} WPSL
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputNumberFormat
            {...register('amount', {
              required: VALIDATION_MESSAGES.required,
              validate: {
                min: val =>
                  parseInt(val.replaceAll(',', '')) >= min ||
                  `Amount must be larger than ${formatNumber(
                    min,
                  )} and smaller than ${formatNumber(MAXIMUM_PSL_AMOUNT)}`,
                max: val =>
                  parseInt(val.replaceAll(',', '')) <= MAXIMUM_PSL_AMOUNT ||
                  `Amount must be larger than ${formatNumber(
                    min,
                  )} and smaller than ${formatNumber(MAXIMUM_PSL_AMOUNT)}`,
              },
            })}
            value={defaultAmount || amount}
            name='amount'
            type='text'
            readOnly={retryWpslToPsl}
            placeholder='Enter amount of wPSL to change'
            helperText={
              amount
                ? `Conversion fee (${CONVERSION_FEE * 100}%) of ${formatNumber(
                    amount,
                  )} wPSL. You will receive ${formatNumber(
                    amount - amount * CONVERSION_FEE,
                  )} PSL after fees`
                : `Conversion fee (${
                    CONVERSION_FEE * 100
                  }%) - no data to calculate yet`
            }
            error={errors.amount?.message}
          />

          <Input
            {...register('targetAddress', {
              required: VALIDATION_MESSAGES.required,
              pattern: {
                value: new RegExp(
                  `(${PSLAddressRegex.source})|(${LSPAddressRegex.source})`,
                  'i',
                ),
                message: 'Invalid PSL address',
              },
            })}
            readOnly={retryWpslToPsl}
            name='targetAddress'
            placeholder='Paste your PSL address'
            helperText='Pastel address where you want to receive PSL'
            error={errors.targetAddress?.message}
          />
          <Input
            {...register('ethAddress', {
              required: VALIDATION_MESSAGES.required,
              pattern: {
                value: new RegExp(`(${ETHAddressRegex.source})`, 'i'),
                message: 'Invalid ETH address',
              },
            })}
            readOnly={retryWpslToPsl}
            name='ethAddress'
            placeholder='Paste the ETH address you are sending the wPSL from'
            helperText='ETH address from which user is sending the wPSL'
            error={errors.ethAddress?.message}
          />
          {isValidPSLConversionData && (
            <Input
              {...register('zEthAddress', {
                required: VALIDATION_MESSAGES.required,
              })}
              readOnly
              name='zEthAddress'
              placeholder='ETH address'
              helperText='ETH address where you should send your wPSL to be converted'
              error={errors.zEthAddress?.message}
            />
          )}
          <div className='mt-8'>
            {pslConversionTimerId ? (
              <div className='callout callout-medium text-base'>
                Transaction status: in progress 2-5 minute (2 blocks) left
              </div>
            ) : (
              <div className='callout callout-medium text-base'>
                Please send the required wPSL to the address specified to begin
                the unwrapping process...
              </div>
            )}
            <input
              type='checkbox'
              checked={checked}
              onChange={handleConfirmCheck}
              id='account-confirm'
              className='w-4 h-4 checked:bg-blue-600 checked:border-transparent'
            />
            <label htmlFor='account-confirm' className='text-neutrals-400'>
              {' '}
              I agree that the{' '}
              <a href='https://wrappedpastel.com'>wrappedpastel.com</a> account
              I am using now is my own account, and that the identity documents
              used to verify my residency on IDAnalyzer Identity belong to me.
            </label>
            <button
              className={classNames(
                'rounded-2xl   py-3 w-full text-white mt-4 disabled:bg-gray-300 disabled:',
                {
                  'text-gray-500': !checked,
                  'bg-gray-300': !checked,
                  'bg-blue-500': checked,
                },
              )}
              disabled={!checked}
            >
              {!isValidPSLConversionData
                ? 'Validate'
                : retryWpslToPsl
                ? 'Convert Again'
                : 'Convert'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
})

export default PslToWpslModal
