import HtmlReactParser from 'html-react-parser'
import { observer } from 'mobx-react'
import React from 'react'
import Modal from 'react-modal'

import Button from '../../common/components/Button/Button'
import Spinner from '../../common/components/Spinner'
import { CONVERSION_STATUS } from '../../constants/constants'
import web3Store from '../../mobx/web3Store'
import style from './WpslToPslProgressModal.module.scss'

const WpslToPslProgressModal: React.FC = observer(() => {
  const {
    showToPslProcess,
    conversionStatus,
    conversionStatusMessage,
    hideToPslProcess,
    pslConversionTimerId,
  } = web3Store

  return (
    <Modal
      isOpen={showToPslProcess}
      contentLabel='Conversion Status'
      className={style.modal_content}
      overlayClassName={style.modal_overlay}
    >
      <div className='p-8'>
        <p className='text-2xl'>{conversionStatus}</p>
        {conversionStatus !== CONVERSION_STATUS.complete ? (
          <p className='break-words pt-2' style={{ fontSize: 14 }}>
            {conversionStatusMessage && conversionStatusMessage.length
              ? HtmlReactParser(
                  conversionStatusMessage
                    .replace('(', '<br>(')
                    .replace(')', ')</br>'),
                )
              : ''}
          </p>
        ) : null}
        {pslConversionTimerId ||
        conversionStatus === CONVERSION_STATUS.checkingFormStatus ? (
          <div className='pt-2'>
            <Spinner />
          </div>
        ) : (
          <>
            {conversionStatus === CONVERSION_STATUS.complete ? (
              <p>{conversionStatusMessage}</p>
            ) : null}
            <Button
              width='250px'
              className='mt-10'
              onClick={() => hideToPslProcess()}
            >
              OK
            </Button>
          </>
        )}
      </div>
    </Modal>
  )
})

export default WpslToPslProgressModal
