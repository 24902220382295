const parseOptionalInt = (value?: string) =>
  value ? parseInt(value) : undefined

export const PSLAddressRegex = /^Pt[a-z0-9]{33}$/i
export const ETHAddressRegex = /^0x[a-fA-F0-9]{40}$/
export const LSPAddressRegex = /^tP[a-z0-9]{33}$/i
export const EmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
export const tempAuthHeaderName = 'x-temp-auth-token'
export const MINIMUM_TEST_PSL_AMOUNT =
  parseOptionalInt(
    process.env.REACT_APP_MINIMUM_TEST_PSL_AMOUNT ||
      process.env.MINIMUM_TEST_PSL_AMOUNT,
  ) || 10000

export const MINIMUM_PSL_AMOUNT =
  parseOptionalInt(
    process.env.REACT_APP_MINIMUM_PSL_AMOUNT || process.env.MINIMUM_PSL_AMOUNT,
  ) || 500000

export const MAXIMUM_PSL_AMOUNT =
  parseOptionalInt(
    process.env.REACT_APP_MAXIMUM_PSL_AMOUNT || process.env.MAXIMUM_PSL_AMOUNT,
  ) || 1250000

export const CONVERSION_FEE = 0.02
// number of blocks to be confirmed for an incoming transaction
export const REQUIRED_BLOCKS = 2
export const WPSL_DECIMALS = 5
export const WPSL_SYMBOL = 'WPSL'
export const WPSL_IMAGE =
  'https://download.pastel.network/misc-assets/pastel-logo-400x400.jpg'

export const REFUND_REASON = {
  InsufficientBalance: 'insufficient balance',
  Amount: 'amount mismatch',
}
