import { observer } from 'mobx-react'
import React from 'react'
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login'
import { toast } from 'react-toastify'

import Button from '../../common/components/Button/Button'
import { googleClientId } from '../../config'
import authStore from '../../mobx/authStore'

const onGoogleLoginSuccess = async (
  res: GoogleLoginResponseOffline | GoogleLoginResponse,
) => {
  if ('tokenId' in res) {
    await authStore.adminLogin(res.tokenId, res.profileObj)
  }
}

const AdminLogin = observer(() => {
  return (
    <div className='container'>
      <GoogleLogin
        render={p => (
          <Button
            width='250px'
            className='mt-6'
            variant='transparent'
            onClick={p.onClick}
          >
            Login as administrator
          </Button>
        )}
        clientId={googleClientId}
        onSuccess={onGoogleLoginSuccess}
        onFailure={(err: any) => {
          toast(err.message)
        }}
        cookiePolicy='single_host_origin'
      />
    </div>
  )
})

export default AdminLogin
