import { useMutation } from 'react-query'

import { tempAuthLock } from '../../config'
import { setLocalStorageItem, useLocalStorage } from '../../utils/localStorage'
import noop from '../../utils/noop'
import { authorize } from './tempAuth.api'

export type TAuthorizeParams = { username: string; password: string }

const storageKey = 'tempAuthToken'

export const getAuthToken = () => localStorage.getItem(storageKey)

const useAuthToken = (): [string | null, (token: string | null) => void] => {
  const authToken = useLocalStorage(storageKey)

  if (!tempAuthLock) {
    return [null, noop]
  }

  return authToken
}

export const useIsAuthorized = () => {
  const [token] = useAuthToken()
  return Boolean(token)
}

export const useAuthorize = () => {
  const { mutate, error, isLoading } = useMutation(authorize, {
    onSuccess({ token }) {
      setLocalStorageItem(storageKey, token)
    },
  })

  return { authorize: mutate, error, isLoading }
}

export const resetToken = () => setLocalStorageItem(storageKey, null)
