export const tosData = (
  <>
    <p>
      This Terms of Service (this "Agreement") is a binding contract between
      you, an individual or entity user or authorized representative of such
      user ("you" or "User") and Wrapped Pastel Custody, LLC ("WrappedPastel")
      (also herein referred to as "we," "us" or "our"). This Agreement governs
      your use of any website that links to these terms, including{' '}
      <a
        href='https://wrappedpastel.com'
        target='_blank'
        rel='noopener noreferrer'
      >
        https://wrappedpastel.com
      </a>{' '}
      (and all related subdomains) and its mobile applications (collectively,
      our "Site") and the services we provide (the "Services") regarding Digital
      Assets ("Digital Assets") (sometimes called cryptocurrency) like Bitcoin,
      Ethereum, and Pastel Network coins ("PSL"). In addition to providing
      access to our Site, our Services include providing KYC/AML compliance
      tools, access to one or more hosted Digital Asset wallets to track,
      manage, and arrange the transfer of supported digital currencies and
      conversion services to arrange for the purchase and sale of Digital Assets
      in transactions. WrappedPastel is not a bank, broker-dealer, tax advisor,
      or investment adviser and does not offer these or any related services.
    </p>
    <p className='font-extrabold'>
      BY ACCESSING OR USING THE SITE OR OUR SERVICES, YOU AGREE THAT YOU HAVE
      READ, UNDERSTOOD AND AGREE TO BE BOUND BY THIS AGREEMENT. IF YOU DO NOT
      AGREE TO THIS AGREEMENT, THEN YOU MAY NOT ACCESS OR USE THE SITE OR OUR
      SERVICES.
    </p>
    <p>
      If you are accessing the Site or using our Services on behalf of a
      business or corporate entity (an "<b>Organization</b>"), then you hereby
      represent and warrant that you have the authority to bind that
      Organization and your acceptance of this Agreement will be treated as
      acceptance by the Organization. In that event, "<b>User</b>" and "
      <b>you</b>" as used in this Agreement will refer to the Organization.
    </p>
    <p>
      <u>Material Terms.</u> As provided in greater detail in this Agreement
      (and without limiting the express language of this Agreement), you
      acknowledge the following:
    </p>
    <div className='ml-3'>
      <ul className='list-disc'>
        <li>
          You consent to the collection, use and disclosure of information in
          accordance with, and to be bound by, the WrappedPastel Privacy Policy
          ("Privacy Policy");
        </li>
        <li className='font-extrabold'>
          THE SITE AND SERVICES ARE PROVIDED "<b>AS IS</b>" WITHOUT WARRANTIES
          OF ANY KIND;
        </li>
        <li className='font-extrabold'>
          WRAPPEDPASTEL’S LIABILITY TO YOU IN CONNECTION WITH THE SITE AND
          SERVICES IS LIMITED, AND YOU BEAR ALL RISKS ASSOCIATED WITH ANY
          PARTICIPATION ON THE SITE AND IN OUR SERVICES;
        </li>
        <li>
          We will resolve disputes arising under this Agreement through binding
          arbitration described further herein.
        </li>
        <li className='font-extrabold'>
          BY ACCEPTING THIS AGREEMENT, AS PROVIDED IN GREATER DETAIL IN SECTION
          9 OF THIS AGREEMENT, YOU AND WRAPPEDPASTEL ARE EACH WAIVING THE RIGHT
          TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
        </li>
      </ul>
    </div>
    <p className='font-extrabold mt-6'>1. General Terms and Conditions</p>
    <div className='ml-3'>
      <ul className='list-disc'>
        <li>
          <u>Description.</u> The Site: (i) provides Users with general
          information about our products and services; and (ii) provides a
          platform ("Platform") for issuers to conduct compliant token
          transactions, primarily for the conversion of PSL tokens into "wrapped
          pastel" ("wPSL"), vice versa. As used in this Agreement, "User" means
          all users of the Site, and the "WrappedPastel Parties" means
          WrappedPastel and all of its affiliated entities.
        </li>
        <li>
          <u>Disclaimers and Other Terms.</u> TO THE FULLEST EXTENT PERMITTED BY
          LAW, AND WITHOUT LIMITING ANY OTHER TERMS IN THIS AGREEMENT, THE
          FOLLOWING TERMS APPLY:
          <ul className='list-disc mt-2'>
            <li>
              <u>Limitations on Liability.</u> WRAPPEDPASTEL SHALL HAVE NO
              LIABILITY TO YOU IN CONNECTION WITH: (1) ANY OUTAGE OR
              UNAVAILABILITY OF THE SITE OR ANY PROBLEMS YOU MAY HAVE IN
              PARTICIPATING ON THE SITE OR IN OUR SERVICES; (2) ANY SECURITY
              BREACH AFFECTING ANY FEATURE, CONTENT OR SERVICES AVAILABLE ON THE
              SITE; AND/OR (3) THE PERFORMANCE OF ANY OFFERING OR OTHER SERVICE
              THAT YOU PARTICIPATE IN ON THE SITE. YOU BEAR ALL RISKS OF USING
              THE SITE, AND YOU SHOULD ONLY PARTICIPATE TO THE EXTENT YOU ARE
              WILLING AND ABLE TO LOSE ANY AMOUNT INVESTED.
            </li>
            <li>
              <u>No Guarantee.</u> WRAPPEDPASTEL MAKES NO REPRESENTATIONS
              REGARDING THE LIKELIHOOD OR PROBABILITY THAT YOUR PARTICIPATION ON
              THE SITE OR IN OUR SERVICES WILL ACHIEVE A PARTICULAR OUTCOME OR
              GOAL. PAST PERFORMANCE IS NOT A GUARANTEE OF FUTURE SUCCESS, AND
              VOLATILITY MEANS THAT PERFORMANCE IN ANY PERIOD MAY BE FAR
              DIFFERENT FROM THAT OF PREVIOUS PERIODS. YOU MAY LOSE ALL OR PART
              OF ANY PARTICIPATION ON THE SITE AND THROUGH OUR SERVICES. YOU
              FURTHER ACKNOWLEDGE AND AGREE THAT WRAPPEDPASTEL MAKES NO
              GUARANTEES OR OTHER COMMITMENTS ABOUT YOUR ABILITY TO ACCESS OR
              USE THE SITE OR SERVICE.
            </li>
            <li>
              <u>Information Submitted to Us; Identity Checks.</u>
              <ul className='list-disc mt-2'>
                <li>
                  YOU ARE SOLELY RESPONSIBLE FOR ENSURING THE ACCURACY AND
                  COMPLETENESS OF ALL INFORMATION AND MATERIALS THAT YOU PROVIDE
                  TO US IN CONNECTION WITH YOUR USE OF THE SITE OR SERVICES,
                  INCLUDING, WITHOUT LIMITATION, ALL INFORMATION AND MATERIAL
                  THAT YOU PROVIDE TO US IN CONNECTION WITH US CHECKING YOUR
                  BACKGROUND AS FURTHER DESCRIBED UNDER 1.b.iii.2 BELOW. YOU
                  HEREBY REPRESENT AND WARRANT THAT: (A) ALL SUCH INFORMATION
                  AND MATERIALS ARE TRUE, ACCURATE AND COMPLETE IN ALL RESPECTS,
                  COMPLY WITH ALL APPLICABLE LAWS, RULES AND REGULATIONS AND DO
                  NOT VIOLATE OR INFRINGE ANY THIRD-PARTY RIGHTS; AND (B) YOU
                  WILL IMMEDIATELY NOTIFY US ABOUT, AND CORRECT, ANY INACCURACY
                  IN ANY SUCH MATERIALS OR INFORMATION.
                </li>
                <li>
                  YOU ACKNOWLEDGE THAT WE WILL CHECK YOUR BACKGROUND AND
                  IDENTITY AS REQUIRED BY APPLICABLE LAWS IN CONNECTION WITH
                  CERTAIN USES OF THE SITE. YOU HEREBY AUTHORIZE US TO, DIRECTLY
                  OR THROUGH THIRD PARTIES, MAKE ANY INQUIRIES AND CONDUCT ANY
                  INVESTIGATION WE CONSIDER NECESSARY OR HELPFUL TO VERIFY YOUR
                  IDENTITY AND TO TAKE ANY ACTIONS WE DEEM NECESSARY OR HELPFUL
                  BASED ON THE RESULTS OF SUCH INQUIRIES AND INVESTIGATIONS. YOU
                  FURTHER AUTHORIZE ANY AND ALL THIRD PARTIES TO WHICH ANY SUCH
                  INQUIRIES OR INVESTIGATIONS MAY BE DIRECTED TO FULLY RESPOND
                  TO SUCH INQUIRIES OR INVESTIGATIONS. YOU ACKNOWLEDGE AND AGREE
                  THAT WE MAY, IN OUR SOLE DISCRETION, DENY YOU THE RIGHT TO USE
                  THE SITE. WRAPPEDPASTELWILL HAVE NO LIABILITY TO YOU FOR ANY
                  LIABILITY OR OTHER LOSSES ARISING FROM ANY INQUIRIES OR
                  INVESTIGATIONS ARISING UNDER THIS SECTION 1.e.ii.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <u>Wallets.</u> YOU ACKNOWLEDGE AND AGREE THAT: (1) WE HAVE NO CONTROL
          OVER ANY WALLET THAT YOU MAY USE IN CONNECTION WITH YOUR USE OF THE
          SITE, OTHER THAN THE OFFICIAL PASTEL NETWORK GRAPHICAL WALLET
          ("WALLET") OR THE WRAPPEDPASTEL.COM WEBSITE ("WEBSITE"); (2) ALL
          WALLETS ARE PROVIDED BY THIRD PARTIES THAT WE HAVE NO RIGHT OR ABILITY
          TO CONTROL; AND (3) ANY DISPUTE BETWEEN YOU AND ANY THIRD PARTY WALLET
          PROVIDER IS BETWEEN YOU AND THAT THIRD PARTY WALLET PROVIDER.
        </li>
        <li>
          <u>Participants.</u> PARTICIPANTS MUST UNDERSTAND THAT TOKENS AND
          CRYPTOCURRENCIES AVAILABLE ON THE SITE AND THROUGH OUR SERVICES ARE
          SUBJECT TO A HIGH LIKELIHOOD OF LOSS AND LONG PERIOD OF ILLIQUIDITY.
          IN ADDITION, IF YOU ARE USING THE SITE AND SERVICES AS A PARTICIPANT
          IN THE UNITED STATES, YOU MAY BE REQUIRED TO QUALIFY AS AN "ACCREDITED
          INVESTOR" AS DEFINED IN RULE 501 OF REGULATION D UNDER THE SECURITIES
          ACT OF 1933, AND BE SOPHISTICATED ENOUGH TO PROTECT YOUR OWN
          INTERESTS. WE MAY ASK YOU FOR INFORMATION NEEDED TO CONFIRM YOUR
          STATUS AS AN ACCREDITED INVESTOR, OR TO CONFIRM OTHER INFORMATION
          ABOUT YOUR STATUS PRIOR TO ALLOWING YOU TO PARTICIPATE THROUGH THE
          SITE OR ANYTIME THEREAFTER.
        </li>

        <li>
          <u>Additional Terms.</u> We may require you to agree to additional
          terms and/or policies from time-to-time in connection with your use of
          the Site or Services ("Additional Terms"). Such Additional Terms may
          include terms that govern your rights in connection with any
          participation in Sponsors offerings. Except as expressly stated
          otherwise in such Additional Terms, any Additional Terms are hereby
          incorporated into and subject to this Agreement, and this Agreement
          will control in the event of any conflict or inconsistency with the
          Additional Terms to the extent of the conflict or inconsistency.
        </li>
        <li>
          <u>Changes to this Agreement.</u> You understand and agree that
          WrappedPastel may amend or otherwise change this Agreement at any time
          and from time to time without prior notice. You may read a current,
          effective copy of this Agreement at any time by selecting the
          appropriate link on the Site. The revised Agreement will become
          effective at the time of posting, and your use of the Site or Services
          after such time will constitute your acceptance of the revised
          Agreement. If any change to this Agreement is not acceptable to you,
          then your sole remedy is to stop using the Site or Services.
          Notwithstanding the foregoing, no revisions to this Agreement will
          apply to any dispute between you and WrappedPastel that arose prior to
          the effective date of such revisions.
        </li>
        <li>
          <u>Consideration.</u> WrappedPastel currently provides you with access
          to the Site for free. In return for enjoying this free access, you
          acknowledge and agree that we may generate revenues, increase goodwill
          or otherwise increase the value of WrappedPastel from your use of the
          Site, and you will have no right to share in any such revenues,
          goodwill or value whatsoever. However, we may charge you fees for
          certain Services and other uses of the Site. If we do, then we will
          notify you of those fees before you have an obligation to pay them.
        </li>
        <li>
          <u>Jurisdictional Issues.</u> WrappedPastel makes no representation
          that materials on the Site or the Services are appropriate, lawful or
          available for use in any locations other than the United States of
          America. Those who choose to access or use the Site or Services from
          locations outside the United States of America do so on their own
          initiative and are responsible for compliance with local laws, if and
          to the extent local laws are applicable.
        </li>
        <li>
          <u>Eligibility.</u> NEITHER THE SITE NOR THE SERVICES ARE FOR PERSONS
          UNDER THE AGE OF 18 OR FOR ANY USERS PREVIOUSLY SUSPENDED OR BLOCKED
          FROM THE SITE BY WRAPPEDPASTEL. IF YOU ARE UNDER 18 YEARS OF AGE, THEN
          YOU MAY NOT USE OR ACCESS THE SITE AT ANY TIME OR IN ANY MANNER.
          Furthermore, by using the Site or Services, you affirm that you are at
          least 18 years of age and otherwise have the legal capacity to
          contract, and have not been previously suspended or blocked from the
          Site by WrappedPastel.
        </li>
        <li>
          <u>Mobile Services.</u> The Site will be accessible via a mobile
          phone, tablet or other wireless device (collectively, "Mobile
          Services"). Your mobile carrier's normal messaging, data and other
          rates and fees will apply to your use of the Mobile Services. In
          addition, downloading, installing or using certain Mobile Services may
          be prohibited or restricted by your mobile carrier, and not all Mobile
          Services may work with all carriers or devices. Therefore, you are
          solely responsible for checking with your mobile carrier to determine
          if the Mobile Services are available for your mobile device(s), what
          restrictions, if any, may be applicable to your use of the Mobile
          Services, and how much they will cost you. Nevertheless, all use of
          the Site and the related Mobile Services must be in accordance with
          this Agreement. WrappedPastel shall not be liable to any party for any
          loss or damage resulting in whole or in part from any malfunction in
          its Site or Service to you to the extent such malfunction arises from,
          or is in anyway connected to, your mobile carrier's service.
        </li>
      </ul>
    </div>
    <p className='font-extrabold mt-6'>2. Registration; Accounts</p>
    <div className='ml-3'>
      <ol className='list-disc'>
        <li>
          <u>Log-In Credentials.</u> While you may always browse public-facing
          portions of the Site without registering with us, in order to access
          the password-protected portion of the Site (the "Protected Platform"),
          you must register an account with us (an "Account") or meet other
          criteria as determined by a particular token issuer or other sponsor.
        </li>
        <li>
          <u>Account Security.</u> You are responsible for the security of your
          Account, and are fully responsible for all activities that occur
          through the use of your credentials. You agree to notify us
          immediately at&nbsp;
          <a href='mailto:support@pastel.network'>support@pastel.network</a> if
          you suspect or know of any unauthorized use of your log-in credentials
          or any other breach of security with respect to your Account. We will
          not be liable for any loss or damage arising from unauthorized use of
          your credentials. Separate log-in credentials may be required to
          access External Sites (defined in Section 7 below).
        </li>
        <li>
          <u>Revocation of Access.</u> We reserve the right to disallow, cancel,
          remove or reassign certain usernames and permalinks in appropriate
          circumstances, as determined by us in our sole discretion, and may,
          with or without prior notice, suspend or terminate your Account if
          activities occur on your Account which, in our sole discretion, would
          or might constitute a violation of this Agreement, cause damage to or
          impair the Services or Site, infringe or violate any third-party
          rights, damage or bring into disrepute the reputation of WrappedPastel
          or any of its affiliates, or violate any applicable laws or
          regulations. If messages sent to the email address you provide are
          returned as undeliverable, then we may terminate your Account
          immediately without notice to you and without any liability to you or
          any third party.
        </li>
      </ol>
    </div>
    <p className='font-extrabold mt-6'>3. Intellectual Property Rights</p>
    <div className='ml-3'>
      <ol className='list-disc'>
        <li>
          <u>License.</u> Subject to your complete and ongoing compliance with
          this Agreement, WrappedPastel hereby grants you a revocable,
          non-exclusive, non-transferable, non-sublicensable, royalty-free and
          worldwide right and license to access and use the Services and Site
          solely in strict compliance with the provisions of this Agreement and
          as permitted by the functionalities of the Services and Site available
          to you.
        </li>
        <li>
          <u>Content.</u> The content that WrappedPastel provides to you on the
          Services and Site, including, without limitation, any text, graphics,
          software, interactive features, information or other materials
          (collectively, the "<b>WrappedPastel Content</b>"), is protected by
          copyright or other intellectual property rights and owned by
          WrappedPastel or its licensors. Moreover, WrappedPastel or its
          licensors own all design rights, database and compilation rights and
          other intellectual property rights in and to the Services and Site, in
          each case whether registered or unregistered, and any related
          goodwill.
        </li>
        <li>
          <u>Marks.</u> The WrappedPastel trademarks, service marks and logos
          (collectively, the "<b>WrappedPastel Trademarks</b>") used and
          displayed on the Site are the registered and/or unregistered
          trademarks or service marks of WrappedPastel or its affiliates. Any
          other product and service names located on any part of the Services
          and Site may be trademarks or service marks owned by third parties
          (collectively with the WrappedPastel Trademarks, the "
          <b>Trademarks</b>"). Except as otherwise permitted by law, you may not
          use the Trademarks to disparage WrappedPastel, any of its affiliates,
          any third party or any product or service offered by WrappedPastel or
          any third party, or in any manner (using commercially reasonable
          judgment) that may damage any goodwill in the Trademarks. You may not
          use any Trademarks as part of a link to or from any website without
          WrappedPastel’s prior express written consent. All goodwill generated
          from the use of any WrappedPastel Trademark will inure solely to
          WrappedPastel’s benefit.
        </li>
        <li>
          <u>Restrictions.</u> WrappedPastel hereby reserves all rights not
          expressly granted to you in this Section 3. Accordingly, nothing in
          this Agreement or on the Site will be construed as granting to you, by
          implication, estoppel or otherwise, any additional license rights in
          and to the Site, Service, or any WrappedPastel Content or Trademarks
          located or displayed on or within the Site or Service.
        </li>
      </ol>
    </div>
    <p className='font-extrabold mt-6'>4. Content</p>
    <div className='ml-3'>
      <ol className='list-disc'>
        <li>
          <u>General.</u> Certain features on the Site may allow you to submit
          or upload (collectively, "<b>Submit</b>") content to the Site or you
          may otherwise directly or indirectly provide content to WrappedPastel,
          such as User profile content or other materials subject to
          intellectual property or similar laws ("<b>User Content</b>"). For all
          User Content that you Submit, you hereby grant us (and those third
          parties we work with) a non-exclusive, perpetual, irrevocable,
          transferable, sublicensable (through multiple tiers), worldwide
          license and royalty-free right to use, exploit, host, store, transmit,
          reproduce, modify, create derivative works of (such as those resulting
          from changes we make so that your User Content works better with our
          Site), publish, publicly perform and display and distribute such
          content; provided that we will not share with other Users any User
          Content that you Submit that is not viewable by other Users based on
          any privacy settings available on the Site. The rights you grant in
          this section are for the purpose of operating, promoting, and
          improving our Site and Service and this license continues even if you
          stop using our Site.
        </li>
        <li>
          <u>You Must Have Rights to the Content You Submit.</u> You represent
          and warrant that: (i) you own the User Content Submitted by you or
          otherwise have the right to grant the license set forth in this
          Agreement; (ii) the Submission of your User Content and the use of the
          same as contemplated in this Agreement does not and will not violate
          any right of any third party; (iii) the Submission of your User
          Content will not require us to pay any amounts or provide any
          attribution to any third parties; and (iv) the Submission of your User
          Content does not result in a breach of contract between you and a
          third party.
        </li>
        <li>
          <u>Disclaimer.</u> We are under no obligation to edit or control User
          Content that you Submit, and will not be in any way responsible or
          liable for User Content. At any time and without notice, WrappedPastel
          may, and WrappedPastel may cause a third-party vendor to, screen,
          remove, edit or block any User Content that in our sole judgment
          violates this Agreement or is otherwise objectionable, such as,
          without limitation, User Content that WrappedPastel determines is or
          could be interpreted to be abusive, bigoted, defamatory, harassing,
          harmful, infringing, obscene, offensive, pornographic, racist,
          threatening, unlawful, vulgar or otherwise inappropriate
          (collectively, "<b>Objectionable Content</b>"). Further, we may, in
          our sole discretion, take any action we deem necessary and/or
          appropriate against any User who Submits Objectionable Content,
          including, but not limited to, warning the User or suspending or
          terminating the User’s Account.
        </li>
      </ol>
    </div>
    <p className='font-extrabold mt-6'>
      5. Notice and Procedure for Making Claims of Intellectual Property
      Infringements
    </p>
    <div className='ml-3'>
      <ol className='list-disc'>
        <li>
          <u>Respect of Third Party Rights.</u> We comply with the provisions of
          the Digital Millennium Copyright Act applicable to Internet service
          providers (17 U.S.C. § 512, as amended). If you have an intellectual
          property rights-related complaint about material appearing on the
          Site, then you may contact our designated agent at the following
          address:
        </li>
        <li className='list-none'>
          <div className='ml-5 mb-2'>
            <span className='font-extrabold'>WrappedPastel</span>
            <br />
            <span>1 Deer Park Court</span>
            <br />
            <span>Greenwich, Connecticut 06830</span>
            <br />
            <span>Attention: Legal Department (Copyright Notification)</span>
          </div>
          <div className='ml-5 mb-2'>
            <span className='font-extrabold'>WrappedPastel</span>
            <br />
            <span>
              Email:{' '}
              <a href='mailto:support@pastel.network'>support@pastel.network</a>{' '}
            </span>
            <br />
            <span>Greenwich, Connecticut 06830</span>
            <br />
            <span>Attention: Legal Department (Copyright Notification)</span>
          </div>
        </li>
        <li>
          Any notice alleging that materials hosted by or distributed through
          the Site infringe intellectual property rights ("Notification of
          Claimed Infringement") must include the following information:
          <ul className='sub-ul mt-2'>
            <li>
              an electronic or physical signature of the person authorized to
              act on behalf of the owner of the copyright or other right being
              infringed;
            </li>
            <li>
              a description of the copyrighted work or other intellectual
              property that you claim has been infringed;
            </li>
            <li>
              a description of the material that you claim is infringing and
              where it is located on the Site;
            </li>
            <li>your address, telephone number and email address;</li>
            <li>
              a statement by you that you have a good faith belief that the use
              of the materials on the Site of which you are complaining is not
              authorized by the copyright owner, its agent or the law; and
            </li>
            <li>
              a statement by you that the above information in your notice is
              accurate and that, under penalty of perjury, you are the copyright
              or intellectual property owner or authorized to act on the
              copyright or intellectual property owner's behalf.
            </li>
          </ul>
        </li>

        <li>
          <u>Repeat Infringer Policy.</u> WrappedPastel’s intellectual property
          policy is to: (i) remove or disable access to material that
          WrappedPastel believes in good faith, upon notice from an intellectual
          property owner or his or her agent, is infringing the intellectual
          property of a third party by being made available through the Site;
          (ii) remove any User Content Submitted by Users who are determined to
          be "<b>repeat infringers</b>"; and (iii) promptly terminate the
          Accounts of repeat infringers. WrappedPastel currently considers a "
          <b>repeat infringer</b>" to be any User that has Submitted User
          Content and for whom WrappedPastel has received more than two takedown
          notices compliant with the provisions of 17 U.S.C. § 512 with respect
          to such User Content. WrappedPastel has discretion, however, to
          terminate the Account of any User after receipt of a single
          Notification of Claimed Infringement (as defined in Section 5(b)) or
          upon WrappedPastel’s own determination.
        </li>
      </ol>
    </div>
    <p className='font-extrabold mt-6'>6. Restrictions on Use of the Site</p>
    <div className='ml-3'>
      <ol className='list-disc'>
        <li>
          Without limiting any other terms of this Agreement, you agree not to
          (and not to attempt to):
          <ul>
            <li>
              decipher, decompile, disassemble or reverse engineer any of the
              software or source code comprising or making up the Site;
            </li>
            <li>
              use any device, software or routine to interfere or attempt to
              interfere with the proper working of the Services or Site, or any
              activity conducted thereon;
            </li>
            <li>
              delete or alter any material WrappedPastel makes available on the
              Site;
            </li>
            <li>
              frame or link to any of the materials or information available on
              the Site;
            </li>
            <li>
              use or exploit any Trademarks or WrappedPastel Content in any
              manner that is not expressly authorized by this Agreement;
            </li>
            <li>
              access, tamper with or use non-public areas of the Site,
              WrappedPastel's (and its hosting company's) computer systems and
              infrastructure or the technical delivery systems of
              WrappedPastel's providers;
            </li>
            <li>provide any false information to WrappedPastel;</li>
            <li>
              create a false identity or impersonate another person or entity in
              any way;
            </li>
            <li>
              restrict, discourage or inhibit any person from using the Site;
            </li>
            <li>
              use the Site, without WrappedPastel's prior express written
              consent, for any unauthorized purpose;
            </li>
            <li>
              gain unauthorized access to the Site, other Users' Accounts or to
              other computers or websites connected or linked to the Site or
              Service;
            </li>
            <li>
              transmit to the Site any virus, worm, spyware, malware,
              ransomware, or any other computer code, file or program that may
              or is intended to disable, overburden, impair, damage or hijack
              the operation of any hardware, software or telecommunications
              equipment, or any other aspect of the Site or Services or
              communications equipment and computers connected thereto;
            </li>
            <li>
              violate any federal, state or local laws or regulations or the
              terms of this Agreement; or
            </li>
            <li>
              assist or permit any person in engaging in any of the activities
              described above.
            </li>
          </ul>
        </li>

        <li>
          It is WrappedPastel’s policy to comply with all facially valid
          subpoenas, court orders or binding orders issued to it by law
          enforcement agencies and other government authorities. This may affect
          your access to your account, your funds and our services to you. We
          are not responsible for any losses, whether direct or indirect, that
          you may incur as a result of our compliance with applicable law and
          regulations, the guidance or direction of any regulatory authority or
          government agency, or any writ of attachment, lien, levy, subpoena,
          warrant, or other legal order.
        </li>
      </ol>
    </div>
    <p className='font-extrabold mt-6'>7. External Sites</p>
    <p className='ml-3'>
      The Site may contain links to other websites or other online properties
      that are not owned or controlled by WrappedPastel (collectively, "
      <b>External Sites</b>"). WrappedPastel does not make any representations
      regarding the content, function or accuracy of any materials on External
      Sites. You should contact the site administrator or webmaster for External
      Sites if you have any concerns regarding content located on those External
      Sites. You should take precautions when downloading files from all
      websites to protect your devices from viruses and other destructive
      programs. If you decide to access any External Sites, then you do so at
      your own risk. Further, you will be solely responsible for compliance with
      any terms of service or similar terms imposed by any External Site in
      connection with your use of External Sites.
    </p>
    <p className='font-extrabold mt-6'>8. Feedback</p>
    <p className='ml-3'>
      If you choose to provide us with input or suggestions regarding problems
      with or proposed modifications or improvements to the Site or Services ("
      <b>Feedback</b>"), then you hereby grant to us a non-exclusive, perpetual,
      irrevocable, transferable, sublicensable (through multiple tiers),
      worldwide and royalty-free right to use and exploit the Feedback in any
      manner and for any purpose without any restriction, credit, attribution or
      fees due to you.
    </p>
    <p className='font-extrabold mt-6'>9. Dispute Resolution</p>
    <div className='ml-3'>
      <ol className='list-disc'>
        <li>
          <u>General.</u> In the interest of resolving disputes between you and
          WrappedPastel in the most expedient and cost-effective manner, you and
          WrappedPastel agree that any dispute arising out of or in any way
          related to this Agreement or your use of the Site or Services will be
          resolved by binding arbitration. Arbitration is less formal than a
          lawsuit in court. Arbitration uses a neutral arbitrator instead of a
          judge or jury, may allow for more limited discovery than in court, and
          can be subject to very limited review by courts. Arbitrators can award
          the same damages and relief that a court can award. This agreement to
          arbitrate disputes includes all claims arising out of or in any way
          related to this Agreement or your use of the Site or Services, whether
          based in contract, tort, statute, fraud, misrepresentation or any
          other legal theory, and regardless of whether a claim arises during or
          after any subsequent updates to or after the termination of this
          Agreement. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THIS
          AGREEMENT, YOU AND WRAPPEDPASTEL ARE EACH WAIVING THE RIGHT TO A TRIAL
          BY JURY OR TO PARTICIPATE IN A CLASS ACTION AND THAT THIS AGREEMENT
          SHALL BE SUBJECT TO AND GOVERNED BY THE FEDERAL ARBITRATION ACT.
        </li>
        <li>
          <u>Exceptions.</u> Notwithstanding Section 9(a) above, nothing in this
          Agreement will be deemed to waive, preclude or otherwise limit the
          right of either party to: (i) bring an individual action in small
          claims court; (ii) pursue an enforcement action through the applicable
          federal, state or local agency if that action is available; (iii) seek
          injunctive relief in aid of arbitration from a court of competent
          jurisdiction; or (iv) file suit in a court of law to address an
          intellectual property infringement claim.
        </li>
        <li>
          <u>Arbitrator.</u> Any arbitration between you and WrappedPastel will
          be governed by the Federal Arbitration Act and be finally settled in
          binding arbitration, on an individual basis, in accordance with the
          JAMS rules for arbitration of consumer-related disputes (accessible at{' '}
          <a
            href='https://www.jamsadr.com/rules-comprehensive-arbitration/'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://www.jamsadr.com/rules-comprehensive-arbitration/
          </a>
          ) and you and WrappedPastel hereby expressly waive the right to a
          trial by jury and to participate in a class action lawsuit or
          class-wide arbitration. The arbitrator has exclusive authority to
          resolve any dispute relating to the interpretation, applicability or
          enforceability of this binding arbitration agreement and the
          Agreement.
        </li>
        <li>
          <u>Notice; Process.</u> A party who intends to seek arbitration must
          first send a written notice of the dispute to the other party by U.S.
          Mail ("<b>Notice</b>"). WrappedPastel’s address for Notice is:
          <div className='ml-5 mb-2 mt-3'>
            <span className='font-extrabold'>WrappedPastel</span>
            <br />
            <span>1 Deer Park Court</span>
            <br />
            <span>Greenwich, Connecticut 06830</span>
            <br />
            <span>Attention: Legal Department</span>
          </div>
          <div className='ml-5 mb-3'>
            <span className='font-extrabold'>WrappedPastel</span>
            <br />
            <span>
              Email:{' '}
              <a href='mailto:support@pastel.network'>support@pastel.network</a>{' '}
            </span>
            <br />
            <span>Greenwich, Connecticut 06830</span>
            <br />
            <span>Attention: Legal Department</span>
          </div>
          The Notice must: (i) describe the nature and basis of the claim or
          dispute; and (ii) set forth the specific relief sought ("<b>Demand</b>
          ). The parties agree to make good faith efforts to resolve the claim
          directly, but if the parties do not reach an agreement to do so within
          30 days after the Notice is received, then you or WrappedPastel may
          commence an arbitration proceeding. During the arbitration, the amount
          of any settlement offer made by you or WrappedPastel must not be
          disclosed to the arbitrator until after the arbitrator makes a final
          decision and award, if any. If the dispute is finally resolved through
          arbitration in your favor with a monetary award that exceeds the last
          written settlement amount offered by WrappedPastel prior to selection
          of an arbitrator, then WrappedPastel will pay you the highest of the
          following: (1) the amount awarded by the arbitrator, if any; (2) the
          last written settlement amount offered by WrappedPastel in settlement
          of the dispute prior to the arbitrator’s award; or (3) $15,000.
        </li>
        <li>
          <u>Fees.</u> If you commence arbitration in accordance with this
          Agreement, then WrappedPastel will reimburse you for your payment of
          the filing fee, unless your claim is for more than $15,000 or as set
          forth below, in which case the payment of any fees will be decided by
          the JAMS Rules. Any arbitration hearing will take place at a location
          to be agreed upon in New York, New York, but if the claim is for
          $15,000 or less, then you may choose whether the arbitration will be
          conducted: (i) solely on the basis of documents submitted to the
          arbitrator; or (ii) through a non-appearance based telephone hearing.
          If the arbitrator finds that either the substance of your claim or the
          relief sought in the Demand is frivolous or brought for an improper
          purpose (as measured by the standards set forth in Federal Rule of
          Civil Procedure 11(b)), then the payment of all fees will be governed
          by the JAMS Rules. In that case, you agree to reimburse WrappedPastel
          for all monies previously disbursed by it that are otherwise your
          obligation to pay under the JAMS Rules. Regardless of the manner in
          which the arbitration is conducted, the arbitrator must issue a
          reasoned written decision sufficient to explain the essential findings
          and conclusions on which the decision and award, if any, are based.
          Each party agrees that such written decision, and information
          exchanged during arbitration, will be kept confidential except to the
          extent necessary to enforce or permit limited judicial review of the
          award. The arbitrator may make rulings and resolve disputes as to the
          payment and reimbursement of fees or expenses at any time during the
          proceeding and upon request from either party made within 14 days of
          the arbitrator’s ruling on the merits. Notwithstanding anything in
          this Agreement to the contrary, and for the avoidance of doubt, the
          arbitrator can award injunctive relief as a remedy in any arbitration
          required under these dispute resolution provisions.
        </li>
        <li>
          <u>No Class Actions.</u>
          <span className='font-extrabold'>
            YOU AND WRAPPEDPASTEL AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
            OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF
            OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
            Further, unless both you and WrappedPastel agree otherwise, the
            arbitrator may not consolidate more than one person’s claims or
            otherwise preside over any form of a representative or class
            proceeding.
          </span>
        </li>
        <li>
          <u>Modifications to this Arbitration Provision.</u> Except as
          otherwise provided in this Agreement, if WrappedPastel makes any
          future change to this arbitration provision, other than a change to
          WrappedPastel’s address for Notice, then you may reject the change by
          sending us written notice within 30 days of the change to
          WrappedPastel’s address for Notice, in which case this arbitration
          provision, as in effect immediately prior to the changes you rejected,
          will continue to govern any disputes between you and WrappedPastel.
        </li>
        <li>
          <u>Enforceability.</u> If Section 9(f) above is found to be
          unenforceable or if the entirety of this Section 9 is found to be
          unenforceable, then the entirety of this Section 9 will be null and
          void.
        </li>
      </ol>
    </div>
    <p className='font-extrabold mt-6'>
      10. Limitation of Liability and Disclaimer of Warranties
    </p>
    <div className='ml-3'>
      <p>
        THE TERMS OF THIS SECTION 10 APPLY TO THE FULLEST EXTENT PERMITTED BY
        LAW:
      </p>
      <ol className='list-disc'>
        <li>
          WRAPPEDPASTEL MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE SITE OR
          ANY CONTENT THEREON OR THE SERVICES. ACCORDINGLY, THE SITE AND ALL
          CONTENT THEREON AND THE SERVICES ARE PROVIDED ON AN "<b>AS IS</b>" AND
          "<b>AS AVAILABLE</b>" BASIS WITHOUT ANY WARRANTIES OF ANY KIND, AND
          WRAPPEDPASTEL HEREBY DISCLAIMS ALL WARRANTIES, INCLUDING, BUT NOT
          LIMITED TO, THE WARRANTIES OF TITLE, MERCHANTABILITY, NON-INFRINGEMENT
          OF THIRD PARTY RIGHTS AND FITNESS FOR A PARTICULAR PURPOSE.
        </li>
        <li>
          WITHOUT LIMITING SECTION 10(a), WRAPPEDPASTEL MAKES NO WARRANTY THAT
          THE SITE, SERVICES, OR ANY CONTENT THEREON ARE FREE OF ERRORS,
          COMPUTER VIRUSES OR SIMILAR CONTAMINATION OR DESTRUCTIVE FEATURES. IF
          YOUR USE OF THE SITE, SERVICES, OR ANY CONTENT THEREON RESULTS IN THE
          NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, THEN WRAPPEDPASTEL
          WILL NOT BE RESPONSIBLE FOR THOSE COSTS.
        </li>
        <li>
          IN NO EVENT WILL WRAPPEDPASTEL BE LIABLE FOR ANY SPECIAL, INDIRECT,
          PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, LOST PROFITS OR DAMAGES
          RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION RESULTING FROM, OR
          IN CONNECTION WITH, THE SITE, SERVICE, AND ANY CONTENT THEREON,
          WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR
          ANY OTHER LEGAL THEORY, EVEN IF WRAPPEDPASTEL HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. WRAPPEDPASTEL’S LIABILITY TO YOU OR ANY
          THIRD PARTIES IN ANY CIRCUMSTANCE ARISING FROM THIS AGREEMENT IS
          LIMITED TO U.S. $100.
        </li>
        <li>
          THERE MAY BE INADVERTENT TECHNICAL OR FACTUAL INACCURACIES AND
          TYPOGRAPHICAL ERRORS IN INFORMATION OR MATERIALS ON THE SITE OR
          SERVICES, AND WRAPPEDPASTEL MAKES NO WARRANTIES REGARDING THE
          ACCURACY, COMPLETENESS OR TIMELINESS OF SUCH INFORMATION OR MATERIALS.
          WRAPPEDPASTEL PROVIDES NO GUARANTEES AGAINST THE POSSIBILITY OF
          DELETION, MIS-DELIVERY OR FAILURE TO STORE COMMUNICATIONS,
          PERSONALIZED SETTINGS, OR OTHER DATA. WRAPPEDPASTEL HEREBY EXPRESSLY
          DISCLAIMS ALL LIABILITY FOR ERRORS OR OMISSIONS IN, OR THE MISUSE OR
          MISINTERPRETATION OF, ANY INFORMATION OR MATERIALS CONTAINED ON THE
          SITE OR SERVICES.
        </li>
        <li>
          NO INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
          WRAPPEDPASTEL OR OTHERWISE THROUGH THE SITE, WILL CREATE ANY WARRANTY
          NOT EXPRESSLY STATED IN THESE TERMS.
        </li>
      </ol>
    </div>
    <p className='font-extrabold mt-6'>11. Third Party Disputes</p>
    <p className='ml-3'>
      ANY DISPUTE YOU HAVE WITH ANY THIRD PARTY, INCLUDING OTHER USERS, IN
      CONNECTION WITH YOUR USE OF THE SITE OR SERVICES IS DIRECTLY BETWEEN YOU
      AND SUCH THIRD PARTY AND SHALL NOT INVOLVE WRAPPEDPASTEL OR ITS
      AFFILIATES. ACCORDINGLY, TO THE FULLEST EXTENT PERMITTED BY LAW, YOU
      HEREBY IRREVOCABLY RELEASE WRAPPEDPASTEL AND ITS AFFILIATES FROM ANY AND
      ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND
      AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH
      SUCH DISPUTES.
    </p>
    <p className='font-extrabold mt-6'>12. Indemnification</p>
    <p className='ml-3'>
      To the fullest extent permitted by law, you agree to defend, indemnify and
      hold harmless WrappedPastel and its affiliates from and against any
      claims, actions or demands, including, without limitation, reasonable
      legal and accounting fees, arising or resulting from: (a) your breach of
      this Agreement; or (b) your access to, use or misuse of the WrappedPastel
      Content, Trademarks or any part of the Site or Services; or (c) any false,
      inaccurate or misleading information you provide to WrappedPastel.
      WrappedPastel will provide notice to you of any such claim, suit or
      proceeding. WrappedPastel reserves the right, in its sole discretion, to
      assume the exclusive defense and control of any matter which is subject to
      indemnification under this Section 12 at your sole expense if
      WrappedPastel believes that you are unwilling or incapable of defending
      WrappedPastel’s interests. In such case, you agree to cooperate with any
      reasonable requests assisting WrappedPastel’s defense of such matter at
      your sole expense. Notwithstanding the foregoing, nothing contained in
      this Agreement shall constitute a waiver by any User of any of his, her or
      its legal rights under applicable U.S. federal laws or any other laws
      whose applicability is not permitted to be contractually waived.
    </p>
    <p className='font-extrabold mt-6'>
      13. Term and Termination of the Agreement
    </p>
    <div className='ml-3'>
      <ol className='list-disc'>
        <li>
          <u>Term.</u> As between you and WrappedPastel, the term of this
          Agreement commences on your first use of the Site or Services and
          continues until the termination of this Agreement by either you or
          WrappedPastel.
        </li>
        <li>
          <u>Suspension, Termination and Cancellation.</u> You may terminate
          this Agreement by sending written notification to us at&nbsp;
          <a href='mailto:support@pastel.network'>support@pastel.network</a> and
          terminating your use of the Site and Services. We reserve the right,
          in our sole discretion, to restrict, suspend, or terminate your access
          to all or any part of the Site or Service, or to terminate this
          Agreement at any time without prior notice or liability if you breach
          any provision of this Agreement or violate the rights of any third
          party on or through the Site. We reserve the right to change, suspend,
          or discontinue all or any part of the Site at any time without prior
          notice or liability.
        </li>
        <li>
          <u>Survival.</u> Sections 1(b), 1(c), 1(d), 1(e), 1(g), 1(h), 1(i),
          2(b), 2(c), 3(b), 3(c), 3(d), 4, 5, 6, 7, 8, 9, 10, 11, 12, 13(c), 14,
          15, 16 and all defined terms used therein will survive the termination
          of this Agreement indefinitely.
        </li>
      </ol>
    </div>
    <p className='font-extrabold mt-6'>
      14. Consent to Electronic Communications
    </p>
    <p className='ml-3'>
      By using the Site, you consent to receiving certain electronic
      communications from us as further described in the Privacy Policy.
    </p>
    <p className='font-extrabold mt-6'>15. Miscellaneous</p>
    <p className='ml-3'>
      This Agreement is governed by the internal substantive laws of the State
      of New York without respect to any conflict of laws rules or provisions
      that would compel the application of the substantive laws of another
      jurisdiction. You agree that no joint venture, partnership, employment or
      agency relationship exists between you and WrappedPastel or any of its
      affiliates as a result of this Agreement or your use of the Site or
      Services. Except as set forth in Section 9(h), if any provision of this
      Agreement is found to be invalid by any court or arbitrator having
      competent jurisdiction, then the invalidity of such provision will not
      affect the validity of the remaining provisions of this Agreement, which
      will remain in full force and effect. The failure of WrappedPastel to act
      on or enforce any provision of this Agreement will not be construed as a
      waiver of that provision or any other provision in this Agreement. No
      waiver will be effective against WrappedPastel unless made in a writing
      signed by an authorized representative of WrappedPastel, and no such
      waiver will be construed as a waiver in any other or subsequent instance.
      This Agreement constitutes the entire agreement between you and
      WrappedPastel with respect to the subject matter hereof, and supersedes
      all previous or contemporaneous agreements, whether written or oral,
      between you and WrappedPastel with respect to the subject matter hereof.
      The section headings in this Agreement are provided merely for convenience
      and will not be given any legal import. This Agreement will inure to the
      benefit of our successors and assigns. You may not assign this Agreement
      or any of the rights or licenses granted hereunder without the prior
      express written consent of WrappedPastel. "
      <b>
        <b>Assignment</b>
      </b>
      " as used in the prior sentence includes any changes of control or sale of
      all or substantially all of the stock or assets of any Organization.
      WrappedPastel may assign this Agreement, including all its rights
      hereunder, without restriction. You acknowledge and agree that you have
      had the opportunity to consult legal counsel in connection with this
      Agreement even if you chose not to do so, and this Agreement will not be
      construed against you or WrappedPastel as drafter.
    </p>

    <br />
    <h1 className='mb-4'>WrappedPastel User Agreement</h1>
    <p>
      This WrappedPastel User Agreement (this "
      <b>
        <b>Agreement</b>
      </b>
      ") is made by and between you (also referred to herein as "<b>User</b>")
      and Wrapped Pastel Custody, LLC ("<b>WrappedPastel</b>") (also herein
      referred to as "<b>we,</b>" "<b>us</b>" or "<b>our</b>"). This Agreement
      governs your use of the services provided by WrappedPastel (collectively,
      the "<b>WrappedPastel Services</b>" and each, individually, a "
      <b>WrappedPastel Service</b>"). By signing up to use an account (including
      any website, web widget, API, mobile applicable, or any other similar
      platform owned, controlled, operated or offered by WrappedPastel (the "
      <b>Site</b>"), you acknowledge and agree that you have read, understood
      and accepted all of the terms and conditions contained in this Agreement
      as well as our Privacy Policy.
    </p>
    <p>
      If you do not agree, you may not access or use the Services and must
      immediately cease any use of the Services. We may amend this Agreement
      from time to time by posting a revised version of this Agreement to the
      Site and may do so without advance notice to you. Accordingly, please
      review the Agreement posted at this location on a periodic basis. Each
      time you use the Services, you agree to be bound by the terms of the
      Agreement in effect at the time of your use thereof. If you do not agree
      to the revised terms, you are not permitted to use the Services.
    </p>
    <p>
      WrappedPastel can only provide services to residents of the following
      countries: The United States of America; United States Minor Outlying
      Islands; Armed Forces Americas; Armed Forces Pacific; Armed Forces Europe;
      Guam; Northern Mariana Islands; and certain foreign jurisdictions. If you
      are not a resident of one of the above jurisdictions, you may not use any
      WrappedPastel Services. Certain of the services described below may be in
      development or limited release and may not be available to any or all
      users of WrappedPastel at this time.
    </p>
    <p>PART 1: General use</p>
    <p className='font-extrabold'>1) Basic WrappedPastel Services.</p>
    <div className='ml-3'>
      <ol className='list-disc'>
        <li>
          <u>Eligibility.</u> To be eligible to use WrappedPastel Services, as
          defined below, you must be at least 18 years old. We request your age
          during the onboarding process. We also prohibit certain types of
          entities and individuals from using WrappedPastel Services, including,
          but not limited to, foreign financial institutions, foreign shell
          banks, private banking accounts, senior foreign political figures,
          FINRA employees and U.S. broker-dealer employees.
        </li>
        <li>
          <u>WrappedPastel Services.</u> Your WrappedPastel account ("
          <b>WrappedPastel Account</b>") encompasses the following basic
          WrappedPastel Services:
        </li>
        <li>
          <u>The ability to request to convert PSL into wPSL</u>. Once you
          complete verification, you will be able to add your conversion (or "
          <em>wrapping</em>") to the queue for processing, which is scheduled to
          occur once per week on Sunday in the EST time zone.
        </li>
        <li>
          <u>The ability to request to convert wPSL into PSL.</u> Once you
          complete verification, you will be able to add your conversion (or "
          <em>unwrapping</em>") to the queue for processing, which is scheduled
          to occur once per week on Sunday in the EST time zone.
        </li>
        <li className='font-extrabold'>
          <u>
            THE RISK OF LOSS IN TRADING OR HOLDING DIGITAL CURRENCY CAN BE
            SUBSTANTIAL.
          </u>{' '}
          IN THE EVENT OF WRAPPEDPASTEL’S INSOLVENCY, THE TREATMENT OF DIGITAL
          CURRENCIES HELD BY A HOSTED DIGITAL CURRENCY WALLET IS UNCERTAIN. YOU
          SHOULD CAREFULLY CONSIDER WHETHER TRADING OR HOLDING DIGITAL CURRENCY
          IS SUITABLE FOR YOU IN LIGHT OF YOUR FINANCIAL CONDITION.
        </li>
      </ol>
    </div>
    <p className='font-extrabold mt-4'>2) Creating a WrappedPastel Account.</p>
    <div className='ml-3'>
      <ol className='list-disc'>
        <li>
          <u>Registration of WrappedPastel Account.</u> In order to use the
          WrappedPastel Services, you will need to register for a WrappedPastel
          account (a "<b>WrappedPastel Account</b>"). During the registration
          process, we will ask you for information, including your name and
          other personal information, to verify your identity. We may, in our
          sole discretion, refuse to open a WrappedPastel Account for you, or
          limit the number of WrappedPastel Accounts that you may hold.
          Additionally, in order to use the WrappedPastel Services, you consent
          to accounts with Wrapped Pastel Custody, as applicable ("
          <b>Custodial Accounts</b>").
        </li>
        <li>
          <u>Identity Verification.</u> During registration of your
          WrappedPastel Account, you agree to provide us with the information we
          request for the purposes of identity verification and the detection of
          money laundering, terrorist financing, fraud, or any other financial
          crime and permit us to keep a record of such information. You will
          need to complete certain verification procedures before you are
          permitted to use the WrappedPastel Services. Your access to one or
          more WrappedPastel Services, and the limits that apply to your use of
          the WrappedPastel Services, may be altered as a result of information
          collected about you during the initial onboarding procedures and on an
          ongoing basis. The information we request may include certain personal
          information, including, but not limited to, your name, address,
          telephone number, email address, date of birth, taxpayer
          identification number, a government identification, and information
          regarding your bank account (such as the name of the bank, the account
          type, routing number, and account number). In providing us with this
          or any other information that may be required, you confirm that the
          information is true and accurate. You agree to promptly update your
          identity information upon any changes.
        </li>
        <li>
          You authorize us to make inquiries, whether directly or through third
          parties, that we consider necessary to verify your identity or to
          protect you and/or us against fraud or other financial crime, and to
          take action we reasonably deem necessary based on the results of such
          inquiries. When we carry out these inquiries, you acknowledge and
          agree that your personal information may be disclosed to credit
          reference and fraud prevention or financial crime agencies and that
          these agencies may respond to our inquiries in full. This is an
          identity check only and should have no adverse effect on your credit
          rating. These inquiries may include, but are not limited to,
          authorizing your wireless carrier to use your mobile number, name,
          address, email, network status, customer type, customer role, billing
          type, mobile device identifiers (IMSI and IMEI) and other subscriber
          status details, if available, solely to allow verification of your
          identity and to compare information you have provided to WrappedPastel
          with your wireless operator account profile information for the
          duration of the business relationship.
        </li>
        <li>
          Additionally, we may require you to wait some amount of time after
          completion of a transaction before permitting you to use certain
          WrappedPastel Services and/or before permitting you to engage in
          transactions beyond certain volume limits.
        </li>
      </ol>
    </div>
    <p className='font-extrabold mt-4'>3) Digital Currency Wallet(s).</p>
    <div className='ml-3'>
      <ol className='list-disc'>
        <li>
          <u>In General</u>Your hosted Digital Currency Wallet(s)enables you to
          monitor the storage, tracking and management of Digital Currencies
          contained in your Hosted Digital Currency Wallet. Through your account
          you will have the ability to request, send and receive(by placing
          orders), and store Digital Currency from third parties by giving
          instructions through the WrappedPastel Site(each such transaction is a
          "Digital Currency Transaction"). We may limit this functionality. We
          may require you to verify your identity prior to engaging in Digital
          Currency Transactions.
          <ul className='mt-2'>
            <li>
              Your WrappedPastel account allows access to one or more hosted
              digital currency wallets that allow you to track, manage and
              arrange the transfer of supported digital currencies, such as
              Bitcoin, Ethereum or Pastel coins ("<b>Digital Currencies</b>")
              (each wallet, a "<b>Hosted Digital Currency Wallet</b>"). Your
              WrappedPastel Account enables you to monitor the storage, tracking
              and management of Digital Currencies contained in your Hosted
              Digital Currency Wallet. Through your WrappedPastel Account you
              will have the ability to request, send and receive (by placing
              orders), and store Digital Currency from third parties by giving
              instructions through the Site (defined below) (each such
              transaction is a "<b>Digital Currency Transaction</b>"). We may
              limit this functionality. We may require you to verify your
              identity prior to engaging in Digital Currency Transactions.
            </li>
            <li>
              WrappedPastel reserves the right to refuse to process or to cancel
              any pending Digital Currency Transaction as required by law or in
              response to a subpoena, court order, or other binding government
              order or to enforce transaction limits. WrappedPastel may not be
              able to reverse a Digital Currency Transaction once it has been
              broadcast to a Digital Currency network. The Hosted Digital
              Currency Wallet services are available only in connection with
              those Digital Currencies that WrappedPastel and its affiliates, in
              their sole discretion, support. The Digital Currencies that
              WrappedPastel support may change from time to time, based on its
              sole and absolute discretion. WrappedPastel will notify you in
              advance if it ceases to support a particular Digital Currency. If
              you have any questions about which Digital Currencies
              WrappedPastel currently supports, please visit the "<b>Fees</b>"
              section of our website. Under no circumstances should you attempt
              to use your Hosted Digital Currency Wallet services to store,
              send, request, or receive digital currencies in any form that is
              not supported by WrappedPastel and its affiliates. WrappedPastel
              assumes no responsibility or liability in connection with any
              attempt to use WrappedPastel Services for digital currencies that
              WrappedPastel does not support.
            </li>
          </ul>
        </li>

        <li>
          <u>Digital Currency Transactions.</u> WrappedPastel processes
          supported Digital Currency according to the instructions received from
          its users and does not guarantee the identity of any user, receiver,
          requestee or other party. You should verify all transaction
          information prior to submitting instructions to WrappedPastel. In the
          event you initiate a Digital Currency Transaction by submitting an
          order, such order will irrevocably execute and be final unless such
          order is cancelled prior to execution. Any pending withdrawals from
          your WrappedPastel Account that have been submitted to a Digital
          Currency network will be unconfirmed for a period of time pending
          sufficient confirmation of the transaction by the Digital Currency
          network. Although a transaction is not complete while it is in a
          pending state, it may not be possible for WrappedPastel or its
          affiliates to undo, reverse, or otherwise alter the transaction. Funds
          associated with transactions that are in a pending state will be
          designated accordingly by WrappedPastel and its custodian partners, as
          appropriate, and will not be displayed in your WrappedPastel Account
          balance or be available to conduct other transactions. WrappedPastel
          and its custodian partners will charge a transaction fee for
          successfully executed orders. WrappedPastel or our custodian partners
          may charge network fees (miner fees) to process a Digital Currency
          withdrawal on your behalf. Network fees will vary between Digital
          Currencies and during periods of high network traffic. WrappedPastel
          will calculate the transaction fee according to its published fee
          schedule and will disclose such fee to you immediately prior to your
          confirmation of such order. WrappedPastel will always notify you of
          any fees at or before the time you authorize a transaction.
          WrappedPastel reserves the right to delay, cancel and/or report any
          Digital Currency Transaction if it perceives a risk of fraud or
          illegal activity and to report to and cooperate with law enforcement
          with respect to any potentially illegal activity.
        </li>
        <li>
          <u>Commingled Digital Currency Storage.</u> WrappedPastel may use
          shared Digital Currency wallets, controlled by WrappedPastel or our
          Digital Currency custodian(s), to hold Digital Currencies on behalf of
          users. WrappedPastel may commingle users’ Digital Currencies, but will
          not commingle WrappedPastel Market’s users’ Digital Currencies and its
          own Digital Currencies. WrappedPastel shall have no obligation to
          segregate any user’s Digital Currencies from any other user’s Digital
          Currency. WrappedPastel may rely upon an internal ledger to indicate
          the amount of each digital asset that is owned by each client.
        </li>
        <li>
          <u>Digital Currency Storage Transmission Delays.</u> Digital Currency
          is securely held in a combination of online and offline storage. As a
          result, it may be necessary for WrappedPastel to communicate with its
          Digital Currency custodian(s) to retrieve certain information from
          offline storage in order to facilitate a Digital Currency Transaction
          in accordance with your instructions, which may delay the initiation
          or crediting of such Digital Currency Transaction for 48 hours or
          more. You acknowledge and agree that a Digital Currency Transaction
          facilitated by WrappedPastel may be delayed.
        </li>
        <li>
          <u>Third Party Payments.</u> WrappedPastel has no control over, or
          liability for, the delivery, quality, safety, legality or any other
          aspect of any goods or services that you may purchase or sell to or
          from a third party (including other Users of WrappedPastel Services).
          WrappedPastel is not responsible for ensuring that a buyer or a seller
          you may transact with will actually complete the transaction or is
          authorized to do so. If you experience a problem with any goods or
          services purchased from, or sold to, a third party in connection with
          Digital Currency transferred using the WrappedPastel Services, or if
          you have a dispute with such third party, you must resolve the dispute
          directly with that third party.
        </li>
        <li>
          <u>Operation of Digital Currency Protocols.</u> WrappedPastel does not
          own or control the underlying software protocols which govern the
          operation of Digital Currencies supported on our platform. In general,
          the underlying protocols are open source, and anyone can use, copy,
          modify, and distribute them. By using WrappedPastel Services, you
          acknowledge and agree that (i) WrappedPastel is not responsible for
          operation of the underlying protocols and that WrappedPastel makes no
          guarantee of their functionality, security, or availability, and (ii)
          the underlying protocols are subject to sudden changes in operating
          rules (a/k/a "<b>forks</b>"), and that such forks may materially
          affect the value, function, and/or even the name of the Digital
          Currency you store in the WrappedPastel platform. In the event of a
          fork, you agree that WrappedPastel may temporarily suspend operations
          (with or without advance notice to you) and that WrappedPastel may, in
          its sole discretion, decide whether or not to support (or cease
          supporting) either branch of the forked protocol entirely. You
          acknowledge and agree that WrappedPastel assumes absolutely no
          responsibility whatsoever in respect of an unsupported branch of a
          forked protocol.
        </li>
      </ol>
    </div>
    <p className='font-extrabold mt-4'>4) WrappedPastel Services.</p>
    <div className='ml-3'>
      <ol className='list-disc'>
        <li>
          <u>Purchase Transactions.</u> After successfully completing the
          verification procedures, you may purchase and sell supported Digital
          Currency by funding your WrappedPastel Account. You authorize
          WrappedPastel to initiate debits from your WrappedPastel Account in
          settlement of purchase transactions. A Transaction Fee (defined below)
          applies to all conversion or wrapping/unwrapping transactions.
          Although WrappedPastel and our Digital Currency custodians will
          attempt to deliver supported Digital Currency to your WrappedPastel
          Account as promptly as possible, funds may be debited from your
          selected payment method before Digital Currency is delivered and
          viewable in your WrappedPastel Account.
        </li>
        <li>
          <u>Transaction Fees.</u>
          <ul className='pt-2'>
            <li>
              Generally, we receive compensation for our Services by charging
              fees based on a percentage of the transaction, currently set at 1%
              of the total amount. Each executed transaction is subject to a fee
              (a "<b>Transaction Fee</b>"). The applicable Transaction Fee is
              displayed to you on the Site or otherwise communicated to you
              prior to your completion of an order instruction. WrappedPastel
              may not process an order if the Transaction Fee and any other
              associated fees as may apply exceed the value of your transaction.
              WrappedPastel may waive some portion of the Transaction Fee
              depending on the payment method you select. The availability of
              each payment method depends on a number of factors, including, but
              not limited to, your location, the identification information you
              have provided to us, and limitations imposed by third-party
              payment processors. Taxes may apply, and additional fees may be
              assessed by our custodian partner and by recipients’ banks.
            </li>
            <li>
              You can view the schedule of currently applicable fees per pairing
              and payment method at our Fees page at wrappedpastel.com .
            </li>
            <li>
              WrappedPastel reserves the right to adjust or waive its
              Transaction Fees at any time. We will always notify you of the
              Transaction Fee which applies to your transaction when you
              authorize the transaction and in each receipt we issue to you.
            </li>
            <li>
              It is the policy of WrappedPastel LLC to not refund its fees for
              completed transactions unless otherwise agreed in writing or
              required by law.
            </li>
          </ul>
        </li>

        <li>
          <u>Reversals Cancellations.</u> You cannot cancel, reverse, or change
          any transaction marked as "<b>Complete,</b>" and you may not be able
          to cancel, reverse or change any transaction marked as "
          <b>Pending.</b>" If your payment is not successful or if your payment
          method has insufficient funds, you authorize WrappedPastel, in its
          sole discretion, to either to cancel the transaction or to debit your
          other payment methods in any amount necessary to complete the
          transaction. You are responsible for maintaining an adequate balance
          in order to avoid overdraft. WrappedPastel reserves the right to
          refuse to process, or to cancel or reverse, any instructions for the
          purchase or sale of Digital Currency in its sole discretion, even
          after funds have been debited from your account(s): (1) if
          WrappedPastel suspects the transaction involves (or has a high risk of
          involvement in) money laundering, terrorist financing, fraud, or any
          other type of financial crime; (2) in response to a subpoena, court
          order, or other government order; (3) if WrappedPastel reasonably
          suspects that the transaction is erroneous; or (4) if WrappedPastel
          suspects the transaction relates to Prohibited Use or a Prohibited
          Business as set forth below. In such instances, WrappedPastel will
          instruct our custodian partner to reverse the transaction and shall be
          under no obligation to allow you to reinstate a purchase or sale order
          at the same price or on the same terms as the cancelled transaction.
          Our custodian partner may have similar restrictions.
        </li>
      </ol>
    </div>
    <p className='font-extrabold mt-4'>
      5) General Use, Prohibited Use, and Termination.
    </p>
    <div className='ml-3'>
      <ol className='list-disc'>
        <li>
          <u>Limited License.</u> We grant you a limited, non-exclusive,
          non-transferable license, subject to the terms of this Agreement, to
          access and use any website that links to these terms, including
          https://wrappedpastel.com (and all related subdomains) and its mobile
          applications (collectively, our "<b>Site</b>") and related content,
          materials and information (collectively, the "<b>Content</b>") solely
          for approved purposes as permitted by WrappedPastel from time to time.
          Any other use of the WrappedPastel Site or Content is expressly
          prohibited and all other right, title, and interest in the
          WrappedPastel Site or Content is exclusively the property of
          WrappedPastel and its licensors. You agree you will not copy,
          transmit, distribute, sell, license, reverse engineer, modify,
          publish, or participate in the transfer or sale of, create derivative
          works from, or in any other way exploit any of the Content, in whole
          or in part. The domains "<b>wrappedpastel.com</b>", "
          <b>WrappedPastel</b>", "<b>WrappedPastel</b>" and all logos related to
          WrappedPastel or displayed on the Site are either trademarks or
          registered marks of WrappedPastel, its affiliates or its licensors.
          You may not copy, imitate or use them without our prior written
          consent.
        </li>
        <li>
          <u>Website Accuracy.</u> Although we intend to provide accurate and
          timely information on the WrappedPastel Site, the WrappedPastel Site
          (including, without limitation, the Content) may not always be
          entirely accurate, complete or current and may also include technical
          inaccuracies or typographical errors. In an effort to continue to
          provide you with as complete and accurate information as possible,
          information may be changed or updated from time to time without
          notice, including without limitation information regarding our
          policies, products and services. Accordingly, you should verify all
          information before relying on it, and all decisions based on
          information contained on the WrappedPastel Site are your sole
          responsibility and we shall have no liability for such decisions.
          Links to third-party materials (including without limitation websites)
          may be provided as a convenience but are not controlled by us. You
          acknowledge and agree that we are not responsible for any aspect of
          the information, content, or services contained in any third-party
          materials or on any third-party sites accessible or linked to the
          Site.
        </li>
        <li>
          <u>Third-Party Applications.</u> If, to the extent permitted by
          WrappedPastel from time to time, you grant express permission to a
          third party to access or connect to your WrappedPastel Account, either
          through the third party’s product or service or through the Site, you
          acknowledge that granting permission to a third party to take specific
          actions on your behalf does not relieve you of any of your
          responsibilities under this Agreement. You are fully responsible for
          all acts or omissions of any third party with access to your
          WrappedPastel Account. Further, you acknowledge and agree that you
          will not hold WrappedPastel responsible for, and will indemnify
          WrappedPastel from, any liability arising out of or related to any act
          or omission of any third party with access to your WrappedPastel
          Account. You may change or remove permissions granted by you to third
          parties with respect to your WrappedPastel Account at any time through
          the Account Settings page on the Site.
        </li>
        <li>
          <u>Prohibited Use.</u> In connection with your use of the
          WrappedPastel Services, and your interactions with other Users, and
          third parties, you agree and represent you will not engage in any
          Prohibited Business or Prohibited Use, as defined herein. We reserve
          the right at all times to monitor, review, retain and/or disclose any
          information as necessary to satisfy any applicable law, regulation,
          sanctions programs, legal process or governmental request. If we
          determine, in our sole discretion, that your WrappedPastel Account is
          associated with a Prohibited Use or a Prohibited Business, we reserve
          the right to cancel or suspend your WrappedPastel Account, block your
          transactions, freeze your funds, or take any other action we deem
          necessary or appropriate. We reserve the right to take any of these
          actions immediately, indefinitely, and without notice to you.
        </li>
        <li>
          <u>Transactions Limits.</u> The use of all WrappedPastel Services may
          be subject to a limit on the amount of volume, stated in U.S. Dollar
          terms, you may transact or transfer in a given period (e.g., daily).
          To view your limits, sign in to your WrappedPastel Account and check
          your profile page. Your transaction limits may vary depending on your
          payment method, verification steps you have completed, and other
          factors. WrappedPastel reserves the right to change applicable limits
          as we deem necessary in our sole discretion. If you wish to raise your
          limits beyond the posted amounts, you may submit a request at&nbsp;
          <a href='mailto:support@pastel.network'>support@pastel.network</a>. We
          may require you to submit additional information about yourself or
          your business, provide records, and arrange for meetings with
          WrappedPastel staff (such process, "<b>Enhanced Due Diligence</b>").
          WrappedPastel reserves the right to charge you costs and fees
          associated with Enhanced Due Diligence, provided that we notify you in
          advance of any such charges accruing. In our sole discretion, we may
          refuse to raise your limits or we may lower your limits at a
          subsequent time even if you have completed Enhanced Due Diligence.
        </li>
        <li>
          <u>Suspension, Termination, and Cancellation.</u> WrappedPastel may
          suspend, restrict, or terminate your access to any or all of the
          WrappedPastel Services, and/or deactivate or cancel your WrappedPastel
          Account, if: (i) we are so required by a facially valid subpoena,
          court order, or binding order of a government authority; (ii) we
          reasonably suspect you of using your WrappedPastel Account in
          connection with a Prohibited Use or Business; (iii) use of your
          WrappedPastel Account is subject to any pending litigation,
          investigation, or government proceeding and/or we perceive a
          heightened risk of legal or regulatory non-compliance associated with
          your WrappedPastel Account activity; (iv) our service partners are
          unable to support your use; or (v) you take any action that
          WrappedPastel deems as circumventing WrappedPastel’ controls,
          including, but not limited to, opening multiple WrappedPastel Accounts
          or abusing promotions which WrappedPastel may offer from time to time.
          If WrappedPastel suspends or closes your WrappedPastel Account, or
          terminates your use of WrappedPastel Services for any reason, we will
          provide you with notice of our actions unless a court order or other
          legal process prohibits WrappedPastel from providing you with such
          notice. You acknowledge that WrappedPastel’s decision to take certain
          actions, including limiting access to, suspending, or closing your
          WrappedPastel Account, may be based on confidential criteria that are
          essential to WrappedPastel’s risk management and security protocols.
          You agree that WrappedPastel is under no obligation to disclose the
          details of its risk management and security procedures to you. You
          will be permitted to transfer Digital Currency or funds associated
          with your Hosted Digital Currency Wallet(s) and/or your USD wallet(s)
          for 90 days after the deactivation or cancellation of your
          WrappedPastel Account unless such transfer is otherwise prohibited by
          law, including, but not limited to, applicable sanctions programs, or
          by a facially valid subpoena or court order. You will not be charged
          for canceling your WrappedPastel Account, although you will be
          required to pay any outstanding amounts owed to WrappedPastel or its
          affiliates and to our custodian partners. You authorize us to cancel
          or suspend any pending transactions at the time of cancellation.
        </li>
        <li>
          <u>Relationship of the Parties.</u> WrappedPastel is an independent
          contractor for all purposes. Nothing in this Agreement shall be deemed
          or is intended to be deemed, nor shall it cause, you and WrappedPastel
          to be treated as partners, joint ventures, or otherwise as joint
          associates for profit.
        </li>
        <li>
          <u>Privacy of Others; Marketing.</u> If you receive information about
          another User through the WrappedPastel Services, you must keep the
          information confidential and only use it in connection with the
          WrappedPastel Services. You may not disclose or distribute a User’s
          information to a third party or use the information except as
          reasonably necessary to effectuate a transaction and other functions
          reasonably incidental thereto, such as support, reconciliation and
          accounting, unless you receive the User’s express consent to do so.
          You may not send unsolicited email to a User through the WrappedPastel
          Services.
        </li>
        <li>
          <u>Password Security; Contact Information.</u> You are responsible for
          maintaining adequate security and control of all IDs, passwords,
          hints, personal identification numbers (PINs), API keys or any other
          codes that you use to access the WrappedPastel Services. You should
          only give such information to individuals who have the authority to
          take actions on behalf of any entity account holder and we assume that
          such individuals have such authority. WrappedPastel requires the use
          of Two Factor Identification for your account. Any loss or compromise
          of the foregoing information and/or your personal information may
          result in unauthorized access to your WrappedPastel Account by third
          parties and the loss or theft of any Digital Currency and/or funds
          held in your account with our custodian partner and any associated
          accounts, including your linked bank account(s) and credit card(s).
          You are responsible for keeping your email address and telephone
          number up to date in your WrappedPastel Account profile in order to
          receive any notices or alerts that we may send you. You are
          responsible for checking your balances and your transaction history.
          We assume no responsibility for any loss that you may sustain due to
          compromise of account login credentials due to no fault of
          WrappedPastel and/or your failure to follow or act on any notices or
          alerts that we may send to you. In the event you believe your
          WrappedPastel Account information has been compromised, contact
          WrappedPastel Support immediately at{' '}
          <a href='mailto:support@pastel.network'>support@pastel.network</a>
        </li>
        <li>
          <u>Taxes.</u> It is your sole responsibility to determine whether, and
          to what extent, any taxes apply to any transactions you conduct
          through the WrappedPastel Services, and to withhold, collect, report
          and remit the correct amounts of taxes to the appropriate tax
          authorities. Your transaction history is available through your
          WrappedPastel Account.
        </li>
        <li>
          <u>Unclaimed Property.</u> If you have funds in your WrappedPastel
          Account, and WrappedPastel is unable to contact you and has no record
          of your use of the Services for several years, applicable law may
          require WrappedPastel to report these funds as unclaimed property to
          the applicable jurisdiction. If this occurs, WrappedPastel will try to
          locate you at the address shown in our records, but if WrappedPastel
          is unable to locate you, it may be required to deliver any such funds
          to the applicable state or jurisdiction as unclaimed property.
          WrappedPastel reserves the right to deduct a dormancy fee or other
          administrative charges from such unclaimed funds, as permitted by
          applicable law.
        </li>
      </ol>
    </div>
    <p className='font-extrabold mt-4'>
      6) Customer Feedback, Queries, Complaints, and Dispute Resolution.
    </p>
    <div className='ml-3'>
      <ol className='list-disc'>
        <li>
          <u>Contact WrappedPastel.</u> If you have any feedback, questions, or
          complaints, write to us at WrappedPastel Customer Support,&nbsp;
          <a href='mailto:support@pastel.network'>support@pastel.network</a>.
          When you contact us, please provide us with your name, address, and
          any other information we may need to identify you, your WrappedPastel
          Account, and the transacton regarding which you have feedback,
          questions, or complaints.
        </li>
        <li>
          <u>
            Dispute Resolution by Binding Arbitration; Waiver of Class Action.
          </u>{' '}
          If you have a dispute with WrappedPastel, we will attempt to resolve
          any such disputes through our support team. If we cannot resolve the
          dispute through our support team, you and we agree that any dispute
          arising under this Agreement shall be finally settled in accordance
          with the dispute resolution provisions set forth in the WrappedPastel
          Terms of Service.
        </li>
      </ol>
    </div>
    <p className='font-extrabold mt-4'>7) General Provisions.</p>
    <div className='ml-3'>
      <ol className='list-disc'>
        <li>
          <u>Computer Viruses.</u> We shall not bear any liability, whatsoever,
          for any damage or interruptions caused by any computer viruses,
          spyware, scareware, Trojan horses, worms or other malware that may
          affect your computer or other equipment, or any phishing, spoofing or
          other attack. We advise the regular use of a reputable and readily
          available virus screening and prevention software. You should also be
          aware that SMS and email services are vulnerable to spoofing and
          phishing attacks and should use care in reviewing messages purporting
          to originate from WrappedPastel. Always log into your WrappedPastel
          Account through the WrappedPastel Site to review any transactions or
          required actions if you have any uncertainty regarding the
          authenticity of any communication or notice.
        </li>
        <li>
          <u>Release of WrappedPastel; Indemnification.</u> If you have a
          dispute with one or more Users of the WrappedPastel Services, you
          release WrappedPastel, its affiliates and service providers, and each
          of their respective officers, directors, agents, joint venturers,
          employees and representatives from any and all claims, demands and
          damages (actual and consequential) of every kind and nature arising
          out of or in any way connected with such disputes. You agree to
          indemnify and hold WrappedPastel, its affiliates and Service
          Providers, and each of its or their respective officers, directors,
          agents, joint venturers, employees and representatives, harmless from
          any claim or demand (including attorneys’ fees and any fines, fees or
          penalties imposed by any regulatory authority) arising out of or
          related to your breach of this Agreement or your violation of any law,
          rule or regulation, or the rights of any third party.
        </li>
        <li>
          <u>Limitation of Liability; No Warranty.</u>
          <ul className='mt-2'>
            <li>
              IN NO EVENT SHALL WRAPPEDPASTEL, ITS AFFILIATES AND SERVICE
              PROVIDERS, OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, AGENTS,
              JOINT VENTURERS, EMPLOYEES OR REPRESENTATIVES, BE LIABLE FOR
              (1) ANY AMOUNT GREATER THAN THE VALUE OF THE SUPPORTED DIGITAL
              CURRENCY REFLECTED IN YOUR WRAPPEDPASTEL ACCOUNT OR (2) ANY LOST
              PROFITS OR ANY SPECIAL, INCIDENTAL, INDIRECT, INTANGIBLE, OR
              CONSEQUENTIAL DAMAGES, WHETHER BASED IN CONTRACT, TORT,
              NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, ARISING OUT OF OR IN
              CONNECTION WITH AUTHORIZED OR UNAUTHORIZED USE OF THE
              WRAPPEDPASTEL SITE OR THE WRAPPEDPASTEL SERVICES, OR THIS
              AGREEMENT, EVEN IF AN AUTHORIZED REPRESENTATIVE OF WRAPPEDPASTEL
              HAS BEEN ADVISED OF OR KNEW OR SHOULD HAVE KNOWN OF THE
              POSSIBILITY OF SUCH DAMAGES. THIS MEANS, BY WAY OF EXAMPLE ONLY
              (AND WITHOUT LIMITING THE SCOPE OF THE PRECEDING SENTENCE), THAT
              IF YOU CLAIM THAT WRAPPEDPASTEL FAILED TO PROCESS A BUY OR SELL
              TRANSACTION PROPERLY, YOUR DAMAGES ARE LIMITED TO NO MORE THAN THE
              VALUE OF THE SUPPORTED DIGITAL CURRENCY AT ISSUE IN THE
              TRANSACTION, AND THAT YOU MAY NOT RECOVER FOR LOST PROFITS, LOST
              BUSINESS OPPORTUNITIES, OR OTHER TYPES OF SPECIAL, INCIDENTAL,
              INDIRECT, INTANGIBLE, OR CONSEQUENTIAL DAMAGES IN EXCESS OF THE
              VALUE OF THE SUPPORTED DIGITAL CURRENCY AT ISSUE IN THE
              TRANSACTION. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
              LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES SO THE ABOVE
              LIMITATION MAY NOT APPLY TO YOU.
            </li>
            <li>
              THE WRAPPEDPASTEL SERVICES ARE PROVIDED ON AN "<b>AS IS</b>" AND "
              <b>AS AVAILABLE</b>" BASIS WITHOUT ANY REPRESENTATION OR WARRANTY,
              WHETHER EXPRESS, IMPLIED OR STATUTORY. TO THE MAXIMUM EXTENT
              PERMITTED BY APPLICABLE LAW, WRAPPEDPASTEL SPECIFICALLY DISCLAIMS
              ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE AND/OR NON-INFRINGEMENT. WRAPPEDPASTEL DOES NOT
              MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO THE SITE,
              ANY PART OF THE WRAPPEDPASTEL SERVICES, OR ANY OF THE MATERIALS
              CONTAINED THEREIN, WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY, OR
              ERROR-FREE.
            </li>
            <li>
              WrappedPastel makes no representations about the accuracy or
              completeness of historical Digital Currency price data available
              on the Site. WrappedPastel will make reasonable efforts to ensure
              that requests for electronic debits and credits involving bank
              accounts, credit cards, and check issuances are processed in a
              timely manner, but WrappedPastel makes no representations or
              warranties regarding the amount of time needed to complete
              processing which is dependent upon many factors outside of our
              control.
            </li>

            <li>
              <u>Entire Agreement.</u> This Agreement, the Privacy Policy,
              onboarding representations, Subscriber Agreement and any
              Appendices incorporated by reference herein comprise the entire
              understanding and agreement between you and WrappedPastel as to
              the subject matter hereof, and supersedes any and all prior
              discussions, agreements and understandings of any kind (including
              without limitation any prior versions of this Agreement), and
              every nature between and among you and WrappedPastel. Section
              headings in this Agreement are for convenience only, and shall not
              govern the meaning or interpretation of any provision of this
              Agreement.
            </li>
            <li>
              <u>Amendments.</u> You may not assign any rights and/or licenses
              granted under this Agreement. We reserve the right to assign our
              rights without restriction, including, without limitation, to any
              WrappedPastel affiliates or subsidiaries, or to any successor in
              interest of any business associated with the WrappedPastel
              Services. Any attempted transfer or assignment in violation hereof
              shall be null and void. Subject to the foregoing, this Agreement
              will bind and inure to the benefit of the parties, their
              successors and permitted assigns.
            </li>
            <li>
              <u>Assignment.</u> You may not assign any rights and/or licenses
              granted under this Agreement. We reserve the right to assign our
              rights without restriction, including, without limitation, to any
              WrappedPastel affiliates or subsidiaries, or to any successor in
              interest of any business associated with the WrappedPastel
              Services. Any attempted transfer or assignment in violation hereof
              shall be null and void. Subject to the foregoing, this Agreement
              will bind and inure to the benefit of the parties, their
              successors and permitted assigns.
            </li>
            <li>
              <u>Severability.</u> If any provision of this Agreement shall be
              determined to be invalid or unenforceable under any rule, law or
              regulation or any governmental agency, local, state, or federal,
              such provision will be changed and interpreted to accomplish the
              objectives of the provision to the greatest extent possible under
              any applicable law and the validity or enforceability of any other
              provision of this Agreement shall not be affected.
            </li>
            <li>
              <u>Survival.</u> All provisions of this Agreement which by their
              nature extend beyond the expiration or termination of this
              Agreement, including, without limitation, sections pertaining to
              suspension or termination, WrappedPastel Account cancellation,
              debts owed to WrappedPastel, general use of the WrappedPastel
              Site, disputes with WrappedPastel, and general provisions, shall
              survive the termination or expiration of this Agreement.
            </li>
            <li>
              <u>Governing Law.</u> This Agreement and any claim or dispute that
              has arisen or may arise between you and WrappedPastel shall be
              governed by the laws of the State of New York, without regard to
              conflicts of laws rules or principles that would compel the
              application of the substantive laws of any other jurisdiction.
            </li>
            <li>
              <u>Force Majeure.</u> We shall not be liable for delays, failure
              in performance or interruption of Services which result directly
              or indirectly from any cause or condition beyond our reasonable
              control, including but not limited to, any delay or failure due to
              any act of God, act of civil or military authorities, act of
              terrorists, civil disturbance, war, strike or other labor dispute,
              fire, interruption in telecommunications or Internet services or
              network provider services, failure of equipment and/or software,
              other catastrophe or any other occurrence which is beyond our
              reasonable control and shall not affect the validity and
              enforceability of any remaining provisions.
            </li>
            <li>
              <u>Non-Waiver of Rights.</u> No waiver will be effective against
              WrappedPastel unless made in a writing signed by an authorized
              representative of WrappedPastel, and no such waiver will be
              construed as a waiver in any other or subsequent instance.
            </li>
            <li>
              <u>Incorporation by Reference.</u> The following appendices are
              expressly incorporated by reference in, and are hereby made a part
              of, this Agreement:
            </li>
          </ul>
        </li>
      </ol>
    </div>
    <p className='font-extrabold mt-12'>
      <a className='anchor' href='#appendix-1'>
        APPENDIX 1: WrappedPastel Trading Rules;
      </a>
    </p>
    <p className='font-extrabold'>
      <a className='anchor' href='#appendix-2'>
        APPENDIX 2: Prohibited Use, Prohibited Businesses and Conditional Use;
      </a>
    </p>
    <p className='font-extrabold'>
      <a className='anchor' href='#appendix-3'>
        APPENDIX 3: Verification Procedures and Limits; and
      </a>
    </p>
    <p className='font-extrabold'>
      <a className='anchor' href='#appendix-4'>
        APPENDIX 4: E-SIGN Disclosure and Consent.
      </a>
    </p>
    <br />
    <br />
    <p className='font-extrabold' id='appendix-1'>
      APPENDIX 1:
      <br />
      WRAPPEDPASTEL TRADING RULES# 1
    </p>
    <div className='ml-3'>
      <ol className='list-disc'>
        <li>
          <u>Request Minimums.</u> Requests placed on WrappedPastel may be
          subject to the following minimum and maximum request sizes:
          <ol className='list-disc mt-2'>
            <li>
              <u>Wrapping/Unwrapping Minimums and Maximums.</u> Request minimums
              for wrapping and unwrapping services for PSL and wPSL are as
              follows:
              <ol className='list-disc mt-2'>
                <li>
                  Wrapping Requests-request minimums are the greater of $1,000
                  and 250,000 PSL, based on the coinmarketcap.com official price
                  for PSL. Request maximums are the lesser of $10,000 and
                  2,500,000 PSL based on the coinmarketcap.com official price
                  for PSL.
                </li>
                <li>
                  Wrapping Requests-request minimums are the greater of $1,000
                  and 250,000 PSL, based on the coinmarketcap.com official price
                  for PSL. Request maximums are the lesser of $10,000 and
                  2,500,000 PSL based on the coinmarketcap.com official price
                  for PSL.
                </li>
                <li>
                  Unwrapping Requests-request minimums are the greater of $1,000
                  and 250,000 PSL, based on the coinmarketcap.com official price
                  for PSL. Request maximums are the lesser of $10,000 and
                  2,500,000 PSL based on the coinmarketcap.com official price
                  for PSL.
                </li>
              </ol>
            </li>
            <li>
              <u>User Specific Request Minimums and Maximums.</u>
              WrappedPastel may restrict individual users minimum and maximum
              request size in its sole discretion. To view your limits, login to
              your WrappedPastel Account and visit your profile page. Your
              request limits may vary depending on your payment method,
              verification steps you have completed, and other factors.
              WrappedPastel reserves the right to change applicable limits as we
              deem necessary in our sole discretion. If you wish to adjust your
              limits beyond the posted amounts, you may submit a request at{' '}
              <a href='mailto:support@pastel.network'>support@pastel.network</a>
              . We may require you to submit additional information about
              yourself or your business, provide records, and arrange for
              meetings with WrappedPastel staff (such process, "
              <b>Enhanced Due Diligence</b>"). WrappedPastel reserves the right
              to charge you costs and fees associated with Enhanced Due
              Diligence, provided that we notify you in advance of any such
              charges accruing. In our sole discretion, we may refuse to raise
              your limits or we may lower your limits at a subsequent time even
              if you have completed Enhwraanced Due Diligence.
            </li>
          </ol>
        </li>
        <li>
          <u>Trading Fees.</u> WrappedPastel charges a fee for each transaction
          conducted on the Platform. The fee is charged by debiting the
          User&#39;s Available Balance for the amount of the fee at the time
          executed. Fees may vary between products and services. The current fee
          rates can be found on the wrappedpastel.com site, but is generally 1%
          of the total amount being wrapped or unwrapped. WrappedPastel reserves
          the right to waive or discount its fees to any user.
        </li>
        <li>
          <u>Market Integrity.</u> WrappedPastel attempts to maintain a fair and
          orderly market for all Users. The following rules apply across
          WrappedPastel&#39;s trading platform.
          <ol className='list-disc mt-2'>
            <li>All Trades are Final.</li>
            <li>
              All Fills are final and will not be reversed unless (1)
              WrappedPastel is compelled to do so by any applicable law or
              regulation or (2) due to a serious technical error, Requests do
              not occur as specified in these Rules, in which case WrappedPastel
              will make all reasonable efforts to restore all Users to the
              position they would have been in had the error not occurred.
            </li>
            <li>
              WrappedPastel may cancel Open Requests in the following
              circumstances:
              <ol className='list-disc mt-2'>
                <li>
                  requests placed by Users who, in our sole discretion, have
                  engaged in Prohibited Use or abusive use of the platform, for
                  example, Market Manipulation;
                </li>

                <li>
                  requests which under the circumstances involve an obvious
                  error with respect to price, quantity, or other parameters (a
                  "<b>clearly erroneous transaction</b>");
                </li>
                <li>
                  if required by any applicable law or regulation, including
                  specifically where WrappedPastel is required to suspend or
                  terminate a User’s WrappedPastel Account; or
                </li>
                <li>if required for technical reasons.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <u>Access to WrappedPastel.</u>
          <ol className='list-disc mt-2'>
            <li>
              All Users have equal access to the WrappedPastel Sites.
              WrappedPastel does not provide prioritized access to any User.
            </li>
            <li>
              WrappedPastel has the authority to take any action deemed
              appropriate to preserve market integrity. Such actions include,
              but are not limited to, the halting of trading, modifying
              risk-mitigating parameters, restricting User access to
              WrappedPastel Accounts or to the WrappedPastel Site, restricting
              order types to post-only or limit only, canceling orders resting
              in the order book, or any other actions deemed to be in the best
              interest of WrappedPastel Site.
            </li>
          </ol>
        </li>
        <li>
          <u>Interruptions.</u>
          <ol className='list-disc mt-2'>
            <li>
              If technical reasons prevent or degrade Users&#39; ability to
              place or cancel Requests, or prevent or degrade access to
              WrappedPastel Sites or affect the operation of WrappedPastel Order
              Books or matching engines, then WrappedPastel may, in its
              discretion, take one or more of the following actions in respect
              of one or more Requests Books:
              <ol className='list-disc mt-3'>
                <li>cancel Open Requests;</li>
                <li>
                  disable the ability to place new Orders (Cancel-Only Mode);
                </li>
                <li>disable sign-in; or</li>
                <li>disable access to the WrappedPastel Site.</li>
              </ol>
            </li>
            <li>
              If access to WrappedPastel through the WrappedPastel Site is
              unavailable for a prolonged period of time but the RFQ or matching
              engine is still operational, WrappedPastel will move to
              Cancel-Only Mode as soon as reasonably possible.
            </li>
            <li>
              If WrappedPastel is in Cancel-only Mode, it will be restored to
              fully operational only after access through the WrappedPastel Site
              becomes available for a reasonable amount of time and
              WrappedPastel deems is it safe to restore full operations.
            </li>
            <li>
              WrappedPastel will notify Users of the move to or from Cancel-Only
              Mode via email, Twitter and/or through posting on the
              wrappedpaste.com website.
            </li>
            <li>
              Scheduled Down-Time. From time to time, WrappedPastel may suspend
              trading temporarily for maintenance or upgrades. Absent
              extenuating circumstances, WrappedPastel will follow this process
              for closing and re-opening trading:
              <div className='pl-6 mt-2'>
                <p>
                  Step 1: Announce Scheduled Downtime via email, twitter and/or
                  the WrappedPastel Site.
                </p>
                <p>Step 2: Scheduled Downtime Begins.</p>
                <p>
                  Step 3: Immediately after Scheduled Downtime is completed, all
                  books enter Post-Only mode. An announcement of the foregoing
                  shall be made via email, twitter and/or the WrappedPastel
                  Site. To ensure a fair and orderly marketplace, WrappedPastel
                  may, in its discretion, cancel all resting limit orders in an
                  Order Book before moving the market to post-only mode or to
                  limit-only mode.
                </p>
                <p>
                  Step 4: At least 10 minutes after Step 3; or longer if
                  necessary, all books enter Limit-Only mode. An announcement of
                  the foregoing shall be made via email, twitter and/or the
                  WrappedPastel Site.
                </p>
                <p>
                  Step 5: At least 10 minutes after Step 4; or longer if
                  necessary, all books enter Full-trading mode. An announcement
                  of the foregoing shall be made via email, twitter and/or the
                  WrappedPastel Site.
                </p>
              </div>
            </li>
          </ol>
        </li>
      </ol>
      <ol className='list-disc'>
        <li>
          <u>Self-Trade Prevention.</u>
          <ol className='list-disc mt-2'>
            <li>
              Users are not able to place a Request which would result in
              self-execution (i.e., where the same User would act as both the
              wrapper and unwrapper for the trade).
            </li>
            <li>
              If two Requests of the same quantity would result in
              self-execution, both Orders are canceled.
            </li>
            <li>
              According to self-trade prevention rules, if two Requests of
              different quantities would result in self-execution you may
              specify the follow actions:
              <ol className='list-disc mt-2'>
                <li>
                  Keep Newest (default) – When self-crossing requests are
                  detected, the newest requests of the crossing set are kept and
                  any requests that cross with them are excluded from
                  participating in the auction. See below for what happens to
                  the excluded requests.
                </li>
                <li>
                  Keep Oldest (keep-oldest) – When self-crossing requests are
                  detected, the oldest requests of the crossing set are kept and
                  any requests that cross with them are excluded from
                  participating in the auction. See below for what happens to
                  the excluded requests.
                </li>
                <li>
                  Cancel All (cancel-all) – When self-crossing requests are
                  detected, the entire set of crossing requests are rejected and
                  do not participate in the auction. The rest of the user&#39;s
                  (non-crossing) requests are not affected.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <u>Flash Crashes and Circuit Breakers.</u> WrappedPastel does not use
          circuit breakers or automated trading halts based on predetermined
          price bands. As provided herein, WrappedPastel may, in its discretion,
          halt trading.
        </li>
        <li>
          <u>Market Manipulation Prohibited.</u> Users are prohibited from
          engaging in Market Manipulation. Market Manipulation of any kind is
          strictly prohibited. Market Manipulation is defined as actions taken
          by any market participant or a person acting in concert with a
          participant which are intended to: (1) deceive or mislead other Users;
          (2) artificially control or manipulate the price or trading volume of
          a Digital Currency; or (3) aid, abet, enable, finance, support, or
          endorse either of the above. This may include actions on WrappedPastel
          Site or outside of WrappedPastel Site. Market Manipulation
          specifically includes, without limitation: front-running, wash
          trading, spoofing, layering, churning, and quote stuffing.
        </li>
        <li>
          <u>Access to Information.</u>
          <ol className='list-disc mt-2'>
            <li>
              All Users have full and equal real-time access to Public Market
              Data. Public Market Data is made available through the
              WrappedPastel Site.
            </li>
            <li>
              Public Market Data includes (1) aggregated Limit Orders placed on
              the Order Book (price and total quantity) and (2) all Fills or
              Executions (price, quantity, and time).
            </li>
            <li>
              Public Market Data does not include the following:
              <ol className='list-disc mt-2'>
                <li>
                  resting Stop Orders (Stop Orders that have been placed but not
                  triggered);
                </li>
                <li>
                  any information about which User placed or canceled an order;
                  and
                </li>
                <li>
                  private market data that WrappedPastel has access to under
                  contract with external market makers
                </li>
              </ol>
            </li>
            <li>
              <u>WrappedPastel Employee Access.</u> WrappedPastel policies
              prohibit all WrappedPastel employees from using inside, corporate
              or proprietary information to trade Digital Assets or securities.
              WrappedPastel employees are prohibited from trading personal
              accounts on WrappedPastel.
            </li>
          </ol>
        </li>
        <li>
          <u>Definitions.</u> As used in these WrappedPastel Trading Rules, the
          following words and phrases have the corresponding meanings:
          <ol className='list-disc mt-2'>
            <li>
              &quot;<span className='font-extrabold'>Account</span>&quot; means
              a User&#39;s WrappedPastel Account.
            </li>
            <li>
              &quot;<span className='font-extrabold'>Asset</span>&quot; means a
              Digital Currency or Fiat Currency.
            </li>
            <li>
              &quot;<span className='font-extrabold'>Available Balance</span>
              &quot; means a User&#39;s Total Asset Value less any amounts held
              for Open Requests and fees.
            </li>
            <li>
              &quot;<span className='font-extrabold'>Base Asset</span>&quot;
              means the Asset being traded on the Request Book; the first Asset
              in the Trading Pair. For example, on the PSL-wPSL Request Book,
              PSL is the Base Asset and wPSL is the Quote Asset.
            </li>
            <li>
              &quot;<span className='font-extrabold'>Cancel-only Mode</span>
              &quot; means that Users cannot place new Requests. Users are able
              to cancel open Requests. No Requests will be filled.
            </li>
            <li>
              &quot;<span className='font-extrabold'>Digital Asset</span>&quot;
              means a blockchain-based digital currency, security, app coin or
              protocol token which is offered for exchange on WrappedPastel,
              particularly PSL and wPSL.
            </li>
            <li>
              &quot;<span className='font-extrabold'>Fill</span>&quot; means a
              match of two Requests. Also referred to as an execution.
            </li>
            <li>
              &quot;<span className='font-extrabold'>Full Trading Mode</span>
              &quot; means normal operation of WrappedPastel. Users can place
              Requests and can cancel Requests.
            </li>
            <li>
              &quot;<span className='font-extrabold'>Hold</span>&quot; means the
              setting aside of Assets allocated to a Request.
            </li>
            <li>
              &quot;<span className='font-extrabold'>Request</span>&quot; means
              an instruction to wrap or unwrap a specified quantity of the Base
              Asset at a specified price in the Quote Asset.
            </li>
            <li>
              &quot;<span className='font-extrabold'>Request Book</span>&quot;
              means each request book on which Requests are placed for trading
              in a Trading Pair.
            </li>
            <li>
              &quot;<span className='font-extrabold'>Scheduled Downtime</span>
              &quot; means a planned temporary outage for maintenance or
              upgrades.
            </li>
            <li>
              &quot;<span className='font-extrabold'>Trading Halt</span>&quot;
              means that Users cannot place or cancel any Requests.
            </li>
            <li>
              &quot;<span className='font-extrabold'>User</span>&quot; means
              each customer trading on WrappedPastel.
            </li>
            <li>
              &quot;<span className='font-extrabold'>Unwrapping Request</span>
              &quot; means a Request to convert a specified quantity of wPSL
              into PSL at the best available price provided through
              WrappedPastel.
            </li>
            <li>
              &quot;<span className='font-extrabold'>Wrapping Request</span>
              &quot; means a Request to convert a specified quantity of PSL into
              wPSL at the best available price provided through WrappedPastel.
            </li>
          </ol>
        </li>
      </ol>
    </div>
    <br />
    <br />
    <p className='font-extrabold' id='appendix-2'>
      APPENDIX 2:
      <br />
      PROHIBITED USE, PROHIBITED BUSINESSES AND CONDITIONAL USE
    </p>
    <div className='ml-3'>
      <ol className='list-disc'>
        <li>
          <u>Prohibited Use.</u> You may not use your WrappedPastel Account to
          engage in the following categories of activity ("
          <b>Prohibited Uses</b>
          "). The specific types of use listed below are representative, but not
          exhaustive. By opening a WrappedPastel Account, you confirm that you
          will not use your WrappedPastel Account to do any of the following:
        </li>
        <li>
          <u>Unlawful Activity:</u> Activity which would violate, or assist in
          violation of, any law, statute, ordinance, or regulation, sanctions
          programs administered in the countries where WrappedPastel conducts
          business, including but not limited to the U.S. Department of
          Treasury&#39;s Office of Foreign Assets Control (&quot;{' '}
          <span className='font-extrabold'>OFAC</span>&quot;), or which would
          involve proceeds of any unlawful activity; publish, distribute or
          disseminate any unlawful material or information;
        </li>
        <li>
          <u>Abusive Activity:</u> Actions which impose an unreasonable or
          disproportionately large load on our infrastructure, or detrimentally
          interfere with, intercept, or expropriate any system, data, or
          information (including the use of bots or scripts); transmit or upload
          any material to the WrappedPastel Site that contains viruses, trojan
          horses, worms, or any other harmful or deleterious programs; attempt
          to gain unauthorized access to the WrappedPastel Site, other
          WrappedPastel Accounts, computer systems or networks connected to the
          WrappedPastel Site, through password mining or any other means; use
          WrappedPastel Account information of another party to access or use
          the WrappedPastel Site (WrappedPastel regards the accessing of
          multiple WrappedPastel Accounts from the same device to indicate
          potential abusive activity); or transfer your WrappedPastel Account
          access or rights to your WrappedPastel Account to a third party,
          unless by operation of law or with the express permission of
          WrappedPastel;
        </li>
        <li>
          <u>Abuse Other Users:</u> Interfere with another individual&#39;s or
          entity&#39;s access to or use of any WrappedPastel Services; defame,
          abuse, extort, harass, stalk, threaten or otherwise violate or
          infringe the legal rights (such as, but not limited to, rights of
          privacy, publicity and intellectual property) of others; incite,
          threaten, facilitate, promote, or encourage hate, racial intolerance,
          or violent acts against others; harvest or otherwise collect
          information from the WrappedPastel Site about others, including
          without limitation email addresses, without proper consent;
        </li>
        <li>
          <u>Fraud:</u> Activity which operates to defraud WrappedPastel,
          WrappedPastel users, or any other person; provide any false,
          inaccurate, or misleading information to WrappedPastel (WrappedPastel
          regards temporary email addresses and disposable email addresses to be
          forms of misleading information);
        </li>
        <li>
          <u>Gambling:</u> Lotteries; bidding fee auctions; sports forecasting
          or odds making; fantasy sports leagues with cash prizes; internet
          gaming; contests; sweepstakes; games of chance; or
        </li>
        <li>
          <u>Intellectual Property Infringement:</u> Engage in transactions
          involving items that infringe or violate any copyright, trademark,
          right of publicity or privacy or any other proprietary right under the
          law, including but not limited to sales, distribution, or access to
          counterfeit music, movies, software, or other licensed materials
          without the appropriate authorization from the rights holder; use of
          WrappedPastel intellectual property, name, or logo, including use of
          WrappedPastel trade or service marks, without express consent from
          WrappedPastel or in a manner that otherwise harms WrappedPastel or the
          WrappedPastel brand; any action that implies an untrue endorsement by
          or affiliation with WrappedPastel.
          <ol className='list-disc mt-2'>
            <li>
              <u>Prohibited Businesses.</u> In addition to the Prohibited Uses
              described above, the following categories of businesses, business
              practices, and sale items are barred from WrappedPastel (&quot;{' '}
              <span className='font-extrabold'>Prohibited Businesses</span>
              &quot;). The specific types of use listed below are
              representative, but not exhaustive. By opening a WrappedPastel
              Account, you confirm that you will not use WrappedPastel Services
              in connection with any of the following businesses, activities,
              practices, or items:
            </li>
          </ol>
        </li>
        <li>
          <u>Investment and Credit Services:</u> Securities brokers; mortgage
          consulting or debt reduction services; credit counseling or repair;
          real estate opportunities; investment schemes;
        </li>
        <li>
          <u>Restricted Financial Services:</u> Check cashing, bail bonds;
          collections agencies.
        </li>
        <li>
          <u>Intellectual Property or Proprietary Rights Infringement:</u>{' '}
          Sales, distribution, or access to counterfeit music, movies, software,
          or other licensed materials without the appropriate authorization from
          the rights holder;
        </li>
        <li>
          <u>Counterfeit or Unauthorized Goods:</u> Unauthorized sale or resale
          of brand name or designer products or services; sale of goods or
          services that are illegally imported or exported or which are stolen;
        </li>
        <li>
          <u>Regulated Products and Services:</u> Marijuana dispensaries and
          related businesses; sale of tobacco, e-cigarettes, and e-liquid;
          online prescription or pharmaceutical services; age restricted goods
          or services; weapons and munitions; gunpowder and other explosives;
          fireworks and related goods; toxic, flammable, and radioactive
          materials; products and services with varying legal status on a
          state-by-state basis;
        </li>
        <li>
          <u>Drugs and Drug Paraphernalia:</u> Sale of narcotics, controlled
          substances, and any equipment designed for making or using drugs, such
          as bongs, vaporizers, and hookahs;
        </li>
        <li>
          <u>Pseudo-Pharmaceuticals:</u> Pharmaceuticals and other products that
          make health claims that have not been approved or verified by the
          applicable local and/or national regulatory body;
        </li>
        <li>
          <u>Substances Designed to Mimic Illegal Drugs:</u> Sale of a legal
          substance that provides the same effect as an illegal drug (e.g.,
          salvia, kratom);
        </li>
        <li>
          <u>Adult Content and Services:</u> Pornography and other obscene
          materials (including literature, imagery and other media); sites
          offering any sexually-related services such as prostitution, escorts,
          pay-per view, adult live chat features;
        </li>
        <li>
          <u>Multi-Level Marketing:</u> Pyramid schemes, network marketing, and
          referral marketing programs;
        </li>
        <li>
          <u>Unfair, Predatory or Deceptive Practices:</u> Investment
          opportunities or other services that promise high rewards; Sale or
          resale of a service without added benefit to the buyer; resale of
          government offerings without authorization or added value; sites that
          we determine in our sole discretion to be unfair, deceptive, or
          predatory towards consumers; or
        </li>
        <li>
          <u>High-Risk Businesses:</u> any businesses that we believe poses
          elevated financial risk, legal liability, or violates card network or
          bank policies.
        </li>
        <ol className='list-disc'>
          <li>
            <u>Conditional Use.</u> Express written consent and approval from
            WrappedPastel must be obtained prior to using WrappedPastel Services
            for the following categories of business and/or use (&quot;{' '}
            <span className='font-extrabold'>Conditional Uses</span>&quot;).
            WrappedPastel may also require you to agree to additional
            conditions, make supplemental representations and warranties,
            complete enhanced on-boarding procedures, and operate subject to
            restrictions if you use WrappedPastel Services in connection with
            any of following businesses, activities, or practices:
          </li>
        </ol>
        <li>
          <u>Money Services:</u> Money transmitters, Digital Currency
          transmitters; currency or Digital Currency exchanges or dealers; gift
          cards; prepaid cards; sale of in-game currency unless the merchant is
          the operator of the virtual world; act as a payment intermediary or
          aggregator or otherwise resell any of the WrappedPastel Services;
        </li>
        <li>
          <u>Charities:</u> Acceptance of donations for nonprofit enterprise;
        </li>
        <li>
          <u>Games of Skill:</u> Games which are not defined as gambling under
          this Agreement or by law, but which require an entry fee and award a
          prize; or
        </li>
        <li>
          <u>Religious/Spiritual Organizations:</u> Operation of a for-profit
          religious or spiritual organization.
        </li>
      </ol>
    </div>
    <br />
    <br />
    <p className='font-extrabold' id='appendix-3'>
      APPENDIX 3:
      <br />
      VERIFICATION PROCEDURES AND LIMITS
    </p>
    <div className='ml-3'>
      <ol className='list-disc'>
        <li>
          As a regulated financial service company operating in the U.S., we are
          required to identify users on our platform. This ensures we remain in
          compliance with KYC/AML laws in the jurisdictions in which we operate,
          something that is necessary for us to be able to continue to offer
          digital currency exchange services to our customers. WrappedPastel and
          our affiliates collect and verify information about you in order to:
          (a) protect WrappedPastel and the community from fraudulent users, and
          (b) to keep appropriate records of WrappedPastel&#39; customers, and
          (c) manage any transaction limitations.
        </li>
        <li>
          All customers who wish to use WrappedPastel Services, including the
          sending and receiving of Digital Assets reconciled to a blockchain,
          are required to establish a WrappedPastel Account by:
          <ol className='list-disc mt-2'>
            <li>
              providing your name and valid email address, password and your
              state and country of residence, and certify that you are 18 years
              or older;
            </li>
            <li>
              accepting Terms of Service, onboarding confirmations, Subscriber
              Agreement and Privacy Policy, and
            </li>
            <li>
              verifying your identity by submitting the following information
              (WrappedPastel reserves the right to request additional
              information as needed; additional information may be requested of
              entities such as companies and trusts):
              <ol className='list-disc mt-2'>
                <li>full name,</li>
                <li>date of birth,</li>
                <li>physical address.</li>
                <li>
                  Social Security Number (or identification number from a
                  government-issued ID for certain types of customers),
                </li>
                <li>telephone number,</li>
              </ol>
            </li>
            <li>
              submitting a selfie picture from your webcam or mobile phone, as
              WrappedPastel may require; and
            </li>
            <li>
              submitting a copy of an acceptable form of identification (i.e.
              passport, state driver&#39;s license, or state identification
              card).
            </li>
          </ol>
        </li>
        <li>
          Users may be required to sign transactions prior to sending them to
          WrappedPastel&#39; custodian partner. Notwithstanding these minimum
          verification procedures, WrappedPastel may require you to provide or
          verify additional information, or to wait some amount of time after
          completion of a transaction, before permitting you to use any
          WrappedPastel Service and/or before permitting you to engage in
          transactions beyond certain volume limits.
        </li>
      </ol>
    </div>
    <br />
    <br />
    <p className='font-extrabold' id='appendix-4'>
      APPENDIX 4:
      <br />
      E-SIGN DISCLOSURE AND CONSENT
    </p>
    <div className='ml-3'>
      <p>
        This policy describes how WrappedPastel delivers communications to you
        electronically. We may amend this policy at any time by providing a
        revised version on our website. The revised version will be effective at
        the time we post it. We will provide you with prior notice of any
        material changes via our website.
      </p>
      <ol className='list-disc'>
        <li>
          Electronic Delivery of Communications. You agree and consent to
          receive electronically all communications, agreements, documents,
          notices and disclosures (collectively, &quot;{' '}
          <span className='font-extrabold'>Communications</span>&quot;) that we
          provide in connection with your WrappedPastel Account and your use of
          WrappedPastel Services. Communications include:
          <ol className='list-disc mt-2'>
            <li>
              terms of use and policies you agree to (e.g., the WrappedPastel
              Subscriber Agreement, Terms of Service, User Agreement and Privacy
              Policy), including updates to these agreements or policies;
            </li>
            <li>
              account details, history, transaction receipts, confirmations, and
              any other account or transaction information; legal, regulatory,
              and tax disclosures or statements we may be required to make
              available to you; and
            </li>
            <li>
              responses to claims or customer support inquiries filed in
              connection with your WrappedPastel Account.
            </li>
          </ol>
        </li>
      </ol>
      <p>
        We will provide these Communications to you by posting them on the
        WrappedPastel website, emailing them to you at the primary email address
        listed in your WrappedPastel profile, communicating to you via instant
        chat, and/or through other electronic communication such as text message
        or mobile push notification, as deemed appropriate.
      </p>
      <ol className='list-disc'>
        <li>
          Hardware and Software Requirements. In order to access and retain
          electronic Communications, you will need the following computer
          hardware and software:
          <ol className='list-disc mt-2'>
            <li>a device with an Internet connection;</li>
            <li>
              a current web browser that includes 128-bit encryption (e.g.
              Internet Explorer version 9.0 and above, Firefox version 3.6 and
              above, Chrome version 31.0 and above, or Safari 7.0 and above)
              with cookies enabled;
            </li>
            <li>
              a valid email address (your primary email address on file with
              WrappedPastel);
            </li>
            <li>multifactor authentication enabled; and</li>
            <li>
              sufficient storage space to save past Communications or an
              installed printer to print them.
            </li>
          </ol>
        </li>
        <li>
          Updating your Information. It is your responsibility to provide us
          with a true, accurate and complete email address and your contact
          information, and to keep such information up to date. You understand
          and agree that if WrappedPastel sends you an electronic Communication
          but you do not receive it because your primary email address on file
          is incorrect, out of date, blocked by your service provider, or you
          are otherwise unable to receive electronic Communications,
          WrappedPastel will be deemed to have provided the Communication to
          you. You may update your information by logging into your account and
          visiting settings.
        </li>
      </ol>
    </div>
  </>
)
