import React from 'react'
import Modal from 'react-modal'

import { ReactComponent as CloseIcon } from '../../assets/icons/ico-union.svg'
import Spinner from '../../common/components/Spinner'
import { TConversion } from '../../mobx/api'
import style from './ConversionLogModal.module.scss'

export default function ConversionLogModal({
  isOpen,
  onClose,
  data,
  isLoading,
}: {
  isOpen: boolean
  onClose: () => void
  data: TConversion[]
  isLoading: boolean
}) {
  const renderConversionLogContent = () => {
    let index = 0
    return data.map(item => {
      if (!item.updatedMessage) {
        return null
      }
      index++
      return (
        <tr key={index}>
          <td className='pr-6 py-4 whitespace-nowrap'>{index}</td>
          <td className='px-6 py-4'>{item.updatedMessage}</td>
        </tr>
      )
    })
  }
  return (
    <Modal
      isOpen={isOpen}
      contentLabel='Login Modal'
      className={style.modal_content}
      overlayClassName={style.modal_overlay}
    >
      <div className='py-10 px-15'>
        <button
          onClick={onClose}
          className='w-7 h-7 rounded-lg border border-icon-default opacity-30 px-2.5 absolute right-6 top-10'
        >
          <CloseIcon />
        </button>
        <h2 className='text-gray-800 font-extrabold'>Conversion Log</h2>
        <div className='mt-5'>
          {isLoading ? (
            <div className='text-center'>
              <Spinner />
            </div>
          ) : (
            <div className={style.conversion_log_wrapper}>
              <table className='min-w-full relative table-auto'>
                <thead>
                  <tr>
                    <th
                      scope='col'
                      className='w-16 pr-6 py-3 text-left text-base font-extrabold text-gray-700 sticky top-0 bg-white z-30'
                    >
                      No
                    </th>
                    <th
                      scope='col'
                      className='px-6 py-3 text-left text-base font-extrabold text-gray-700 sticky top-0 bg-white z-30'
                    >
                      Conversion message
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white'>
                  {renderConversionLogContent()}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
