import React from 'react'

import { SectionContact } from '../../components/SectionContact'
import { SectionFaq } from '../../components/SectionFaq'

const Faq = () => {
  return (
    <div className='px-15 pb-10'>
      <div className='container'>
        <SectionFaq />
        <SectionContact />
      </div>
    </div>
  )
}

export default Faq
