export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string

export const web3Networks = (process.env.REACT_APP_WEB3_NETWORK || '')
  .split(',')
  .map(n => n.trim())
  .filter(n => n)
export const web3WpslAddresses = (process.env.REACT_APP_WEB3_WPSL_ADDR || '')
  .split(',')
  .map(n => n.trim())
  .filter(n => n)

export const pslAddressZ = process.env.REACT_APP_PSL_ADDRESS_Z as string

export const transferRatio = 0.99
export const pslExplorerUrl = 'https://explorer.pastel.network'
export const erc20APIUrl =
  process.env.REACT_APP_ERC20_API || 'https://erc20-api.wrappedpastel.com'
export const baseURL =
  process.env.REACT_APP_BACKEND_URL || 'http://localhost:8443'

export const tempAuthLock = process.env.REACT_APP_TEMP_LOCK === 'true'
